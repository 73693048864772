const fontFamilyObj = {
    "titleFamily": "Nunito",
    "bodyFamily": "Open Sans",
    "timerFamily": "Cousine"
};

const globalProfiles = {
    'palette': {
        'primaryColor': '#FB7286',
        // 'primaryVariantColor': '#572676',
        'secondaryColor': '#F79F58',
        // 'secondaryVariantColor': '#FF1E50',
        'progressBarBackground': '#F1F1F1',
        'progressBarPrimaryColor': '#2FE690',
        'progressBarSecondaryColor': '#E4FFF3',
        'primaryBgColor': '#FC919E',
        'secondaryBgColor': '#F6B889',
        'tertiaryBgColor': '#FAF1EA',
        'grayColor1': '#031A20',
        'grayColor2': '#03313D',
        'grayColor3': '#BABABA',
        'grayColor4': '#E5E3E1',
        'grayColor5': '#C4C4C4',
        'black': '#000000',
        'white': '#FFFFFF',
        'buttonColor1': '#00D7FF',
        'buttonColor2': '#7DEEB9',
        'red': '#D63228',
        'pink': '#FD1E51',
        'pink2': '#FF5279',
        'yellow': '#FFD452',
        'green': '#36B496',
        'primaryPopupBgColor': '#FEDEE2',
        'secondaryPopupBgColor': '#FCEADC',
        'tertiaryPopupBgColor': '#FDF9F6',
        'pinkTableBg': '#FFEEF0',
        'warningTextColor': '#FF425D'
    }
};

const entityProfiles = []

const skins = {
    fontFamily: fontFamilyObj,
    globalProfiles: globalProfiles,
    entityProfiles: entityProfiles
};

export default skins;
