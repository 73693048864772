import actionConsts from 'constants/actions';

const setNextEvent = (payload) => {
    return {
        type: actionConsts.SET_NEXT_EVENT,
        payload
    };
}

const startEventLoader = () => {
    return {
        type: actionConsts.START_EVENT_LOADER
    };
}

const stopEventLoader = () => {
    return {
        type: actionConsts.STOP_EVENT_LOADER
    };
}

const eventResponseReceived = (payload) => {
    return {
        type: actionConsts.EVENT_RESPONSE,
        payload
    };
};

const resetEventResponse = () => {
    return {
        type: actionConsts.RESET_EVENT_RESPONSE
    }
}

const updateOverallMetricsForEvent = (payload) => {
    return {
        type: actionConsts.UPDATE_EVENT_METRICS,
        payload
    };
};

export {
    setNextEvent,
    startEventLoader,
    stopEventLoader,
    eventResponseReceived,
    resetEventResponse,
    updateOverallMetricsForEvent
};
