import React from 'react';
import getSkin from "./skin.js";
import styles from "./downloadReport.module.scss";
import { css } from "aphrodite/no-important";
import applyWrappers from "wrappers/ComponentWrapper";

const DownloadReport = (props) => {

	const renderDownloadMessage = () => {
        return props.uiState.isReportDownloading ? props.getLabel('label_report_downloading') : "";
    }

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="report-container">
			<div className={css(myStyles.downloadBtn)} onClick={props.downloadReportOnClick} 
				styleName="download-btn">
					{props.getLabel("label_end_screen_button_2")}
			</div>
			<div className={css(myStyles.downloadStatusText)} styleName="status-bar">
				{renderDownloadMessage()}
			</div>
		</div>
	);
}

export default applyWrappers(DownloadReport, styles);