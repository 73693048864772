const checkIfPresent = (value) => {
    if (value === undefined || value === null || value === "") {
        return false;
    }
    return true;
}

const getUserName = (userState) => {
    if (checkIfPresent(userState.firstName)) {
        return userState.firstName;
    } else if (checkIfPresent(userState.emailId)) {
        return userState.emailId;
    }
    return ''
}

const getKalturaLink = () => {
    return 'https://cdnapisec.kaltura.com/p/2413672/sp/241367200/embedIframeJs/uiconf_id/42816162/partner_id/2413672';
}

export {
    checkIfPresent,
    getUserName,
    getKalturaLink
};