import React, { useState, useEffect } from "react";
import styles from "./history.module.scss";
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from "wrappers/ComponentWrapper";
import _ from "lodash";
import CloseIcon from "images/action-response-close.png";
import HistoryMetrics from "components/history/historyMetrics";
import history_arrow from 'images/history_arrow.png';
// import DelayedImpact from 'images/history_delayedImpact.png';
import historyImage from 'images/history_heading.png';
import analyticsUtil from 'utils/segmentUtil';
import HistoryDropDown from '../historyDropDown'
import AllActionIcon from 'images/all_actors_icon.svg';
import DelayedImpact from 'images/delayedimpact.svg';

const Container = props => {

    const delayedActionTag = props.getLabel('label_history_delayed_action_tag');
    const delayedImpactTag = props.getLabel('label_history_delayed_impact_tag');
    const myStyles = getSkin(props.skinGuide);
    let { setUiState, history, actions, showDataFor, onClickOfTab } = props;

    const actionTypeList = [
        { id: 0, name: props.getLabel('label_history_all') },
        { id: 1, name: props.getLabel('label_intermediate_report_chart1_legend2') },
        { id: 2, name: props.getLabel('label_intermediate_report_chart1_legend1') },
        { id: 3, name: props.getLabel("label_event_text") }
        // turkish
    ]
    let [actionTypeOptionId, setActionTypeOptionId] = useState(0)

    const teamMemberList = [{ id: 0, name: props.getLabel('label_history_all'), image: AllActionIcon }]
    props.actors.actorsOrder.map(item => {
        teamMemberList.push({
            id: item,
            name: props.getLabel(props.actors.actorList[item].name),
            image: props.actors.actorList[item].imagePath
        })
    })
    let [teamActorOptionId, setTeamActorOptionId] = useState(0)

    const BAMBAList = [{ id: 0, name: props.getLabel('label_history_all') }]
    props.categories.categoriesOrder.map(item => {
        BAMBAList.push({
            id: item,
            name: props.getLabel(props.categories.categoryList[item].name)
        })
    })
    let [BAMBAOptionId, setBAMBAOptionId] = useState(0)

    const renderHistoryList = (showDataFor = "all") => {
        let historyList = [];
        if (history && history.length > 0) {
            // Filter by action type
            if (actionTypeOptionId === 1) {
                history = history.filter(item => item.type === 'action' & !item.isOrgAction)
            }
            if (actionTypeOptionId === 2) {
                history = history.filter(item => item.type === 'action' && item.isOrgAction)
            }
            if (actionTypeOptionId === 3) {
                history = history.filter(item => item.type === 'event')
            }
            //Filter by actor id
            if (teamActorOptionId !== 0) {
                if (actionTypeOptionId === 0) {
                    history = history.filter(item => item.actorId === teamActorOptionId || !item.actorId)
                } else {
                    history = history.filter(item => item.actorId === teamActorOptionId)
                }
            }
            //Filter by BAMBA category id
            if (BAMBAOptionId !== 0) {
                history = history.filter(item => item.categoryId === BAMBAOptionId)
            }
            history.forEach(function (element, index) {
                const { completedDay, type, user_metrics, actorActions } = element;
                let tag = "", name = "", desc = "", tagStyle = "", showEventAction = false, eventAction, actorMetrics;
                let isDelayed = false;
                let delayedResponse = false;
                let delayDay = 0;

                let category = props.categories.categoryList[element.categoryId];
                // domain= props.categories.categoryList[element.categoryId]['subDomains'][actionResponseObj['subDomainId']]['name'],

                actorMetrics = actorActions

                switch (type) {
                    case "event":
                        if ((showDataFor == "all" || showDataFor == "event") && element.event) {
                            tag = "label_history_event";
                            name = element.event.name;
                            desc = element.event.desc;

                            if (element.eventAction && element.eventAction.length > 0) {
                                showEventAction = true;
                                eventAction = element.eventAction[0];
                            }
                        }
                        tagStyle = "eHash";
                        break;
                    case "action":
                        if ((showDataFor == "all" || showDataFor == "action") && actions[element.actionId]) {
                            tag = "label_history_action";
                            name = actions[element.actionId].name;
                            isDelayed = actions[element.actionId].isDelayed;
                            delayedResponse = isDelayed && element.impactDay > element.completedDay ? true : false;
                            delayDay = delayedResponse ? element.impactDay - element.completedDay : 0;
                            desc = delayedResponse ? "" : element.actionMessage;
                        }
                        tagStyle = "aHash";
                        break;
                }
                if (tag != "") {
                    historyList.push(renderEachHistory(tag, name, desc, completedDay, user_metrics, tagStyle, showEventAction, eventAction, isDelayed, delayedResponse, delayDay, actorMetrics, category));
                }
            });
        }
        return historyList;
    }

    const closePopup = () => {
        //Segment integartion
        analyticsUtil.track("SIDEBAR_POPUP_CLOSE", { popup: "HISTORY_POPUP" });
        props.setUiState({
            showOverlay: false,
            overlayComponentType: '',
            overlayComponent: ''
        });
        props.setHighlightedSidebarComponent(-1)
    }

    const renderEachHistory = (tag, name, desc, completedDay, user_metrics, tagStyle, showEventAction, eventAction, isDelayed, delayedResponse, delayDay, actorMetrics, category) => {
        return (
            <div styleName="row" key={Math.random()}>
                <div styleName="history">
                    <div className={css(myStyles.day)} styleName="day">{props.getLabel("label_history_day")}</div>
                    <div className={css(myStyles.dayNumber)} styleName="daynumber">{completedDay}</div>
                    <div className={css(myStyles.spacer)} styleName="spacer">
                        <div styleName="action">
                            {
                                isDelayed ?
                                    <div styleName='delayed_style'>
                                        <img styleName="delayed_img" src={DelayedImpact} />
                                        <div className={css(myStyles.delayedImpact)} styleName="centered">{delayedResponse ? delayedActionTag : delayedImpactTag}</div>
                                    </div> : ""
                            }
                            <div styleName="each_row">
                                <div styleName="header-tag">
                                    <div className={css(myStyles[tagStyle])}>{props.getLabel(tag)}</div>
                                </div>
                                <div className={css(myStyles.name)} styleName="a-name">{props.getLabel(name)}</div>
                                <div className={css(myStyles.msg)} styleName="e-msg">{props.getLabel(desc)}
                                </div>
                                {Object.keys(user_metrics).length > 0 ? <div>
                                    <HistoryMetrics
                                        user_metrics={user_metrics}
                                        delayedResponse={delayedResponse}
                                        delayDay={delayDay}
                                        actorMetrics={actorMetrics}
                                        actors={props.actors}
                                        category={category}
                                    />
                                </div> : ""}

                                {showEventAction && <div styleName="eventAction">
                                    <div styleName="header-tag">
                                        <img styleName="historyArrow" src={history_arrow} />
                                        <div className={css(myStyles.aHash)}>{props.getLabel('label_history_action')}</div>
                                    </div>
                                    <div styleName="eventAction_left">
                                        <div className={css(myStyles.name)} styleName="a-name">{props.getLabel(eventAction['event_action']['name'])}</div>
                                        <div className={css(myStyles.msg)} styleName="e-msg">{props.getLabel(eventAction['event_action']['actionCompletedMessage'])}
                                        </div>
                                        {Object.keys(eventAction['user_metrics']).length > 0 ? <div>
                                            <HistoryMetrics
                                                user_metrics={eventAction['user_metrics']}
                                                delayedResponse={delayedResponse}
                                                actorMetrics={actorMetrics}
                                                actors={props.actors}
                                                category={category}
                                            />

                                        </div> : ""}
                                    </div>
                                </div>}

                                {/* <div styleName="white-seperator"/> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css(myStyles.borderBottom)} styleName="borderBottom" />
            </div>
        );
    };

    const resetFilters = () => {
        setActionTypeOptionId(0)
        setTeamActorOptionId(0)
        setBAMBAOptionId(0)
    }

    return (
        <div className={css(myStyles.container)} styleName="container">
            <div style={{ width: '100%', height: '486px', marginTop: '25px' }}>
                <div className={css(myStyles.header)} styleName="header">
                    <img styleName="heading-icon" src={historyImage} />
                    <div className={css(myStyles.title)} styleName="headerlabel">{props.getLabel("label_history_title")}</div>
                </div>
                <div styleName="filter">
                    <div styleName="filter-by">{props.getLabel("label_history_filter_by")}</div>
                    <HistoryDropDown
                        optionId={actionTypeOptionId}
                        setOptionId={setActionTypeOptionId}
                        optionList={actionTypeList}
                        drowDownName={props.getLabel("label_type")}
                        isDisabled={false}
                    />
                    <HistoryDropDown
                        optionId={teamActorOptionId}
                        setOptionId={setTeamActorOptionId}
                        optionList={teamMemberList}
                        drowDownName={props.getLabel("label_team_member")}
                        isDisabled={actionTypeOptionId >= 2}
                    />
                    <HistoryDropDown
                        optionId={BAMBAOptionId}
                        setOptionId={setBAMBAOptionId}
                        optionList={BAMBAList}
                        drowDownName='BAMBA'
                        isDisabled={actionTypeOptionId === 3}
                    />
                    <div
                        styleName='reset-button'
                        onClick={() => resetFilters()}
                    >
                        {props.getLabel("label_clear_all")}
                    </div>
                </div>
                <div className={css(myStyles.box)} styleName="box">
                    <div styleName="list">{renderHistoryList(showDataFor)}</div>
                </div>
            </div>
            <div
                styleName="close"
                onClick={closePopup}
            >
                <img src={CloseIcon} />
            </div>
        </div>
    );
};

export default applyWrappers(Container, styles);
