import React, { useState } from 'react';
import styles from './toolTipComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import InfoIcon from 'images/info-icon.svg';
import InfoIconDark from 'images/info-icon-dark.svg';

const ToolTipComponent = (props) => {

    const myStyles = getSkin(props.skinGuide);
    const propStyles = props.propStyles ? props.propStyles : {};

    const [showTooltip, setShowTooltip] = useState(false);


    const renderTooltip = (myStyles) => {
        const tooltipStyles = props.tooltipStyles ? props.tooltipStyles : {};
        switch (props.alignTooltipKey) {
            case 'LEFT':
                return (
                    <div styleName="tooltip-container-left">
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{ __html: props.tooltipText }}
                        ></div>
                    </div>
                );
            case 'RIGHT':
                return (
                    <div styleName="tooltip-container-right">
                        <div style={{ ...tooltipStyles }} className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{ __html: props.tooltipText }}
                        ></div>
                    </div>
                );
            case 'TOP':
                return (
                    <div styleName="tooltip-container-top">
                        <div style={{ ...tooltipStyles }} className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{ __html: props.tooltipText }}
                        ></div>
                    </div>
                );
            case 'BOTTOM':
                return (
                    <div styleName="tooltip-container-bottom">
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{ __html: props.tooltipText }}
                        ></div>
                    </div>
                );
            default:
                return (
                    <div styleName="tooltip-container-right">
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{ __html: props.tooltipText }}
                        ></div>
                    </div>
                );
        }
    }


    return (
        <div
            styleName="info-container"
            style={{ ...propStyles }}
            onMouseEnter={() => { setShowTooltip(true); }}
            onMouseLeave={() => { setShowTooltip(false); }}
        >
            <div
                styleName="info-icon"
            >
                {!props.dark ?
                    <img src={InfoIcon} /> : <img src={InfoIconDark} />
                }
            </div>
            {showTooltip ? renderTooltip(myStyles) : null}
        </div>
    );
}

export default applyWrappers(ToolTipComponent, styles);