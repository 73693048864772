import React, { useState } from 'react';
import UpArrow from 'images/transcript-arrow.svg'
import styles from './transcriptComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const TranscriptComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const [isTranscriptOpen, setIsTranscriptOpen] = useState(false)

    return (
        <div styleName="transcript-container" className={css(myStyles.transcriptContainer)}>
            <div
                styleName="transcript"
                onClick={() => { setIsTranscriptOpen(!isTranscriptOpen) }}
                className={css(myStyles.transcriptTitle)}
            >
                {
                    !isTranscriptOpen
                        ? props.getLabel('label_storyline_view_transcript_header')
                        : props.getLabel('label_storyline_hide_transcript_header')
                }
                <div styleName="icon">
                    {
                        !isTranscriptOpen
                            ? <img src={UpArrow} />
                            : <img src={UpArrow} style={{ transform: 'rotate(180deg)' }} />
                    }
                </div>
            </div>
            <div
                className={css(myStyles.transcriptText)}
                dangerouslySetInnerHTML={{ __html: props.getLabel(props.introData.transcript[0]) }}
                styleName={
                    isTranscriptOpen
                        ? "transcript-show"
                        : "transcript-hidden"
                }
            />
        </div>
    )
}

export default applyWrappers(TranscriptComponent, styles);
