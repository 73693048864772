import urlConsts from 'constants/url'
import netpack from 'utils/netpack'
import { setUiState } from '../uiState/actionCreators'
import store from 'store/store.js'

export const updateTimer = (payload) => {
    netpack(urlConsts.UPDATE_TIME_LEFT).post({
        body: JSON.stringify(payload)
    }).then((response) => {
        // console.log(response)
        if (response.gameOver) {
            // show game over popup
            let showGameOverPopup = {
                showOverlay: true,
                overlayComponentType: "CENTER",
                overlayComponent: "TIMEOVER_POPUP",
                gameOver: {simEnd: response.gameOver, simEndCause: "timeover"}
            };
            store.dispatch(setUiState(showGameOverPopup));
        }
    })
}