import React, { useState } from 'react';
import styles from './historyDropDown.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const HistoryDropDown = (props) => {
    const myStyles = getSkin(props.skinGuide);

    let [dropDownFlag, setDropDownFlag] = useState(false)

    const toggleDropDownMenu = () => {
        setDropDownFlag(!dropDownFlag)
    }

    const showMenuOptions = () => {
        let optionDivArray = []
        props.optionList.map(item => {
            optionDivArray.push(<div
                key={item.id}
                onClick={() => onMenuOptionClick(item.id)}
            >
                {
                    props.drowDownName === props.getLabel("label_team_member") &&
                    <img src={item.image} />
                }
                {item.name}
            </div>
            )
        })
        return optionDivArray
    }

    const onMenuOptionClick = (id) => {
        props.setOptionId(id)
        toggleDropDownMenu()
    }

    return (
        <div
            styleName='container'
            className={css(myStyles.container)}
            style={props.isDisabled ? { cursor: 'not-allowed' } : {}}
        >
            <div
                styleName='current-option'
                onClick={() => toggleDropDownMenu()}
                style={props.isDisabled ? { pointerEvents: 'none' } : {}}
            >
                {props.drowDownName} : {props.optionList.find(x => x.id === props.optionId).name}
            </div>
            {
                dropDownFlag &&
                <div styleName='dropdown-menu'>
                    {showMenuOptions()}
                </div>
            }
        </div>
    )
}

export default applyWrappers(HistoryDropDown, styles);
