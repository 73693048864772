import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        style1: {
            ...myTypography.body1,
            fontSize: '50px',
            textTransform: 'uppercase',
            color: skin.black
        },
        backgroundContainer: {
            background: `linear-gradient(110.3deg, #FFF3F5 55.03%, rgba(255, 255, 255, 0.58) 100%)`
        }
    });
}

export default getSkin;
