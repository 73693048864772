import React from "react";
import { connect } from "react-redux";
import { setUiState, setHighlightedSidebarComponent } from "actions/uiState/actionCreators";
import ObjectivesPopup from "components/objectivesPopup";

class VideoAndObjectivesContainer extends React.Component {
    render() {
        return <ObjectivesPopup {...this.props} />;
    }
}

VideoAndObjectivesContainer.propTypes = {};

VideoAndObjectivesContainer.defaultProps = {};

const mapStateToProps = state => {
    return state
};

const mapDispatchToProps = dispatch => ({
    setUiState: payload => {
        dispatch(setUiState(payload));
    },
    setHighlightedSidebarComponent: payload => {
        dispatch(setHighlightedSidebarComponent(payload));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoAndObjectivesContainer);
