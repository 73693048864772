import applyLabel from 'utils/LabelProvider';
import applySkin from 'providers/SkinProvider'
import CSSModules from 'react-css-modules'

const applyWrappers = (WrappedComponent, styles) => {
    return applyLabel(
        applySkin(
            CSSModules(
                WrappedComponent,
                styles,
                {
                    allowMultiple: true
                }
            )
        )
    );
}

export default applyWrappers;