import React from "react";
import { connect } from "react-redux";
import { setUiState, setHighlightedSidebarComponent } from "actions/uiState/actionCreators";
import { fetchLeaderboardData } from "actions/apiAction/fetchLeaderboardData";
import LeaderboardPopup from "components/leaderboard/leaderboardPopup";

class LeaderboardContainer extends React.Component {
    componentDidMount() {
        this.props.fetchLeaderboardData();
    }

    render() {
        return <LeaderboardPopup {...this.props} />;
    }
}

LeaderboardContainer.propTypes = {};

LeaderboardContainer.defaultProps = {};

const mapStateToProps = state => {
    return {
        userState: state.userState,
        leaderboard: state.leaderboard
    };
};

const mapDispatchToProps = dispatch => ({
    fetchLeaderboardData: () => {
        dispatch(fetchLeaderboardData());
    },
    setUiState: payload => {
        dispatch(setUiState(payload));
    },
    setHighlightedSidebarComponent: payload => {
        dispatch(setHighlightedSidebarComponent(payload));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderboardContainer);
