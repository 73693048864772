import React from "react";
import { connect } from "react-redux";
import { setUiState } from "actions/uiState/actionCreators";
import EndScreenPopup from "components/endScreenPopup";
import { setUserGameCompleted, setUserBudgetFinished } from 'actions/apiAction/userApis';
import analyticsUtil from 'utils/segmentUtil';

class EndScreenPopupContainer extends React.Component {
  clickGotIt = () => {
    //Segment integartion
    analyticsUtil.track("ENDSCREEN_POPUP_CLOSE",{ gameCompleted: true });
    
    let updatedUiState = {
      showOverlay: false,
      overlayComponentType: '',
      overlayComponent: ''
    };
    this.props.setUiState(updatedUiState);
    if(this.props.uiState.gameOver.simEndCause == "budget" && !this.props.uiState.isBudgetFinished){
      this.props.setUserBudgetFinished()   
    }else if(this.props.uiState.gameOver.simEnd || this.props.userInfo.userTimer == 0){
      this.props.setUserGameCompleted();
    }  
}


  render() {
    return <EndScreenPopup {...this.props}  clickGotIt={this.clickGotIt}/>;
  }
}

EndScreenPopupContainer.propTypes = {};

EndScreenPopupContainer.defaultProps = {};

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => ({
  setUiState: payload => {
    dispatch(setUiState(payload));
  },
  setUserGameCompleted: () => {
		dispatch(setUserGameCompleted());
  },
  setUserBudgetFinished: () => {
    dispatch(setUserBudgetFinished());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EndScreenPopupContainer);
