import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            background: skin.white,
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
        },
        containerDisabled: {
            background: '#FFF9F9',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.03)',
            borderRadius: '10px'
        },
        selectIcon: {
            background: skin.grayColor5,
            opacity: 0.3
        },
        actionName: {
            ...myTypography.body2,
            lineHeight: '16px',
            color: skin.black,
            opacity: 0.9
        },
        actionNameDisabled: {
            ...myTypography.body2,
            lineHeight: '16px',
            color: 'rgba(0, 0, 0, 0.4)',
            opacity: 0.9
        },
        hrLine: {
            background: skin.grayColor4
        },
        vrLine: {
            background: skin.grayColor3
        },
        actionDetails: {
            ...myTypography.body2,
            fontSize: '10px',
            lineHeight: '15px',
            color: skin.black
        },
        text: {
            opacity: 0.6
        },
        textActionDisabled: {
            ...myTypography.body2,
            fontSize: '10px',
            lineHeight: '14px',
            color: skin.warningTextColor
        }
    });
}

export default getSkin;
