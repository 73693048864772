import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { setUiState, setHighlightedSidebarComponent } from "actions/uiState/actionCreators";
import EndScreenComponent from "components/endScreenComponent";
import DesktopHeader from "components/header/desktopHeader";
import updateRoute from "utils/webAppRoutes/updateRoute";
import SideBarListComponent from "../components/sideBarListComponent";
import Overlay from "../components/commonComponents/overlay";
import { fetchReportPdf } from 'actions/apiAction/fetchReportPdf';
import { postMailReport } from 'actions/apiAction/mailReport';
import analyticsUtil from 'utils/segmentUtil';
class EndReportContainer extends Component {
    componentDidMount() {
        let { userInfo } = this.props;
        if (!userInfo.isGameCompleted && userInfo.isSimStarted) {
            updateRoute({ route: "/dashboard" });
        } else if (!userInfo.isSimStarted) {
            updateRoute({ route: "/intro" });
        }
    }

    onClickOfHistory = () => {
        this.props.setUiState({
            showOverlay: true,
            overlayComponentType: "CENTER",
            overlayComponent: "HISTORY_POPUP"
        });
    };

    onClickOfLeaderboard = () => {
        this.props.setUiState({
            showOverlay: true,
            overlayComponentType: "CENTER",
            overlayComponent: "LEADERBOARD_POPUP"
        });
    };

    onClickOfObjectives = () => {
        this.props.setUiState({
            showOverlay: true,
            overlayComponentType: "CENTER",
            overlayComponent: "VIDEO_AND_OBJECTIVES_POPUP"
        });
    };

    onClickOfIntermediateReport = () => {
        this.props.setUiState({
            showOverlay: true,
            overlayComponentType: "CENTER",
            overlayComponent: "INTERMEDIATE_REPORT_POPUP"
        });
    };

    downloadReportOnClick = () => {
        //Segment integartion
        analyticsUtil.track("DOWNLOAD_USER_REPORT_STARTED");
        this.props.setUiState({
            isReportDownloading: true
        });
        this.props.fetchReportPdf({});
    };

    render() {
        let baseRouterContainerStyle = {};
        if (this.props.uiState.showOverlay) {
            baseRouterContainerStyle = {
                ...baseRouterContainerStyle,
                opacity: "0.1"
            };
        }

        let container_size = {
            width: "100%"
        };
        return (
            <Fragment>
                <div className="base-app-box">
                    <DesktopHeader isIntro={true} {...this.props} />
                    <div className='app-body' style={baseRouterContainerStyle}>
                        <EndScreenComponent
                            {...this.props}
                            downloadReportOnClick={this.downloadReportOnClick}
                        />
                        <SideBarListComponent
                            uiState={this.props.uiState}
                            userInfo={this.props.userInfo}
                            onClickOfHistory={this.onClickOfHistory}
                            onClickOfObjectives={this.onClickOfObjectives}
                            onClickOfLeaderboard={this.onClickOfLeaderboard}
                            onClickOfIntermediateReport={this.onClickOfIntermediateReport}
                            setHighlightedSidebarComponent={this.props.setHighlightedSidebarComponent}
                        />
                    </div>
                </div>
                {this.props.uiState.showOverlay ? <Overlay {...this.props} /> : null}
            </Fragment>
        );
    }
}

EndReportContainer.propTypes = {};

EndReportContainer.defaultProps = {};

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => ({
    setUiState: payload => {
        dispatch(setUiState(payload));
    },
    setHighlightedSidebarComponent: (payload) => {
        dispatch(setHighlightedSidebarComponent(payload))
    },
    fetchReportPdf: (payload) => {
        dispatch(fetchReportPdf(payload));
    },
    postMailReport: (payload, callback) => {
		dispatch(postMailReport(payload, callback))
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(EndReportContainer);
