import React from 'react';
import styles from './flatButton.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const FlatButton = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const disabledStyle = {
        background: '#E8E8E8',
        cursor: 'not-allowed',
        pointerEvents: 'none'
    }

    return (
        <div
            styleName="button"
            className={css(myStyles.buttonText)}
            style={props.isDisabled ? disabledStyle : {}}
            onClick={props.onClickFunction}
        >
            {props.textLabel}
        </div>
    )
}

export default applyWrappers(FlatButton, styles);
