import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        graphLabel: {
            ...myTypography.body1,
            fontSize: '12px',
            textTransform: 'uppercase',
            fontWeight: 600,
            color: skin.white
        },
        value: {
            ...myTypography.body2,
            fontSize: '13px',
            fontWeight: 'bold',
            color: skin.white
        },
        targetValue: {
            ...myTypography.body2,
            fontSize: '10px',
            fontWeight: 600,
            color: skin.white
        },
        budgetChange: {
            ...myTypography.body1,
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#B6B6B6'
        }
    });
}

export default getSkin;
