import React from 'react';
import styles from './actorCategoryTable.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { hexToRgbA } from 'utils/styleUtil';
import upArrow from 'images/up-arrow.svg';

const ActorCategoryTable = (props) => {
    const myStyles = getSkin(props.skinGuide);

    let { categoryDetails, domains, isInitialDataShown, currentActorGraphDetails } = props

    const getDomainNames = () => {
        let domain1 = categoryDetails.subDomains[categoryDetails.subDomainsOrder[0]].domainId
        let domain2 = categoryDetails.subDomains[categoryDetails.subDomainsOrder[3]].domainId
        let domain3 = categoryDetails.subDomains[categoryDetails.subDomainsOrder[6]].domainId
        let domainArray = []

        domainArray.push(<div styleName='domain' key={domain1}>{props.getLabel(domains[domain1].name)}</div>)
        domainArray.push(<div styleName='domain' key={domain2}>{props.getLabel(domains[domain2].name)}</div>)
        domainArray.push(<div styleName='domain' key={domain3}>{props.getLabel(domains[domain3].name)}</div>)

        return domainArray
    }

    const getSubDomainNames = () => {
        let subDomainNameArray = []
        categoryDetails.subDomainsOrder.map((item, key) => {
            subDomainNameArray.push(
                <div styleName='subdomain' key={key}>{props.getLabel(categoryDetails.subDomains[item].name)}</div>
            )
        })

        return subDomainNameArray
    }

    const getSubDomainValues = (isInitialDataShown) => {
        let subDomainValueArray = []

        categoryDetails.subDomainsOrder.map((item, key) => {
            subDomainValueArray.push(
                <div styleName='subdomain' key={key}>
                    {
                        isInitialDataShown ?
                            <div className={css(myStyles.subdomainValue)}> {currentActorGraphDetails.subdomainsData.initial[item].value} </div>
                            :
                            <div styleName={currentActorGraphDetails.subdomainsData.current[item].offset == 0 ? "" : 'subdomain-change'}>
                                <div className={css(myStyles.subdomainValue)}>{currentActorGraphDetails.subdomainsData.current[item].value}</div>
                                {
                                    currentActorGraphDetails.subdomainsData.current[item].offset > 0 ?
                                        <span styleName="offset-circle" className={css(myStyles.greenTag)}>
                                            <img src={upArrow} />
                                            <div style={{ marginLeft: '3px', fontSize: '12px' }}>
                                                {currentActorGraphDetails.subdomainsData.current[item].offset}
                                            </div>
                                        </span>
                                        :
                                        currentActorGraphDetails.subdomainsData.current[item].offset < 0 ?
                                            <span styleName="offset-circle" className={css(myStyles.redTag)}>
                                                <img style={{ transform: 'rotate(180deg)' }} src={upArrow} />
                                                <div style={{ marginLeft: '3px', fontSize: '12px' }}>
                                                    {Math.abs(currentActorGraphDetails.subdomainsData.current[item].offset)}
                                                </div>
                                            </span> : null
                                }
                            </div>
                    }
                </div >
            )
        })

        return subDomainValueArray
    }

    let initialStyle = {
        backgroundColor: `${hexToRgbA('#A04EB5', 0.3)}`
    }

    return (
        <div styleName="container" key={categoryDetails.id}>
            <div styleName='header' className={css(myStyles.header)}>
                {props.getLabel(categoryDetails.name)}
            </div>
            <div styleName='domain-list' className={css(myStyles.domain)}>
                {getDomainNames()}
            </div>
            <div styleName='subdomain-list' className={css(myStyles.subdomain)}>
                {getSubDomainNames()}
            </div>
            <div styleName='subdomain-list' style={isInitialDataShown ? initialStyle : {}} className={css(myStyles.subdomain)}>
                {getSubDomainValues(isInitialDataShown)}
            </div>
        </div>
    )
}

export default applyWrappers(ActorCategoryTable, styles);
