import React from 'react';
import styles from './actorsListComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ActorCard from './actorCardComponent';
import ActorTutorial from './actorTutorial';

const ActorsListComponent = (props) => {
    const myStyles = getSkin(props.skinGuide)

    let isActorSelectionScreenOpen = props.actionSequence.actionStepNumber === 4

    const onCardClick = (id) => {
        // if (isActorSelectionScreenOpen) {
        //     props.saveSelectedActorId(id)
        // } else {
        props.saveShowActorId(id);
        props.getActorDetails(id)
        if (!props.actors.actorList[id].isSeen) {
            props.actorVisitedApi({
                actorId: id
            })
        }
        //}
    }

    //click on radio button
    const onActorSelect = (e, id) => {
        e.stopPropagation();
        props.saveSelectedActorId(id)
    }

    const getActorCards = () => {
        let actorCardArray = [];
        let { actorsOrder, actorList } = props.actors;
        actorsOrder && actorsOrder.map(item => {
            let currentItem = actorList[item];
            actorCardArray.push(<ActorCard
                onActorCardClick={onCardClick}
                onActorSelect={onActorSelect}
                key={currentItem.id}
                isActorSelectionScreenOpen={isActorSelectionScreenOpen}
                isCurrentActorSelected={props.actionSequence.selectedActorId === currentItem.id}
                actionSequence={props.actionSequence}
                {...currentItem}
                tutorial={props.tutorial}
                actionList={props.actionList}
                introData={props.introData}
                overallMetrics={props.overallMetrics}
                actor_image={currentItem.imagePath}
            />)
        });
        return actorCardArray;
    }

    return (
        <div styleName='container'>
            <div styleName='title' className={css(myStyles.title)}>{props.getLabel('label_dashboard_screen_text1')}</div>
            <div styleName='actors-container'>
                {getActorCards()}
                {
                    props.tutorial.isActorsTutorialRunning &&
                    <div styleName='actor-tutorial-container'>
                        <ActorTutorial
                            startReactJoyrideTutorial={props.startReactJoyrideTutorial}
                            updateTutorialStepNumber={props.updateTutorialStepNumber}
                            //To stop actor tutorial and start with bamba tutorial
                            setActorTutorialCompleted={props.setActorTutorialCompleted}
                            stopActorsTutorial={props.stopActorsTutorial}
                            showActionsUncoveredPage={props.showActionsUncoveredPage}
                            setActorTutorialSteps={props.setActorTutorialSteps}
                            tutorial={props.tutorial}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default applyWrappers(ActorsListComponent, styles);
