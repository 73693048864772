import actionConsts from 'constants/actions';

const saveShowActorId = (id) => {
    return {
        type: actionConsts.SAVE_SHOW_ACTOR_ID,
        payload: id
    }
}

const setActorVisitedFlag = (id) => {
    return {
        type: actionConsts.SET_ACTOR_VISITED_FLAG,
        payload: id
    }
}

const setActorGraphDetails = (payload) => {
    return {
        type: actionConsts.SET_ACTOR_GRAPH_DETAILS,
        payload
    }
}

export {
    saveShowActorId,
    setActorVisitedFlag,
    setActorGraphDetails
};