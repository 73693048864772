import React, { useState, useEffect, useRef, Fragment } from 'react';
import firebase from 'firebase';
import styles from './timerComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { updateTimer } from 'actions/timer'

let timerCallback = null;

const TimerComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const { firebaseConfig, userInfo } = props

    const [timerInitialized, setTimerInitialized] = useState(false);
    const [timeLeft, setTimeLeft] = useState(userInfo.userTimer)

    const timeRef = useRef(timeLeft);
    timeRef.current = timeLeft;

    useEffect(() => {
        // If the walkthrough is completed
        if (userInfo.isTimerStarted) {
            if (!timerInitialized) {
                // If firebase is not initialized, start the firebase connection
                if (!firebase.apps.length) {
                    firebase.initializeApp(firebaseConfig);
                }

                // Start timer using user timer data from backend, after authenticating the user
                firebase.auth().signInWithCustomToken(firebaseConfig.firebaseToken)
                    .then(() => {
                        startTimer(userInfo, timeLeft, setTimeLeft)
                    })
                    .catch((error) => {
                        // Handle Errors here.
                        console.log(error);
                    });

                setTimerInitialized(true)
            }
        }
    })

    const startTimer = (userInfo) => {
        if (timerCallback) {
            stopTimer();
        }

        timerCallback = setInterval(() => {
            if (timeRef.current >= 0) {
                updateFirebaseData(userInfo, timeRef.current);
                if (timeRef.current % 20 === 0) {
                    updateTimer({
                        timer: timeRef.current
                    })
                }
                setTimeLeft(timeRef.current - 1);
            } else {
                stopTimer();
            }
        }, 1000);
    };

    const stopTimer = () => {
        clearTimeout(timerCallback);
    };

    const updateFirebaseData = (userInfo, timeLeft) => {
        // checkFirebaseConnection()
        firebase.database()
            .ref("group/" + userInfo.groupId + "/user/" + userInfo.uliId)
            .set({
                timeLeft
            });
    };

    const checkFirebaseConnection = () => {
        //for now just stopping the timer on header, other bg actions still continue running
        let connectedRef = firebase.database().ref(".info/connected");

        connectedRef.on("value", function (snap) {
            if (!snap.val()) {
                alert("network error");
                stopTimer();
            }
        });
    };

    // Set time in min:sec format
    let minute = ("0" + Math.floor(timeLeft / 60)).slice(-2);
    let second = ("0" + Math.floor(timeLeft % 60)).slice(-2);

    if (timeLeft < 0) {
        minute = '00';
        second = '00';
    }

    return (
        <div styleName='outside-container' className={css(myStyles.outsideContainer)}>
            <div styleName='container' className={css(myStyles.container)}>
                <Fragment>{minute} : {second}</Fragment>
            </div>
        </div>
    )
}

export default applyWrappers(TimerComponent, styles);
