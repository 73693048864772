import actionConsts from 'constants/actions';

const initialState = [];

const actionList = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_GAME_DATA:
            return action.payload.actions;
        case actionConsts.ACTION_RESPONSE:
            let newState = state
            if (typeof newState[action.payload.actionId].currentAttempt === 'object') {
                newState[action.payload.actionId].currentAttempt[action.payload.actorId].attmptNo += 1
                newState[action.payload.actionId].lastTimeTaken[action.payload.actorId].lastTimeTaken = action.payload.dayTo
            } else {
                newState[action.payload.actionId].currentAttempt += 1
                newState[action.payload.actionId].lastTimeTaken = action.payload.dayTo
            }
            return newState
        default:
            return state;
    }
};

export default actionList;