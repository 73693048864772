import { parseErrorHandler, backendErrorHandler, networkErrorHandler } from 'actions/error';
import store from 'store/store';
import Netpack from 'netpack';

const defaultOptions = {
    credentials: 'include',
    headers: {
        'Content-type': 'application/json'
    }
};

const NetpackInstance = new Netpack(
    store.dispatch,
    { parseErrorHandler, backendErrorHandler, networkErrorHandler },
    defaultOptions
);

export default NetpackInstance.ajax;
