import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';
import ActionResponseBg from 'images/action-response-bg.svg'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
    const skin = globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        containerhead: {
            backgroundImage: `url(${ActionResponseBg})`,
        },
        container: {
            // backgroundImage: `linear-gradient(125.94deg, ${skin.primaryPopupBgColor} 4.65%, ${skin.secondaryPopupBgColor} 50.35%, ${skin.tertiaryPopupBgColor} 97.99%)`,
            backgroundImage: `url(${ActionResponseBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        responseContainer: {
            //background: `#FBFBFB`
        },

        personName: {
            ...myTypography.body1,
            fontStyle: 'italic',
            color: skin.grayColor4
        },

        delayedImpact: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '14px',
            lineHeight: '19px',
            color: skin.white,
        },

        seperator: {
            background: `linear-gradient(173.88deg, ${skin.primaryColor} 2.55%, ${skin.secondaryColor} 110.15%)`
        },
        responseText: {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: '34px',
            opacity: '0.4',
            color: '#FB7286'
        }
    });
}

export default getSkin;