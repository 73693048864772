import consts from 'constants/url';
import netpack from 'utils/netpack';
import { setActorVisitedFlag, setActorGraphDetails } from 'actions/actors'
import { setUiState } from '../uiState/actionCreators';

export const actorVisitedApi = (payload) => (dispatch) => {
    // Send actor id
    netpack(consts.SET_ACTOR_VISITED_FLAG_API).post({
        body: JSON.stringify(payload)
    }).then((response) => {
        if (response.success) {
            dispatch(setActorVisitedFlag(response.actorId))
        }
    });
}

export const getActorDetails = (payload) => (dispatch) => {
    let actorUrl = consts.GET_ACTOR_DETAILS + payload
    netpack(actorUrl).get()
        .then((response) => {
            if (response) {
                dispatch(setActorGraphDetails(response))

                //Open actor details component
                dispatch(setUiState({
                    showOverlay: true,
                    overlayComponentType: 'CENTER',
                    overlayComponent: 'ACTOR_DETAIL_COMPONENT'
                }));
            }
        })
}
