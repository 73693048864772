import React, { useState } from 'react';
import styles from './sideBarItemComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Leaderboard from 'images/leaderboard.png';
import Objectives from 'images/objectives.png';
import History from 'images/history.png';
import Report from 'images/report.png';
import SidebarArrow from 'images/sidebar-arrow.svg'

const SideBarItemComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const [showTooltip, setShowTooltip] = useState(false);

    let tooltipContent = ''

    const renderIcon = (image) => {
        switch (image) {
            case "leaderboard":
                tooltipContent = 'label_tutorial_sidebar_leaderboard_button'
                return (
                    <img src={Leaderboard} />
                );

            case "objectives":
                tooltipContent = 'label_tutorial_sidebar_objectives_button'
                return (
                    <img src={Objectives} />
                );
            case "history":
                tooltipContent = 'label_tutorial_sidebar_history_button'
                return (
                    <img src={History} />
                );
            case "report":
                tooltipContent = 'label_tutorial_sidebar_statistics_button_V2'
                return (
                    <img src={Report} />
                );
            default:
                return null;
        }
    }

    const onItemClick = () => {
        props.onClickCall()
        switch (props.image) {
            case "leaderboard":
                props.setHighlightedSidebarComponent(0)
                return
            case "objectives":
                props.setHighlightedSidebarComponent(1)
                return
            case "history":
                props.setHighlightedSidebarComponent(2)
                return
            case "report":
                props.setHighlightedSidebarComponent(3)
                return
            default:
                return;
        }
    }

    return (
        <div
            styleName="item"
            onClick={onItemClick}
            style={!props.isHighlighted ? { opacity: 0.1 } : {}}
            onMouseEnter={() => { setShowTooltip(true); }}
            onMouseLeave={() => { setShowTooltip(false); }}
        >
            {renderIcon(props.image)}
            {
                showTooltip ?
                    <div styleName="tooltip-container-right">
                        <img src={SidebarArrow} />
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{ __html: props.getLabel(tooltipContent) }}
                        ></div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default applyWrappers(SideBarItemComponent, styles);
