import { combineReducers } from 'redux'

import colorProfiles from './colorProfiles';
import uiState from './uiState/uiState';
import tutorial from './tutorial';
import actionSequence from './actionSequence';
import categories from './categories';
import actionList from './actionList';
import actors from './actors';
import labels from './labels';
import userActions from './userActions';
import leaderboard from './leaderboard';
import intermediateReport from './intermediateReport';
import overallMetrics from './overallMetrics';
import history from './history';
import userInfo from './userInfo';
import introData from './introData';
import userDelayedActions from './userDelayedAction';
import userEvent from './userEvent';
import userEventResponse from './userEventResponse';
import firebaseConfig from './firebaseConfig';
import currentActorGraphDetails from './currentActorGraphDetails'
import domains from './domains'

export default combineReducers({
    colorProfiles,
    uiState,
    tutorial,
    actionSequence,
    categories,
    actionList,
    labels,
    actors,
    userActions,
    leaderboard,
    intermediateReport,
    overallMetrics,
    history,
    userInfo,
    introData,
    userDelayedActions,
    userEvent,
    userEventResponse,
    firebaseConfig,
    currentActorGraphDetails,
    domains
})
