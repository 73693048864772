import React from 'react';
import getSkin from "./skin.js";
import styles from "./endScreenComponent.module.scss";
import { css } from "aphrodite/no-important";
import applyWrappers from "wrappers/ComponentWrapper";
import EndScreenIcon from "images/end-screen.svg";
import EndSecreemMetrics from "./endSecreemMetrics";
import DownloadReport from 'components/commonComponents/downloadReport';
import SendEmailReport from 'components/commonComponents/sendEmailReport';

const EndScreenComponent = props => {
    const myStyles = getSkin(props.skinGuide);
    const { userInfo } = props

    let isTargetAchieved = false;
    if (props.overallMetrics && props.overallMetrics['revenue'] && props.introData && props.introData.targetRevenue) {
        isTargetAchieved = props.overallMetrics['revenue']['metricValue'] >= props.introData.targetRevenue
            ? true
            : false;
    }
    const title = isTargetAchieved
        ? 'label_end_screen_title_1_achieved'
        : 'label_end_screen_title_1_not_achieved';

    return (
        <div styleName="outer_component">
            <div styleName="component">
                <div styleName="heading">
                    <img styleName="image" src={EndScreenIcon} />
                    <div styleName="info">
                        <div styleName="title" className={css(myStyles.title)}>
                            {props.getLabel(title)}
                        </div>
                        <div styleName="desc" className={css(myStyles.desc)}>
                            {
                                props.getLabel("label_end_screen_text_1")
                            }
                        </div>
                    </div>
                </div>
                <div styleName="metrics">
                    {<EndSecreemMetrics overallMetrics={props.overallMetrics} targetRevenue={props.introData.targetRevenue} />}
                </div>
                <div styleName="box" className={css(myStyles.box)}>
                    <div styleName="box-title" className={css(myStyles.boxTitle)}>
                        {" "}
                        {props.getLabel("label_end_screen_title_2")}
                    </div>
                    {/* <div styleName="feedback">
                        <div className={css(myStyles.feedbackTitle)}>
                            {" "}
                            {props.getLabel("label_end_screen_text_2")}
                        </div>
                        <div styleName="feedback-btn" className={css(myStyles.feedbackBtn)}>
                            {" "}
                            {props.getLabel("label_end_screen_button_1")}
                        </div>
                    </div> */}
                    {!userInfo.isVACEnabled ?<div styleName="buttons">
                        <DownloadReport {...props} />
                        <SendEmailReport {...props} />
                    </div> :  null }
                </div>
            </div>
        </div>
    );
};

export default applyWrappers(EndScreenComponent, styles);
