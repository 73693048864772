import React, { useState, Fragment } from 'react';
import styles from './actorTutorial.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import dottedArrowShort from 'images/dotted-arrow-short.svg'
import dottedArrowLarge from 'images/dotted-arrow-large.svg'

const ActorTutorial = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const [stepId, setStepId] = useState(1)

    const onPreviousButtonClick = () => {
        if (props.tutorial.type !== 'header') {
            props.setActorTutorialSteps()
        }
        props.startReactJoyrideTutorial()
        props.updateTutorialStepNumber(4)
    }

    const onActorTutorialComplete = () => {
        props.showActionsUncoveredPage()
        props.stopActorsTutorial()
        props.setActorTutorialCompleted()
    }

    const getTutorialStep = (id) => {
        // return div based on step number
        switch (id) {
            case 1:
                return (
                    <Fragment>
                        <div styleName='actor-card-step-container'>
                            <div styleName='small-arrow'>
                                <img src={dottedArrowShort} />
                            </div>
                            <div styleName='text-container'>
                                <div
                                    styleName='title'
                                    className={css(myStyles.title)}
                                    dangerouslySetInnerHTML={{ __html: props.getLabel('label_tutorial_actor_card_title') }}
                                >
                                </div>
                                <div className={css(myStyles.name)}>
                                    {props.getLabel('label_tutorial_actor_card_desc')}
                                </div>
                            </div>
                        </div>
                        <div styleName='happiness-bar-container'>
                            <div styleName='small-arrow'>
                                <img src={dottedArrowShort} />
                            </div>
                            <div styleName='text-container'>
                                <div className={css(myStyles.name)}>
                                    {props.getLabel('label_tutorial_actor_happiness_desc')}
                                </div>
                            </div>
                        </div>
                        <div styleName='unexplored-card-container'>
                            <div styleName='large-arrow'>
                                <img src={dottedArrowLarge} />
                            </div>
                            <div styleName='text-container'>
                                {/* <div styleName='title' className={css(myStyles.title)}>
                                    {props.getLabel('label_tutorial_actor_unexplored_card')}
                                </div> */}
                                <div className={css(myStyles.name)}>
                                    {props.getLabel('label_tutorial_actor_unexplored_card_desc')}
                                </div>
                            </div>
                        </div>
                        <div styleName='buttons'>
                            <div className='button previous-button' onClick={() => onPreviousButtonClick()}>
                                {props.getLabel('label_previous')}
                            </div>
                            <div className='button next-button next-btn-margin' onClick={() => onActorTutorialComplete()}>
                                {props.getLabel('label_tutorial_next_button')}
                            </div>
                        </div>
                    </Fragment>
                )

            case 2:
                return (
                    <div styleName='click-step-container'>
                        <div styleName='text-container'>
                            <div styleName='title' className={css(myStyles.title)}>
                                {props.getLabel('label_tutorial_view_any_actor')}
                            </div>
                            <div className={css(myStyles.name)}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                            </div>
                            <div className={css(myStyles.clickText)}>
                                CLICK ON ANY TEAM MEMBER
                            </div>
                        </div>
                    </div>
                )
        }
    }

    return (
        <div styleName='container'>
            {getTutorialStep(stepId)}
        </div>
    )
}

export default applyWrappers(ActorTutorial, styles);
