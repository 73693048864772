import actionConsts from 'constants/actions';

const initalState = {
    isReactJoyrideRunning: false,
    stepIndex: 0,
    tutorialStatus: -1,     //to store the state of tutorial
    steps: [],
    type: '',
    isActorsTutorialRunning: false,
    isActionsUncoveredPageShown: false,
    isActionsTutorialRunning: false
};

const tutorial = (state = initalState, action = {}) => {
    switch (action.type) {
        case actionConsts.START_TUTORIAL:
            return {
                ...state,
                isReactJoyrideRunning: true
            };
        case actionConsts.ADD_TUTORIAL_STEPS:
            return {
                ...state,
                steps: action.payload.steps,
                type: action.payload.type
            };
        case actionConsts.UPDATE_TUTORIAL_STEP:
            return {
                ...state,
                stepIndex: action.stepId
            }
        case actionConsts.END_TUTORIAL:
            return {
                isReactJoyrideRunning: false,
                stepIndex: 0,
                tutorialStatus: -1,     //to store the state of tutorial
                steps: [],
                type: ''
            };
        case actionConsts.PAUSE_TUTORIAL:
            return {
                ...state,
                isReactJoyrideRunning: false
            };
        case actionConsts.SET_END_TUTORIAL_POPUP_FLAG:
            return {
                ...state,
                isTutorialEndPopupOpen: action.payload
            };
        case actionConsts.START_ACTORS_TUTORIAL:
            return {
                ...state,
                isActorsTutorialRunning: true
            }
        case actionConsts.STOP_ACTORS_TUTORIAL:
            return {
                ...state,
                isActorsTutorialRunning: false
            }
        case actionConsts.SHOW_ACTIONS_UNCOVERED_PAGE:
            return {
                ...state,
                isActionsUncoveredPageShown: true
            }
        case actionConsts.HIDE_ACTIONS_UNCOVERED_PAGE:
            return {
                ...state,
                isActionsUncoveredPageShown: false
            }
        case actionConsts.START_ACTIONS_TUTORIAL:
            return {
                ...state,
                isActionsTutorialRunning: true
            }
        case actionConsts.STOP_ACTIONS_TUTORIAL:
            return {
                ...state,
                isActionsTutorialRunning: false
            }
        default:
            return state;
    }
};

export default tutorial;