import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        listTitle: {
            ...myTypography.subtitle,
            display: 'flex',
            alignItems: 'center',
            fontSize: '18px',
            lineHeight: '25px',
            fontWeight: '600',
            color: skin.grayColor1,
            opacity: 0.8
        },
        listBullet: {
            opacity: 0.5,
            backgroundColor: skin.black
        },
        listItemText: {
            ...myTypography.body,
            fontSize: '14px',
            lineHeight: '25px',
            color: skin.grayColor1
        },
        objectivesTextList: {
            ...myTypography.body,
            fontSize: '16px',
            lineHeight: '30px',
            color: skin.grayColor1
        }
    });
}

export default getSkin;
