import React from 'react';
import styles from './actorGraphs.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { ResponsiveLine } from '@nivo/line';
import IMG_ACTOR_ICON from 'images/Actor.png';
import ProgressBar from 'components/commonComponents/progressBarComponent';
import FlatButton from 'components/commonComponents/flatButton'

const ActorGraphs = (props) => {
    const myStyles = getSkin(props.skinGuide);
    const { introData } = props;
    const totalDays = introData['initialDuration'];
    let happinessGraphValues = props.currentActorGraphDetails.graphData
    const days = totalDays ? totalDays : 100;
    let metricsData = [];
    happinessGraphValues.forEach(ele => {
        if (ele.day != null) {
            let obj = {};
            obj.x = ele.day;
            obj.y = ele.list.find(item => item.type === 'happiness').value;
            metricsData.push(obj);
        }
    });
    const data = [
        {
            "id": "Happiness",
            "color": "hsl(178, 70%, 50%)",
            "data": metricsData
        }
    ];
    const getLineGraph = (data, totalDays) => {
        return (
            <ResponsiveLine
                data={data}
                height={170}
                margin={{ top: 10, right: 30, bottom: 50, left: 55 }}
                xScale={{ type: 'linear', min: 0, max: totalDays }}
                yScale={{ type: 'linear', min: 0, max: 100, stacked: true }}
                yFormat={function(value){ 
                    return value.toFixed(1);
                }}
                axisTop={null}
                axisRight={null}
                // axisBottom={null}
                // axisLeft={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: `${props.getLabel("label_actor_happiness_graph_legend2")}`,
                    legendOffset: 30,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: [0, 20, 40, 60, 80, 100],
                    legend: `${props.getLabel("label_actor_happiness_graph_legend1")}`,
                    legendOffset: -40,
                    legendPosition: 'middle'
                }}
                colors="#F79F58"
                enableGridX={false}
                pointSize={0}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh={true}
                isInteractive={true}
                enableSlices="x"
                sliceTooltip={({ slice }) => {
                    return (
                        <div
                            style={{
                                background: 'white',
                                padding: '9px 12px',
                                border: '1px solid #ccc',
                            }}
                        >
                            {slice.points.map(point => (
                                <div
                                    key={point.id}
                                    style={{
                                        color: '#000',
                                        padding: '2px 0',
                                        fontSize: '14px'
                                    }}
                                >
                                    <strong>{props.getLabel("label_days")}: </strong> {point.data.xFormatted} 
                                    <strong>,{props.getLabel("label_happiness_name")}: </strong> {point.data.yFormatted}%
                                </div>
                            ))}
                        </div>
                    )
                }}
            />
        )
    };

    let strings = [];
    let actor_name = `${props.getLabel(props.actorDetail.name)}`;
    actor_name = actor_name ? actor_name.split(" ")[0] : actor_name;
    strings["actor_history_graph_desc"] = `${props.getLabel("label_actor_happiness_graph_desc", "", { actor_name })}`;

    const selectActor = () => {
        props.saveSelectedActorId(props.saveShowActorId);
        props.onClickOfCloseActorDetailPopup();
    }

    return (
        <div styleName="containerhead" className={css(myStyles.style1)}>
            <div styleName="actorComponent">
                <img styleName="actor-image-component" src={props.actorDetail.imagePathProfile} />
                <div styleName="actor-component-desc">
                    <span styleName="actor-name" className={css(myStyles.actorName)}>
                        {props.getLabel(props.actorDetail.name)}
                    </span>
                    <span styleName="actor-designation" className={css(myStyles.actorDesignation)}>
                        {props.getLabel(props.actorDetail.role)}
                    </span>
                    <div styleName="progress-bar-component">
                        <ProgressBar value={props.actorDetail.happiness} isBarRounded={true} />
                    </div>
                </div>
            </div>
            <div styleName="actorHistoryGraphContainer">
                <div styleName="actorHistoryGraphDesc" className={css(myStyles.styleGraphDesc)}>
                    <span>{props.getLabel('label_actor_happiness_graph_title')} </span>
                    {strings['actor_history_graph_desc']}
                </div>
                <div styleName="actorHistoryGraph">
                    {getLineGraph(data, days)}
                </div>
            </div>
            {
                // This step is for selecting actor in individual action
                props.actionSequence && props.actionSequence.actionStepNumber === 4
                    && props.actionSequence.selectedActorId != props.saveShowActorId ?
                    <div styleName="selectMember"> <FlatButton
                        textLabel={props.getLabel('label_dashboard_choose_member_button')}
                        onClickFunction={() => selectActor()}
                    /> </div>
                    : null
            }
        </div>
    )


}

export default applyWrappers(ActorGraphs, styles);
