import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        title: {
            ...myTypography.subtitle,
            fontSize: '14px',
            color: skin.black,
            opacity: 0.8
        },
        actionStepDesc: {
            ...myTypography.body2,
            color: skin.black,
            opacity: 0.7
        },
        chooseText: {
            ...myTypography.subtitle,
            fontSize: '18px',
            marginTop: '25px',
            color: skin.black,
            opacity: 0.8,
            fontWeight: '600'
        },
        actionPath: {
            ...myTypography.subtitle,
            fontSize: '10px',
            color: skin.black,
            textTransform: 'uppercase',
            opacity: 0.5
        },
        actionDesc: {
            ...myTypography.body1,
            color: skin.black,
            opacity: 0.7
        },
        cancelButton: {
            ...myTypography.button,
            textTransform: 'uppercase',
            color: skin.buttonColor1,
            border: `1px solid ${skin.buttonColor1}`
        }
    });
}

export default getSkin;
