import React from 'react';
import styles from './gameMetricsGraphComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ProgressBarHappiness from 'components/commonComponents/progressBarComponent'
import ProgressBarRevenue from 'components/commonComponents/progressBarRevenueComponent'
import ToolTipComponent from 'components/commonComponents/toolTipComponent'
import metricsDecreaseIcon from 'images/metrics_decrease.svg'
import metricsIncreaseIcon from 'images/metrics_increase.svg'
import NoChangeIcon from 'images/metrics_no_change.svg'

const GameMetricsGraphComponent = (props) => {
    let metricsValue = props.value;
    if (props.name == `${props.getLabel("label_metric_happiness")}`)
        metricsValue = metricsValue + "%";
    else {
        metricsValue = "$" + metricsValue.toLocaleString('en-US');
    }

    let isScoreChanged = ['ACTION_REPONSE_POPUP', 'EVENT_POPUP', 'ACTION_EVENT_POPUP'].includes(props.uiState.overlayComponent)
    let metricsIcon = '', textColor;
    if (props.offset < 0) {
        metricsIcon = metricsDecreaseIcon
        textColor = '#FF4B4B'
    } else if (props.offset > 0) {
        metricsIcon = metricsIncreaseIcon
        textColor = '#0DD8A8'
    } else {
        metricsIcon = NoChangeIcon
    }

    const myStyles = getSkin(props.skinGuide);
    if (!props.name) {
        return (
            <div styleName='container' style={{ width: props.width }}>
            </div>
        )
    }
    return (
        <div styleName='container' style={{ width: props.width }}>
            <div styleName='text'>
                <div styleName='metric-name' className={css(myStyles.graphLabel)}>
                    <div styleName="header-space">{props.name}</div>
                    <ToolTipComponent
                        tooltipText={props.toolTip}
                        alignTooltipKey="RIGHT"
                        tooltipStyles={{
                            maxWidth: '220px'
                        }}
                    />
                </div>{
                    props.name == `${props.getLabel("label_metric_happiness")}` ?
                        <div className={css(myStyles.value)}>{metricsValue}</div> :
                        <div styleName="revenue-value" className={css(myStyles.value)}>{metricsValue}
                            <div className={css(myStyles.targetValue)}>/${(props.targetRevenue).toLocaleString('en-US')}</div>
                        </div>
                }

            </div>
            <div styleName='graph'>
                {!props.isRevenue ?
                    <ProgressBarHappiness
                        value={props.value}
                        isBarRounded={true}
                        hideValue={true}
                        metricsType={props.name}
                        happinessBar={true}
                    /> :
                    <ProgressBarRevenue
                        value={props.value}
                        isBarRounded={true}
                        hideValue={true}
                        metricsType={props.name}
                        targetRevenue={props.targetRevenue}
                    />
                }
            </div>
            {
                isScoreChanged && props.isRevenue &&
                <div styleName='value-changes'>
                    <img src={metricsIcon} />
                    <div className={css(myStyles.budgetChange)} style={{ color: textColor, marginLeft: '7px' }}>${Math.abs(props.offset)}</div>
                </div >
            }
            {
                isScoreChanged && !props.isRevenue &&
                <div styleName='value-changes'>
                    <img src={metricsIcon} />
                    <div className={css(myStyles.budgetChange)} style={{ color: textColor, marginLeft: '7px' }}>{Math.abs(props.offset)}%</div>
                </div >
            }
        </div>
    )
}

export default applyWrappers(GameMetricsGraphComponent, styles);
