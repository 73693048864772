import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        title: {
            ...myTypography.subtitle,
            fontSize: '24px',
            lineHeight: '33px',
            fontWeight: '600',
            opacity: '0.8',
            color: skin.black
        },
        text: {
            ...myTypography.body1,
            color: skin.black
        }
    });
}

export default getSkin;
