import React from 'react';
import styles from './welcomePage.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import FlatButton from 'components/commonComponents/flatButton'
import WelcomeIcon from 'images/welcome-illustration.svg'

const WelcomePage = (props) => {
    const myStyles = getSkin(props.skinGuide);

    let { firstName, emailId } = props.userInfo
    let playerName = firstName ? firstName : emailId

    return (
        <div styleName="container" className={css(myStyles.container)}>
            <div styleName="welcome-icon">
                <img src={WelcomeIcon} />
            </div>
            <div styleName="title" className={css(myStyles.title)}>{props.getLabel('label_welcome_page_header', '', { PLAYER_NAME: playerName })}</div>
            <div
                styleName="title"
                className={css(myStyles.text)}
                dangerouslySetInnerHTML={{ __html: props.getLabel('label_welcome_page_text') }}
            />
            <div styleName="proceed-button">
                <FlatButton textLabel={props.getLabel('label_welcome_screen_button')} onClickFunction={() => props.onFooterButtonClick()} />
            </div>
            {/* <div styleName="helper-text" className={css(myStyles.helperText)}>
                {props.getLabel('label_welcome_screen_timer_start_text')}
            </div> */}
        </div>
    )
}

export default applyWrappers(WelcomePage, styles);
