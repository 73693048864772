import React from 'react';
import styles from './progressBarRevenueComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const ProgressBarRevenueComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);
    const skin = props.skinGuide.globalProfiles.palette

    let targetRevenue = props.targetRevenue ? props.targetRevenue : 500000;

    const breakPoint = (props.value * 100)/targetRevenue;
    // let ProgressBarIcon = breakPoint >= 750000
    //         ? HappyProgressBarIcon
    //         : (breakPoint >= 500000 ? NeutralProgressBarIcon : SadProgressBarIcon);


    const BarStyle = props.isBarRounded 
    ?{
        borderRadius: '10px',
        backgroundImage: `linear-gradient(90deg, #00D7FF ${breakPoint - 8}%, #E6FBFF ${breakPoint}%, #F1F1F1 ${breakPoint - -13}%)`
    }
    :{
        backgroundImage: `linear-gradient(90deg, #00D7FF ${breakPoint - 8}%, #E6FBFF ${breakPoint}%, #F1F1F1 ${breakPoint - -13}%)`
    }

    return (
        <div styleName='container'>
            <div
                styleName='progress'
                style={BarStyle}
            />
            {/* <div styleName='progress-bar-icon'>
                <img src={ProgressBarIcon} />
            </div> */}
            {
                !props.hideValue &&
                <div styleName='progress-bar-value' className={css(myStyles.progressValue)}>
                    {props.value}
                </div>
            }
        </div>
    )
}

export default applyWrappers(ProgressBarRevenueComponent, styles);
