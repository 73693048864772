import actionConsts from 'constants/actions';

const initialState = {};

const firebaseConfig = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_GAME_DATA:
            let newState = action.payload.firebase
            newState.firebaseToken = action.payload.userInfo.firebaseToken
            return newState;
        default:
            return state;
    }
};

export default firebaseConfig;