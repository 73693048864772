import actionConsts from 'constants/actions';

const initialState = {};

const overallMetrics = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_GAME_DATA:
            return action.payload.metrics;
        case actionConsts.ACTION_RESPONSE:
        case actionConsts.EVENT_RESPONSE:
        case actionConsts.UPDATE_EVENT_METRICS:
            return action.payload.metrics.overallMetrics ? action.payload.metrics.overallMetrics : state;
        case actionConsts.UPDATE_HEADER_METRICS:
            return action.payload[0].metrics.overallMetrics
        default:
            return state;
    }
};

export default overallMetrics;