import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setUiState, setHighlightedSidebarComponent } from 'actions/uiState/actionCreators';
import { fetchHistoryData } from "actions/apiAction/fetchHistoryData";
import HistoryList from '../components/history/historyList'

class HistoryListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDataFor: "all"
        };
    }

    componentDidMount() {
        this.props.fetchHistoryData();
    }

    setShowData = (type) => {
        this.setState({
            showDataFor: type
        })
    }

    render() {
        return (
            <HistoryList {...this.props} onClickOfTab={this.setShowData} showDataFor={this.state.showDataFor} />
        );
    }
}

HistoryListContainer.propTypes = {};

HistoryListContainer.defaultProps = {};

const mapStateToProps = state => {
    return {
        uiState: state.uiState,
        history: state.history,
        actions: state.actionList,
        actors: state.actors,
        categories: state.categories
    };
};

const mapDispatchToProps = dispatch => ({
    fetchHistoryData: () => {
        dispatch(fetchHistoryData());
    },
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    setHighlightedSidebarComponent: (payload) => {
        dispatch(setHighlightedSidebarComponent(payload))
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryListContainer));
