import actionConsts from 'constants/actions';

const initialState = {};

const uiState = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_GAME_DATA:
            return action.payload.storylineIntro
        default:
            return state;
    }
};

export default uiState;