import actionConsts from 'constants/actions';

const initialState = [];

const labels = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_LABELS_DATA:
            return action.payload;
        default:
            return state;
    }
};

export default labels;