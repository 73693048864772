import actionConsts from 'constants/actions';

const startReactJoyrideTutorial = () => {
    return {
        type: actionConsts.START_TUTORIAL
    }
};

const pauseTutorial = () => {
    return {
        type: actionConsts.PAUSE_TUTORIAL
    }
};

const endTutorial = () => {
    return {
        type: actionConsts.END_TUTORIAL
    }
};

const addStepsToTutorial = (steps, type) => {
    return {
        type: actionConsts.ADD_TUTORIAL_STEPS,
        payload: {
            steps,
            type
        }
    };
}

const updateTutorialStepNumber = (id) => {
    return {
        type: actionConsts.UPDATE_TUTORIAL_STEP,
        stepId: id
    }
}

const setEndTutorialPopup = (flag) => {
    return {
        type: actionConsts.SET_END_TUTORIAL_POPUP_FLAG,
        payload: flag
    }
}

const startActorsTutorial = () => {
    return {
        type: actionConsts.START_ACTORS_TUTORIAL
    }
}

const stopActorsTutorial = () => {
    return {
        type: actionConsts.STOP_ACTORS_TUTORIAL
    }
}

const showActionsUncoveredPage = () => {
    return {
        type: actionConsts.SHOW_ACTIONS_UNCOVERED_PAGE
    }
}

const hideActionsUncoveredPage = () => {
    return {
        type: actionConsts.HIDE_ACTIONS_UNCOVERED_PAGE
    }
}

const startActionsTutorial = () => {
    return {
        type: actionConsts.START_ACTIONS_TUTORIAL
    }
}


const stopActionsTutorial = () => {
    return {
        type: actionConsts.STOP_ACTIONS_TUTORIAL
    }
}

export {
    startReactJoyrideTutorial,
    pauseTutorial,
    endTutorial,
    addStepsToTutorial,
    updateTutorialStepNumber,
    setEndTutorialPopup,
    startActorsTutorial,
    stopActorsTutorial,
    showActionsUncoveredPage,
    hideActionsUncoveredPage,
    startActionsTutorial,
    stopActionsTutorial
};