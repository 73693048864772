import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        progressValue: {
            fontFamily: 'Open Sans',
            fontSize: '10px',
            fontWeight: '800px',
            color: skin.black,
            opacity: '0.6',
            fontSize: '10px'
        }
    });
}

export default getSkin;
