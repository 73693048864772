import React from 'react';
import styles from './individualAction.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import actor_pic from 'images/Actor_Pic.svg'

const IndividualAction = (props) => {

	const myStyles = getSkin(props.skinGuide);
	// let listOfActor = [
	// 	{
	// 		image: actor_pic,
	// 		name: "Lee Min-Ho",
	// 		score: "20%",

	// 	},
	// 	{
	// 		image: actor_pic,
	// 		name: "Lee Min-Ho",
	// 		score: "20%",

	// 	},
	// 	{
	// 		image: actor_pic,
	// 		name: "Lee Min-Ho",
	// 		score: "20%",

	// 	},
	// 	{
	// 		image: actor_pic,
	// 		name: "Lee Min-Ho",
	// 		score: "20%",

	// 	},
	// 	{
	// 		image: actor_pic,
	// 		name: "Lee Min-Ho",
	// 		score: "20%",

	// 	},
	// 	{
	// 		image: actor_pic,
	// 		name: "Lee Min-Ho",
	// 		score: "20%",

	// 	},
	// ]
	let listOfActor = props.listOfActor;
	const renderActors = () => {
		let data = [];
		let item = listOfActor.map(function(actor,index){
			return renderListOfActor(actor,index)
		})
		data.push(item);
		return data;
	}

	const renderListOfActor = (actor,index) => {
		return(
			<div key={ index } styleName="individual">
				<img styleName="actorImage" src={actor.image}/>
				<div styleName="actor" className={css(myStyles.name)}>{props.getLabel(actor.name)}</div>
				<div styleName="individualScore" className={css(myStyles.score)}>{actor.score}</div>
			</div>)
	}
	return (
		<div>
			{renderActors()}
		</div>
	);
}

export default applyWrappers(IndividualAction, styles);