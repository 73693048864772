import actionConsts from 'constants/actions';
import defaultSkinGuide from 'defaults/skins';

const initialState = defaultSkinGuide

const colorProfiles = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_COLOR_PROFILE_DATA:
            return state
                .set('globalProfiles', action.payload.globalProfiles)
                .set('entityProfiles', action.payload.entityProfiles)
                .set('isColorDataFetched', true);
        default:
            return state;
    }
};

export default colorProfiles;