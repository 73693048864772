import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import ActionsBgImage from 'images/actions-bg.svg'

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            backgroundImage: `url(${ActionsBgImage})`
        },
        title: {
            ...myTypography.subtitle,
            fontSize: '14px',
            lineHeight: '19px',
            fontStyle: 'italic',
            color: skin.black
        },
        actionHint: {
            ...myTypography.body2,
            color: skin.warningTextColor
        },
        actionDesc: {
            ...myTypography.body1,
            color: skin.black,
            opacity: 0.7
        },
        actionTypes: {
            ...myTypography.body2,
            color: skin.black,
        },
        actionTextBG: {
            backgroundImage: `linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.79) 100%)`,
            borderLeft: `2px solid #FBDEE2`,
            borderRight: `2px solid #FDF8F5`
        }
    });
}

export default getSkin;
