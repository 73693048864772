import React from 'react';
import styles from './objectivesAndInstructionsPage.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import IntroListComponent from 'components/commonComponents/introListComponent'
import FlatButton from 'components/commonComponents/flatButton'
import ObjectivesIcon from 'images/objectives-icon.svg'
import PlayingConditionsIcon from 'images/playing-conditions-item.svg'
import IntroFooterBg from 'images/intro-footer-bg.png'

const ObjectivesAndInstructionsPage = (props) => {
    const myStyles = getSkin(props.skinGuide);

    let { objective, condition, initialTimer, initialDuration, initialBudget, targetRevenueObjective } = props.introData

    condition[0] = props.getLabel(condition[0], '', { TIMER_VALUE: Math.floor(initialTimer / 60) })
    condition[1] = props.getLabel(condition[1], '', { TIME_VALUE: initialDuration, NOTATION_VALUE: props.getLabel("label_days") })
    condition[2] = props.getLabel(condition[2], '', { CURRENCY_VALUE: '$', BUDGET_VALUE: initialBudget.toLocaleString('en-US') })
    let objectiveCondition = { CURRENCY_VALUE: "$", TARGETREV_VALUE: targetRevenueObjective.toLocaleString('en-US') };

    const objectivesProps = {
        optionList: objective,
        condition: objectiveCondition,
        title: 'label_storyline_objectives',
        listIcon: ObjectivesIcon,
        type: 'objectives'
    }

    const playingConditions = {
        optionList: condition,
        title: 'label_storyline_playing_conditions',
        listIcon: PlayingConditionsIcon,
        type: 'conditions'
    }

    return (
        <div styleName="external-container">
            <div
                styleName="container"
                className={css(myStyles.container)}
            >
                <div styleName="intro-body">
                    <IntroListComponent {...objectivesProps} />
                    <IntroListComponent {...playingConditions} />
                </div>
            </div>
            <div styleName="intro-footer">
                <FlatButton textLabel={props.getLabel('label_objective_screen_button')}
                    onClickFunction={() => props.onFooterButtonClick()} />
                <img src={IntroFooterBg} />
            </div>
        </div>
    )
}

export default applyWrappers(ObjectivesAndInstructionsPage, styles);
