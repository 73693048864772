import React, { useState } from 'react';
import ActorCategoryTable from './actorCategoryTable'
import styles from './actorDescription.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { ResponsiveRadar } from '@nivo/radar';
import Toggle from 'react-toggle'
import "react-toggle/style.css";
import ToolTipComponent from 'components/commonComponents/toolTipComponent'

const ActorDescription = (props) => {
    const myStyles = getSkin(props.skinGuide);
    const { categories, domains, currentActorGraphDetails } = props;

    const [isReadMoreOpen, setIsReadMoreOpen] = useState(false)

    const readMoreStyle = {
        height: 'unset',
        overflow: 'unset',
        minHeight: 'unset'
    }

    const currentBambaStyleOff = {
        color: '#000000',
        opacity: 0.3
    }

    const currentBambaStyleOn = {
        color: '#000000',
        opacity: 1
    }

    const intialBambaStyleOn = {
        color: '#A04EB5',
        opacity: 1
    }

    const intialBambaStyleOff = {
        color: '#000000',
        opacity: 0.3
    }
    const LabelComponent = ({ id, anchor }) => {
        let style1;
        if (id == props.getLabel('label_category_basic_needs')) {
            style1 = {
                transform: 'translate(-4%,-1%)'
            }
        } else if (id == props.getLabel('label_category_autonomy')) {
            style1 = {
                transform: 'translate(0%,4%)'
            }
        } else if (id == props.getLabel('label_category_belonging')) {
            style1 = {
                transform: 'translate(-3%, 4%)'
            }

        } else if (id == props.getLabel('label_category_mastery')) {
            style1 = {
                transform: 'translate(-2%, 4%)'
            }

        } else {
            style1 = {
                transform: 'translate(0%, -1%)'
            }
        }
        return (

            <g style={style1}>
                <text>{id && id[0]}</text>
            </g>
        )
    }

    let happinessGraphValues = currentActorGraphDetails.graphData
    let initialBambaValues = happinessGraphValues[0]
    let currentBambaValues = happinessGraphValues[happinessGraphValues.length - 1]

    let data = [];
    let initialText = props.getLabel('label_actor_bamba_initial_text');
    let currText = props.getLabel('label_actor_bamba_current_text');
    categories.categoriesOrder.forEach(cat => {
        let obj = {};
        let catName = categories.categoryList[cat].shortName //props.getLabel(categories.categoryList[cat].name)
        // var matches = catName.match(/\b(\w)/g);
        // var acronym = matches.join('');
        // if (acronym.length > 1) {
        //     acronym = acronym[0] + ' '
        // }
        obj.elements = catName;

        obj[initialText] = Math.round(initialBambaValues.list.find(item => item.type === 'category' && item.categoryId === cat).value);
        obj[currText] = Math.round(currentBambaValues.list.find(item => item.type === 'category' && item.categoryId === cat).value)
        // obj.currentValue = Math.floor(Math.random() * 100)
        data.push(obj);
    });
    let strings = {};
    strings['actorDescription'] = props.desc;

    let graphData = data.slice(2, data.length).concat(data.slice(0, 2));

    const renderBehaviorGraph = () => {
        return (
            <ResponsiveRadar
                data={graphData}
                keys={[initialText, currText]}
                indexBy="elements"
                maxValue={100}
                margin={{ top: 30, right: 10, bottom: 10, left: 10 }}
                curve="linearClosed"
                borderWidth={2}
                borderColor={{ from: 'color' }}
                gridLevels={1}
                gridShape="linear"
                gridLabelOffset={15}
                enableDots={true}
                dotSize={0}
                dotColor={{ theme: 'background' }}
                dotBorderWidth={2}
                dotBorderColor={{ from: 'color' }}
                enableDotLabel={true}
                dotLabel="none"
                dotLabelYOffset={-12}
                colors={['#A04EB5CC', '#35E794CC']}
                fillOpacity={0.1}
                blendMode="normal"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                isInteractive={true}
                gridLabel={LabelComponent}
            />
        );
    };
    const toggleInitialScales = () => {
        props.toggleInitialBambaScale();
    }

    let nameArray = []
    let initArray = []
    let currArray = []

    const getBambaLabels = () => {
        data.map((item, key) => {
            let name = item.elements;
            name = name.charAt(0).toUpperCase();

            nameArray.push(
                <div key={key} styleName='table-values'>
                    {name}
                </div>
            )
        })
        return nameArray
    }

    const getInitBambaValues = () => {
        data.map((item, key) => {
            initArray.push(
                <div key={key} styleName='table-values'>
                    {Math.round(item[initialText])}%
                </div>
            )
        })
        return initArray
    }

    const getCurrentBambaValues = () => {
        data.map((item, key) => {
            currArray.push(
                <div key={key} styleName='table-values'>
                    {Math.round(item[currText])}%
                </div>
            )
        })
        return currArray
    }

    let tableArray = []
    const showSubdomainTables = () => {
        categories.categoriesOrder.forEach(cat => {
            let categoryDetails = categories.categoryList[cat]
            tableArray.push(<ActorCategoryTable
                key={cat}
                categoryDetails={categoryDetails}
                categories={categories}
                domains={domains}
                currentActorGraphDetails={currentActorGraphDetails}
                isInitialDataShown={props.uiState.isInitialBambaScaleOn}
            />)
        })
        return tableArray
    }

    return (
        <div styleName="containerhead">
            <div styleName="actorDescComponent" style={isReadMoreOpen ? readMoreStyle : {}}>
                <div className={css(myStyles.aboutActor)}>{(props.getLabel("label_actor_about_text", '', { actor_name: props.getLabel(props.name).split(" ")[0] }))} </div>
                <div
                    styleName="actorDescription"
                    className={css(myStyles.actorDesc)}
                    dangerouslySetInnerHTML={{ __html: props.getLabel(strings['actorDescription']) }}
                />
            </div>
            <div
                style={isReadMoreOpen ? { visibility: 'hidden' } : {}}
                onClick={() => { setIsReadMoreOpen(true) }}
                className={css(myStyles.readMoreText)}
            >
                {props.getLabel("label_actor_read_more_link")}
            </div>
            <div styleName='hr-line' className={css(myStyles.hrLine)}></div>
            <div styleName="actorGraphComponent">
                <div styleName="bamba-title" className={css(myStyles.aboutActor)}>
                    {props.getLabel("label_actor_bamba_graph_title")}
                    <div styleName="tooltip">
                        <ToolTipComponent
                            tooltipText={props.getLabel("label_actor_bamba_graph_tooltip", "", { actor_name: props.getLabel(props.name).split(" ")[0] })}
                            alignTooltipKey="RIGHT"
                            tooltipStyles={{
                                maxWidth: '220px'
                            }}
                            dark={true}
                        />
                    </div>
                </div>
                <div styleName='bambaGraphAndTableContainer'>
                    <div styleName='actor-graph-container'>
                        <div styleName="actorGraph"> {renderBehaviorGraph(data)} </div>
                        <div styleName="graph-labels">
                            <div styleName='current'>
                                <div styleName='current-label'> </div>
                                <span styleName='current-text'> {props.getLabel("label_actor_bamba_current_text")} </span>
                            </div>
                            <div styleName='initial'>
                                <div styleName='initial-label'> </div>
                                <span styleName='current-text'> {props.getLabel("label_actor_bamba_initial_text")} </span>
                            </div>

                        </div>
                    </div>
                    {/* <div styleName='bamba-graph-labels'>
                        
                        
                    </div> */}
                    <div styleName='bamba-categories-values'>
                        <div styleName='table-header' className={css(myStyles.tableHeader)}>
                            {getBambaLabels()}
                        </div>
                        <div styleName='bamba-values' className={css(myStyles.bambaValues)}>
                            <div styleName='current-text1'>{props.getLabel("label_actor_bamba_current_text")}</div>
                            {getCurrentBambaValues()}
                        </div>
                        <div styleName='bamba-values' className={css(myStyles.bambaValues)}>
                            <div styleName='current-text2'>{props.getLabel("label_actor_bamba_initial_text")} </div>
                            {getInitBambaValues()}
                        </div>
                        <div styleName='info-text' className={css(myStyles.infoText)}>
                            {props.getLabel("label_actor_scroll_to_view_title")}
                        </div>
                    </div>
                </div>
                <div className={css(myStyles.tableDesc)}
                    dangerouslySetInnerHTML={{ __html: props.getLabel('label_actor_bamba_scales_desc_text') }}
                >
                </div>
                <div styleName='bamba-scales'>
                    <span styleName='bamba-scale-text' style={props.uiState.isInitialBambaScaleOn ? currentBambaStyleOff : currentBambaStyleOn}> {props.getLabel("label_actor_radio_button_title2")} </span>
                    <Toggle
                        className='custom-classname'
                        defaultChecked={props.uiState.isInitialBambaScaleOn}
                        onChange={toggleInitialScales}
                        icons={false}
                    />
                    <span styleName='bamba-scale-text1' style={props.uiState.isInitialBambaScaleOn ? intialBambaStyleOn : intialBambaStyleOff}> {props.getLabel("label_actor_radio_button_title1")} </span>
                </div>
                <div styleName='subdomain-tables'>
                    {showSubdomainTables()}
                </div>
            </div>
        </div>
    )


}

export default applyWrappers(ActorDescription, styles);
