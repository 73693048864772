import actionConsts from 'constants/actions';

const setUserGameStartedFlag = () => {
    return {
        type: actionConsts.SET_USER_GAME_STARTED
    }
}

const setUserGameCompletedFlag = () => {
    return {
        type: actionConsts.SET_USER_GAME_COMPLETED
    }
}

const setUserBudgetFinishedFlag = () => {
    return {
        type: actionConsts.SET_USER_BUDGET_FINISHED
    }
}

const setActorTutorialCompletedFlag = () => {
    return {
        type: actionConsts.SET_USER_ACTOR_TUTORIAL_COMPLETED
    }
}

const resetActorTutorialCompletedFlag = () => {
    return {
        type: actionConsts.RESET_USER_ACTOR_TUTORIAL_COMPLETED
    }
}

const setActionTutorialCompletedFlag = () => {
    return {
        type: actionConsts.SET_USER_ACTION_TUTORIAL_COMPLETED
    }
}

const setStartTimerFlag = () => {
    return {
        type: actionConsts.SET_START_TIMER_FLAG
    }
}

const startActionLoader = () => {
    return {
        type: actionConsts.START_ACTION_LOADER
    }
}

const stopActionLoader = () => {
    return {
        type: actionConsts.STOP_ACTION_LOADER
    };
}

export {
    setUserGameStartedFlag,
    setUserGameCompletedFlag,
    setUserBudgetFinishedFlag,
    setActorTutorialCompletedFlag,
    resetActorTutorialCompletedFlag,
    setActionTutorialCompletedFlag,
    setStartTimerFlag,
    startActionLoader,
    stopActionLoader
};