import React, { useState, Fragment, useEffect, useRef } from 'react';
import styles from './actionEventPopup.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Header from 'components/commonComponents/popup/header';
import Description from 'components/commonComponents/popup/description';
import ResponseMetrics from 'components/action/actionResponseMetrics';
import FlatButton from 'components/commonComponents/flatButton'
import ActionCardComponent from 'components/dashboardComponent/actionsComponent/actionsListComponent/actionCardComponent'
import LoadingPage from 'components/commonComponents/loadingPage'
import ActionEventResponse from 'components/event/actionEventResponse'
import analyticsUtil from 'utils/segmentUtil';

const ActionEventPopup = (props) => {
    const myStyles = getSkin(props.skinGuide);

    let { userEvent, userEventResponse } = props
    let { isEventLoading } = userEvent

    let withoutActionResponseBG = {
        backgroundImage: `linear-gradient(102.08deg,  #FFE9EB 0%, #FFF1E7 52.62%, #FFFBF9 100.04%)`,
        padding: '15px 44px 15px 44px'
    }

    let withActionResonseBG = {
        background: '#FFFFFF',
        padding: '15px 44px 15px 44px'
    }

    let actions = userEvent.eventActionsOrder.map(item => {
        return userEvent.eventActions[item].event_action
    })

    let eventData = {
        title: props.getLabel(userEvent.name),
        desc: props.getLabel(userEvent.desc),
        eventActionDesc: props.getLabel(userEvent.eventActionDesc)
    }

    analyticsUtil.track("ACTION_EVENT", {
        ...eventData,
        ...userEvent
    });

    let [currentActionId, setCurrentActionId] = useState(-1)

    let currentActionInfo = currentActionId !== -1 && userEvent.eventActions[currentActionId].event_action

    // console.log(userEventResponse.eventActionId);
    const saveSelectedEventActionId = (id) => {
        setCurrentActionId(id)
    }

    const onTakeActionButtonClick = () => {
        if (currentActionInfo) {
            analyticsUtil.track("ACTION_TAKEN_EVENT", {
                ...eventData,
                actionInfo: userEvent.eventActions[currentActionId].event_action
            });
            props.actionEventCompleted({
                "eventId": props.userEvent.id,
                "eventActionId": currentActionId
            })
        }
    }

    const getActionCard = (startPoint, endPoint) => {
        let divArray = []
        for (let i = startPoint; i <= endPoint; i++) {
            divArray.push(<ActionCardComponent
                key={i}
                {...actions[i]}
                isEventAction={true}
                saveSelectedActionId={saveSelectedEventActionId}
                isCurrentActionSelected={actions[i].id === currentActionId}
                introData={props.introData}
                overallMetrics={props.overallMetrics}
                userEventResponse={userEventResponse}
            />)
        }
        return divArray
    }

    const actionEventResponseRef = useRef(null)

    const scrollToBottom = () => {
        // Wont work well in ie 11
        actionEventResponseRef.current.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        if (userEventResponse.eventActionId !== -1) {
            scrollToBottom()
        }
    });

    return (
        <div className={css(myStyles.container)} styleName="container">
            <div className={css(myStyles.seperator)} styleName="headseperator"></div>
            {
                isEventLoading ?
                    <LoadingPage />
                    :
                    <Fragment>
                        <div className={css(myStyles.responseContainer)} styleName="response-container">
                            <div styleName="first-half">
                                <div styleName="title">
                                    <Header title={eventData.title} />
                                </div>
                                <div styleName="response-message">
                                    <Description desc={eventData.desc} fontSizeSmaller={true} />
                                </div>
                                {/* {
                                    Object.keys(userEvent.metrics).length &&
                                    <div styleName='event-metric-changes'>
                                        <ResponseMetrics popup="action-event" {...userEvent} />
                                    </div>
                                } */}
                            </div>
                            <div style={userEventResponse.eventActionId !== -1 ? withActionResonseBG : withoutActionResponseBG}>
                                <div className={css(myStyles.actionEventTitle)} >
                                    {eventData.eventActionDesc}
                                </div>
                                <div styleName="sub-container">
                                    <div>
                                        <div styleName="cards">
                                            {getActionCard(0, userEvent.eventActionsOrder.length - 1)}
                                        </div>
                                        {
                                            userEventResponse.eventActionId === -1 &&
                                            <Fragment>
                                                {
                                                    currentActionId > -1 &&
                                                    <div styleName="action-desc">
                                                        <div className={css(myStyles.descMessage)}>
                                                            {/* <b>{"Description:"}</b> */}
                                                            {props.getLabel(currentActionInfo.desc)}
                                                        </div>
                                                    </div>
                                                }
                                                <div styleName="takeAction">
                                                    <FlatButton
                                                        textLabel={props.getLabel("label_dashboard_take_action_button")}
                                                        isDisabled={currentActionId === -1}
                                                        onClickFunction={() => onTakeActionButtonClick()}
                                                    />
                                                </div>
                                            </Fragment>
                                        }

                                    </div>
                                </div>
                                {
                                    userEventResponse.eventActionId !== -1 && <ActionEventResponse {...props} />
                                }
                                <div ref={actionEventResponseRef} />
                            </div>
                        </div>
                    </Fragment>
            }
        </div>
    )
}

export default applyWrappers(ActionEventPopup, styles);
