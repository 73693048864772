import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
    const skin = globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            background: `linear-gradient(106.44deg, #FFEDED 4.65%, #FCF5EF 50.35%, ${skin.tertiaryPopupBgColor} 97.99%)`,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
        },
        head: {
            background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`
        },
        title: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '25px',
            color: skin.white
        },
        quarters: {
            background: skin.white
        },
        report: {
            background: `linear-gradient(106.44deg, #FFEDED 4.65%, #FCF5EF 50.35%, ${skin.tertiaryPopupBgColor} 97.99%)`,
        },
        action: {
            background: skin.white,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
        },
        name: {
            fontFamily: "Nunito",
            fontStyle: "normal",
            fontSize: "12px",
            lineHeight: "12px",
            color: skin.black
        },
        enable: {
            fontWeight: "bold"
        },
        disable: {
            fontWeight: "normal",
            opacity: "0.5",
            pointerEvents: 'none'
        },
        line: {
            background: `linear-gradient(167.37deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`
        },
        setActive: {
            textDecoration: 'underline'
        },
    });
}

export default getSkin;