import actionConsts from 'constants/actions';

const initialState = {
    actionStepNumber: 0,
    selectedCategoryId: -1,
    selectedSubDomainId: -1,
    selectedActionId: -1,
    selectedActorId: -1,
    saveShowActorId: -1
};

// The ids will be -1 if the corresponding field is not selected

const actionSequence = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.SAVE_SELECTED_CATEGORY_ID:
            return {
                ...state,
                selectedCategoryId: action.payload
            }
        case actionConsts.SAVE_SELECTED_SUBDOMAIN_ID:
            return {
                ...state,
                selectedSubDomainId: action.payload
            }
        case actionConsts.SAVE_SELECTED_ACTION_ID:
            return {
                ...state,
                selectedActionId: action.payload
            }
        case actionConsts.SAVE_SELECTED_ACTOR_ID:
            return {
                ...state,
                selectedActorId: action.payload
            }
        case actionConsts.SAVE_SHOW_ACTOR_ID:
            return {
                ...state,
                saveShowActorId: action.payload
            }
        case actionConsts.SET_ACTION_STEP_NUMBER:
            return {
                ...state,
                actionStepNumber: action.stepId
            }
        case actionConsts.RESET_ACTION_SEQUENCE:
            return initialState
        default:
            return state;
    }
};

export default actionSequence;