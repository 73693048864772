import React from 'react';
import styles from './lineGraph.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { ResponsiveLine } from '@nivo/line';

const LineGraph = (props) => {
	const myStyles = getSkin(props.skinGuide);
	let graphData = [];
	if (props.graphData && props.graphData.length > 0) {
		props.graphData.forEach(ele => {
			let obj = {};
			obj.x = ele.day;
			obj.y = ele.metricValue;
			graphData.push(obj);
		})
	}
	const data = [
		{
			"id": "Happiness", // turkish
			"color": "hsl(178, 70%, 50%)",
			"data": graphData
		}
	];

	const theme = {
		axis: {
			fontFamily: "Open Sans",
			fontSize: "10px",
			tickColor: "#eee",
			ticks: {
				line: {
					stroke: "#555555"
				},
				text: {
					fill: "#031A20",
					opacity: 0.8,
					fontFamily: "Open Sans",
					fontSize: "8px",
				}
			},
			legend: {
				text: {
					fill: "#031A20",
					opacity: 0.8,
				}
			}
		},
	};


	return (
		<div className={css(myStyles.body)} styleName="body">
			<div styleName="heading" className={css(myStyles.title)}>
				{props.title}
			</div>
			<div styleName="desc" className={css(myStyles.description)}>
				{props.desc}
			</div>
			<ResponsiveLine
				data={data}
				margin={{ top: 33, right: 50, bottom: 120, left: 80 }}
				xScale={{ type: 'linear', min: 0, max: props.xaxis }}
				yScale={{ type: 'linear', min: 0, max: "auto", stacked: true }}
				axisTop={null}
				axisRight={null}
				yFormat={props.yFormat}
				axisBottom={{
					orient: 'bottom',
					tickSize: 0,
					tickPadding: 5,
					tickRotation: 0,
					legend: props.getLabel('label_tooltip_days_title'),
					legendOffset: 30,
					legendPosition: 'middle'
				}}
				axisLeft={{
					orient: 'left',
					tickSize: 0,
					tickPadding: 5,
					tickRotation: 0,
					legend: props.axisLeft,
					legendOffset: props.legendOffset,
					legendPosition: 'middle',
					format: function (value) {
						return props.isHappinessTrend
							? value + '%'
							: '$' + value.toLocaleString('en-US')
					},
					tickValues: props.gridYValues,
				}}
				colors="#35E794"
				pointSize={0}
				pointColor={{ theme: 'background' }}
				pointBorderWidth={2}
				pointBorderColor={{ from: 'serieColor' }}
				pointLabel="y"
				pointLabelYOffset={-12}
				enableArea={true}
				areaOpacity={0.25}
				gridYValues={props.gridYValues}
				isInteractive={true}
				useMesh={true}
				enableGridX={false}
				enableGridY={true}
				theme={theme}
				enableSlices="x"
				sliceTooltip={({ slice }) => {
					return (
						<div
							style={{
								background: 'white',
								padding: '9px 12px',
								border: '1px solid #ccc',
							}}
						>
							{slice.points.map(point => (
								<div
									key={point.id}
									style={{
										color: "#000",
										padding: '2px 0',
										fontSize: '14px'
									}}
								>
									<strong>{props.getLabel("label_history_day")}: </strong> {point.data.xFormatted}
									<strong>,{props.hoverText}: </strong> {point.data.yFormatted}
								</div>
							))}
						</div>
					)
				}}
			/>
		</div>
	);
}

export default applyWrappers(LineGraph, styles);