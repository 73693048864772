import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            ...myTypography.body2,
            fontWeight: '500',
            color: skin.white
        },
        item: {
            background: 'blue'
        },
        tooltipContent: {
            border: `1px solid ${skin.secondaryColor}`,
            fontWeight: 600,
            fontSize: '9px',
            borderRadius: '10px',
            background: skin.white,
            color: skin.black
        },
    });
}

export default getSkin;
