import consts from 'constants/url';
import actionConsts from 'constants/actions';
import netpack from 'utils/netpack';
import { resetActionSequence } from '../actionSequence';
import { setUiState, setDisplayMonthlyReportFlag } from '../uiState/actionCreators';
import { actionResponseReceived } from '../apiResponse';
import { setNextEvent } from 'actions/event';
import { startActionLoader, stopActionLoader } from '../user';


export const actionTaken = (payload) => (dispatch) => {
    //dispatch actionInfo fetching
    dispatch(startActionLoader())
    const actionDataPromise = netpack(consts.ACTION_API).post({
        body: JSON.stringify(payload)
    }).then((response) => {
        dispatch(resetActionSequence());
        //dispatch action data received
        dispatch((actionResponseReceived(response)));
        return response;
    });
    return Promise.all([actionDataPromise]).then((actionData) => {
        console.log("[actionTaken]actionData",actionData)
        dispatch(stopActionLoader())
        dispatch(setUiState({
            showOverlay: true,
            overlayComponentType: "BOTTOM",
            overlayComponent: "ACTION_REPONSE_POPUP",
            isTargetAchieved: actionData[0].isTargetAchieved,
            gameOver: actionData[0].gameOver
        }));
        // Show the monthly report
        if (actionData[0].monthlyReport.showMonthlyReport) {
            dispatch(setDisplayMonthlyReportFlag(actionData[0]))
        }
        //   Set event data, if it exists
        if (actionData[0].events) {
            dispatch(setNextEvent(actionData[0]))
        }
        return actionData;
    });
}
