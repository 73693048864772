import actionConsts from 'constants/actions';

const initialState = {
    usersList: [],
	isLeaderboardFetching: false
};

const leaderboard = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.LEADERBOARD_DATA:
            return {
				...state,
				usersList: action.payload,
				isLeaderboardFetching: false
            };
        case actionConsts.UPDATE_LEADERBOARD_STATUS:
            return {
                ...state,
                isLeaderboardFetching: action.payload
            };
        default:
            return state;
    }
};

export default leaderboard;