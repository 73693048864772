import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
    const skin = globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        leftMetricsContainer: {
            // borderRight: `solid 1px ${hexToRgbA(skin.grayColor1, 0.1)}`
        },
        metricTitle: {
            ...myTypography.subtitle,
            //background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`,
            fontWeight: 600,
            fontSize: '12px'
        },
        metricTitleSmall: {
            ...myTypography.subtitle,
            //background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`,
            fontWeight: 600,
            fontSize: '9px'
        },
        daysTitle: {
            //background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`,
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '10px',
        },
        delayedAction: {
            background: skin.white
        },
        delayedActionTitle: {
            //background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`,
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px'
        },

        metricValue: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: 'normal',
            color: skin.black
        },
        metricValueSmall: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: 'normal',
            color: skin.black
        },

        metricChange: {
            ...myTypography.body1,
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: 'normal',
            color: skin.white,
        },
        greenTag: {
            background: `#7CEBD1`
        },
        redTag: {
            background: `#FF9292`
        },
        gameMetricContainer: {
            // borderRight: `solid 1px ${hexToRgbA(skin.grayColor1, 0.1)}`
            // marginRight: '14px'
        },

        gameMetricContainerRightBorder: {
            borderRight: `solid 1px ${hexToRgbA(skin.grayColor1, 0.1)}`
        },
        gameMetricsContainer: {
            background: skin.white,
            border: '0.5px solid #FB7A7F',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
            borderRadius: '19px',
            //paddingTop: '7px'
            padding: '11px 19px 13px 14px',
            width: '212px'
        },

        rightMetricLeftBorder: {
            borderLeft: `1px solid ${skin.grayColor5}`
        },

        daysFrom: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            textTransform: 'uppercase',
            color: skin.black,
            opacity: 0.4,
            // marginRight: '6.57px'
        },

        daysTo: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            textTransform: 'uppercase',
            color: skin.black,
            opacity: 0.8,
        },
        barTitle:{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '19px',
            color: skin.black
        },
        targetRevenue:{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '10px',
            lineHeight: '16px',
            color: skin.black
        }
    });
}

export default getSkin;