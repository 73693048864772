import React from 'react';
import styles from './dashboardComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ActorsListComponent from './actorsListComponent'
import ActionsComponent from './actionsComponent'
import ActorTutorialCompletedPage from './actorTutorialCompletedPage'

const DashboardComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);

    return (
        <div
            styleName="dashboard-component"
            className={`${css(myStyles.dashboardComponent)}`}
            style={props.dashboardContainerStyle}
        >
            <ActorsListComponent
                tutorial={props.tutorial}
                actionSequence={props.actionSequence}
                saveSelectedActorId={props.saveSelectedActorId}
                saveShowActorId={props.saveShowActorId}
                actors={props.actors}
                startReactJoyrideTutorial={props.startReactJoyrideTutorial}
                updateTutorialStepNumber={props.updateTutorialStepNumber}
                actorVisitedApi={props.actorVisitedApi}
                getActorDetails={props.getActorDetails}
                setActorTutorialCompleted={props.setActorTutorialCompleted}
                stopActorsTutorial={props.stopActorsTutorial}
                showActionsUncoveredPage={props.showActionsUncoveredPage}
                actionList={props.actionList}
                setActorTutorialSteps={props.setActorTutorialSteps}
                introData={props.introData}
                overallMetrics={props.overallMetrics}
            />
            <ActionsComponent
                tutorial={props.tutorial}
                stopActionsTutorial={props.stopActionsTutorial}
                startReactJoyrideTutorial={props.startReactJoyrideTutorial}
                updateTutorialStepNumber={props.updateTutorialStepNumber}
                categories={props.categories}
                actorList={props.actors.actorList}
                actionList={props.actionList}
                actionSequence={props.actionSequence}
                saveSelectedCategoryId={props.saveSelectedCategoryId}
                saveSelectedSubDomainId={props.saveSelectedSubDomainId}
                saveSelectedActionId={props.saveSelectedActionId}
                saveSelectedActorId={props.saveSelectedActorId}
                setActionStepNumber={props.setActionStepNumber}
                resetActionSequence={props.resetActionSequence}
                actionTaken={props.actionTaken}
                setActionTutorialCompleted={props.setActionTutorialCompleted}
                userInfo={props.userInfo}
                introData={props.introData}
                overallMetrics={props.overallMetrics}
            />
            {
                // If actor tutorial completed flag is set and actions uncovered page flag is true, show the page
                props.userInfo.isActorTutorialCompleted && props.tutorial.isActionsUncoveredPageShown &&
                <ActorTutorialCompletedPage
                    tutorial={props.tutorial}
                    onShowBambaButtonClick={props.onShowBambaButtonClick}
                    resetActorTutorialCompleted={props.resetActorTutorialCompleted}
                    startActorsTutorial={props.startActorsTutorial}
                    hideActionsUncoveredPage={props.hideActionsUncoveredPage}
                />
            }
        </div>
    )
}

export default applyWrappers(DashboardComponent, styles);
