import React from 'react';
import styles from './endSecreemMetrics.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import budgetIcon from 'images/actionresponse-budget.svg';
import daysIcon from 'images/actionresponse-days.svg';
import ProgressBarHappiness from 'components/commonComponents/progressBarComponent'
import ProgressBarRevenue from 'components/commonComponents/progressBarRevenueComponent'

const EndSecreemMetrics = (props) => {
    const myStyles = getSkin(props.skinGuide);
    const renderGameMetrics = (title, value) => {
        return (
            <div
                className={css(myStyles["gameMetricContainer"])}
                styleName={"game-metric-container"}
                key={Math.random()}
            >
                <div className={css(myStyles["metricTitleSmall"
                ])} styleName="metric-title">
                    {title}
                </div>
                <div styleName={"left-metrics-value-change-container"}>
                    <div className={css(myStyles["metricValueSmall"
                    ])}>{value}</div>
                    {/* {renderTag(changeValue, changeType)} */}
                </div>
            </div >
        );
    }

    const renderMainMetrics = (title, value, metricsType) => {
        return (
            <div
                className={css(myStyles["gameMetricsContainer"])}
                styleName={"game-metric-container"}
                key={value}
            >
                <div styleName="bar">
                    <div styleName="bar-title" className={css(myStyles["barTitle"])}> {title} </div>
                    {metricsType == "Revenue"
                        ? <div styleName="revenue-value" className={css(myStyles["metricValue"])}>
                            {"$" + Math.round(value).toLocaleString('en-US')}
                            <div
                                className={css(myStyles["targetRevenue"])}>/{(props.targetRevenue).toLocaleString('en-US')}
                            </div>
                        </div>
                        : <div className={css(myStyles["metricValue"])}>{value + '%'}</div>}
                </div>
                <div styleName='progress-bar'>
                    {metricsType != "Revenue"
                        ? <ProgressBarHappiness
                            value={value}
                            isBarRounded={true}
                            hideValue={true}
                            isEndScreen={true}
                        />
                        : <ProgressBarRevenue
                            value={value}
                            isBarRounded={true}
                            hideValue={true}
                            metricsType={metricsType}
                            targetRevenue={props.targetRevenue}
                        />
                    }
                </div>
            </div >
        );
    }

    const renderBudgetOrDays = (title, value, icon) => {

        let val;
        if (icon == budgetIcon) {
            val = value > 0 ? "$" + Math.round(value).toLocaleString('en-US') : "$0";
        } else {
            val = value > 0 ? value + " " + props.getLabel('label_tooltip_days_title') : "0 " + props.getLabel("label_history_day");
        }

        return (
            <div styleName={"containerSpace"} key={value}>
                <div className={css(myStyles["daysTitle"])} styleName="days-title">
                    <img styleName="iconSpace" src={icon} />
                    {title}
                </div>
                <div styleName={"left-metrics-value-change-container"}>
                    <div className={css(myStyles["metricValueSmall"])}>{val}</div>
                    {/* {renderTag(changeValue, changeType)} */}
                </div>
            </div>
        );
    }


    const renderTopMetrics = () => {
        return (
            <div styleName={"game-metrics-container"} key={props.id}>
                {renderMainMetrics(
                    props.getLabel("label_metric_happiness"),
                    (+props.overallMetrics['happiness']['metricValue']).toFixed(1)
                )}
                {renderMainMetrics(
                    props.getLabel("label_metric_revenue"),
                    (+props.overallMetrics['revenue']['metricValue']).toFixed(0),
                    'Revenue'
                )}
                {/* {<div styleName="seperator"></div>}
                {renderGameMetrics(
                    props.getLabel("label_metric_satisfaction"),
                    (+props.overallMetrics['satisfaction']['metricValue']).toFixed(1) + "%"
                )}
                {renderGameMetrics(
                    props.getLabel("label_metric_engagement"),
                    (+props.overallMetrics['engagement']['metricValue']).toFixed(1) + "%"
                )}
                {renderGameMetrics(
                    props.getLabel("label_metric_motivation"),
                    (+props.overallMetrics['motivation']['metricValue']).toFixed(1) + "%"
                )} */}
                {<div styleName="seperator"></div>}
                {renderBudgetOrDays(
                    props.getLabel("label_end_screen_budget_text"),
                    (+props.overallMetrics['budget']['metricValue']).toFixed(0),
                    budgetIcon
                )}
                {renderBudgetOrDays(
                    props.getLabel("label_end_screen_days_text"),
                    (+props.overallMetrics['duration']['metricValue']).toFixed(0),
                    daysIcon
                )}
            </div>
        );
    }

    return (
        <div styleName={"metrics-container"}>
            {renderTopMetrics()}
        </div>
    );
}

export default applyWrappers(EndSecreemMetrics, styles);
