import consts from 'constants/url';
import netpack from 'utils/netpack';
import actionConsts from 'constants/actions';

export const fetchHistoryData = () => (dispatch) => {
    netpack(consts.HISTORT_API)
        .get()
        .then((response) => {
            dispatch({
                type: actionConsts.HISTORY_DATA,
                payload: response
            })
        });
    }
