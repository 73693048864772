import React from 'react';
import styles from './actorDetail.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ActorDescription from './actorDescription';
import ActorHistory from './actorHistory';
import ActorGraphs from './actorGraphs';
import IMG_ACTION_RESPONSE_CLOSE_ICON from 'images/action_response_close.png';
import analyticsUtil from 'utils/segmentUtil';

const ActorDetail = (props) => {
    let { actorList } = props.actors;
    let actorDetail = actorList[props.saveShowActorId];

    //Segment integartion
    analyticsUtil.track("ACTOR_PROFILE_OPEN", { actorName: actorDetail.name });

    const myStyles = getSkin(props.skinGuide);
    const onClickOfCloseActorDetailPopup = () => {
        if (props.tutorial.isActorsTutorialRunning) {
            props.setActorTutorialCompleted()
            props.stopActorsTutorial()
            props.showActionsUncoveredPage()
        } else {
            //Segment integartion
            analyticsUtil.track("ACTOR_PROFILE_CLOSE", { actorName: actorDetail.name });

            //Toggle button for initial bamba scale
            if (props.uiState.isInitialBambaScaleOn) {
                props.toggleInitialBambaScale()
            }

            props.setUiState({
                showOverlay: false,
                overlayComponentType: '',
                overlayComponent: ''
            });
        }
    };

    return (
        <div styleName="containerhead" className={css(myStyles.style1)}>
            <ActorGraphs
                {...props}
                actorDetail={actorDetail}
                onClickOfCloseActorDetailPopup={onClickOfCloseActorDetailPopup}
            />
            <ActorDescription {...actorDetail} {...props} />
            {/* {<ActorHistory totalDays={props.introData['initialDuration']} happiness={actorDetail.metricsValues} history={actorDetail.history} />} */}
            <div styleName="close-image"> <img src={IMG_ACTION_RESPONSE_CLOSE_ICON} onClick={onClickOfCloseActorDetailPopup} /> </div>
        </div>
    )
}

export default applyWrappers(ActorDetail, styles);
