import React from 'react';
import styles from './gameMetricsValueComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper'
import ToolTipComponent from 'components/commonComponents/toolTipComponent'

const GameMetricsValueComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);

    return (
        <div styleName='container'>
            <div
                styleName='text'
                className={css(myStyles.text)}
                style={props.isCurrency ? { marginTop: '-3px' } : {}}
            >
                {
                    props.isCurrency ?
                        <div
                            className={css(myStyles.icontext)}
                            style={{ fontSize: '10px' }}
                        >
                            <div styleName="header-space">{props.name}</div>
                        </div>
                        :
                        <div styleName="header-space" className={css(myStyles.icontext)}>{props.name}</div>
                }
                <ToolTipComponent
                    tooltipText={props.toolTip}
                    alignTooltipKey="RIGHT"
                    tooltipStyles={{
                        maxWidth: '220px'
                    }}
                />
            </div>
            <div styleName='graph'>
                {
                    props.isCurrency ?
                        <div className={css(myStyles.value)}>${props.value}
                            {/* / <span style={{ fontSize: '10px' }}>${props.targetRevenue}</span> */}
                        </div>
                        :
                        <div className={css(myStyles.value)}>{props.value}%</div>
                }
            </div>
        </div >
    )
}

export default applyWrappers(GameMetricsValueComponent, styles);
