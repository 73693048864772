import React from 'react';
import styles from './simLogo.module.scss';
import applyWrappers from 'wrappers/ComponentWrapper';
import simLogo from 'images/simlogo.svg'
import demoSimLogo from 'images/logo_Demo.png'

const SimLogo = (props) => {
    let simIcon = props.introData.isDemo ? demoSimLogo : simLogo
    return (
        <div >
            <img src={simIcon} />
        </div>
    )
}

export default applyWrappers(SimLogo, styles);
