import React from 'react';
import styles from './introListComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import BulletIcon from 'images/bullet-icon.svg'
import ObjectivesBg from 'images/objectives-bg.svg'

const IntroListComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const listItems = props.optionList.map((item, key) => {
        return (
            <div styleName="list-item-container" key={key}>
                <img styleName="list-item-bullet" src={BulletIcon} />
                <div className={css(myStyles.listItemText)}>{props.getLabel(item)}</div>
            </div>
        )
    })

    const playingConditionsContainer = () => {
        return <div styleName="container">
            <div styleName="conditions-container">
                <div className={css(myStyles.listTitle)}>
                    <img styleName="list-icon-container" src={props.listIcon}></img>
                    {props.getLabel(props.title)}
                </div>
                <div styleName="list-container">
                    {listItems}
                </div>
            </div>
        </div>
    }

    const objectivesContainer = () => {
        return <div
            styleName="objectives-container"
            style={{ backgroundImage: `url(${ObjectivesBg})` }}
        >
            <div styleName="text-container">
                <div className={css(myStyles.listTitle)}>
                    <img styleName="list-icon-container" src={props.listIcon}></img>
                    {props.getLabel(props.title)}
                </div>
                <div styleName="obj-item-container" key={Math.random()}>
                    <div className={css(myStyles.objectivesTextList)} dangerouslySetInnerHTML={{ __html: props.getLabel('label_storyline_objectives_text', "", props.condition) }} />
                </div>
            </div>
        </div>
    }

    return (
        props.type === 'objectives' ?
            objectivesContainer()
            :
            playingConditionsContainer()

    )
}

export default applyWrappers(IntroListComponent, styles);
