import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
	});
}

export default getSkin;