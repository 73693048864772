import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import LoadingPage from 'components/commonComponents/loadingPage'
import DesktopDashboardContainer from 'containers/DesktopDashboardContainer'
import IntroContainer from 'containers/IntroContainer';
import EndReportContainer from 'containers/EndReportContainer';
import { fetchStorylineData } from "actions/apiAction/fetchStorylineData";
import { fetchLabelsData } from 'actions/apiAction/fetchLabelsData';
import { updateUISize } from "actions/uiState/actionCreators";
import DelayedImpact from 'images/delayedimpact.svg';
import IncremenentIcon from 'images/submetrics-increment-arrow.png';
import DecrementIcon from 'images/submetrics-decrement-arrow.png';
import NoChangeIcon from 'images/submetrics_no_change.png';
import budgetIcon from 'images/actionresponse-budget.svg';
import daysIcon from 'images/actionresponse-days.svg';
import daysLeft from 'images/daysleft.png';
import rightArrow from 'images/rightArrow.png';
import IncremenentIconSvg from 'images/submetrics-increment-arrow.svg';
import DecrementIconSvg from 'images/submetrics-decrement-arrow.svg';
import IMG_ACTION_RESPONSE_CLOSE_ICON from 'images/action_response_close.png';
import footer_image from 'images/footer.png';
import objective_achieved from 'images/objective_achieved.png';
import timer_image from 'images/timer_image.svg';
import simLogo from 'images/simlogo.png'
import history_arrow from 'images/history_arrow.png';
import historyImage from 'images/history_heading.png';
import Close from 'images/action-response-close.png';
import reportImage from 'images/report_heading.png';
import individualActionImage from 'images/individual-action.png';
import IntroFooterBg from 'images/intro-footer-bg.png'
import ObjectivesIcon from 'images/objectives-icon.svg'
import PlayingConditionsIcon from 'images/playing-conditions-item.svg'
import leaderboardImage from 'images/leaderboard_heading.png';
import LeaderboardIcon from 'images/action-response.svg';
import objectivesImage from 'images/objectives_heading.png';
import Leaderboard from 'images/leaderboard.png';
import Objectives from 'images/objectives.png';
import History from 'images/history.png';
import Report from 'images/report.png';
import SidebarArrow from 'images/sidebar-arrow.svg'
import WelcomeBgIllustration from 'images/welcome-rockstar-bg.svg'
import WelcomeIcon from 'images/welcome-illustration.svg'
import BulletIcon from 'images/bullet-icon.svg'
import DashboardBg from 'images/dashboard-bg.svg'
import BudgetIcon from 'images/budget-header-icon.svg'
import DayIcon from 'images/day-icon.svg'
import InfoIcon from 'images/info-icon.svg'
import HappyProgressBarIcon from 'images/happy-progress-bar-icon.svg';
import SadProgressBarIcon from 'images/sad-progress-bar-icon.svg';
import NeutralProgressBarIcon from 'images/neutral-progress-bar-icon.svg';
import positiveIcon from 'images/happiness-icon-positive.svg'
import neutralIcon from 'images/happiness-icon-neutral.svg'
import negativeIcon from 'images/happiness-icon-negative.svg'
import lockedIcon from 'images/actor-info-icon.svg'
import actor_pic from 'images/Actor_Pic.svg'
import actionArrow from 'images/action-arrow.svg'
import IndividualIcon from 'images/individual-icon.svg'
import SelectedIndividualIcon from 'images/individual-icon-selected.svg'
import OrgIcon from 'images/org-icon.svg'
import SelectedOrgIcon from 'images/org-icon-selected.svg'
import CalendarIcon from 'images/calendar-icon.svg'
import SelectedCalendarIcon from 'images/calendar-icon-selected.svg'
import BudgetIconSvg from 'images/budget-icon.svg'
import SelectedBudgetIcon from 'images/budget-icon-selected.svg'
import ActionsBgImage from 'images/actions-bg.svg'
import logoutIcon from 'images/logout-icon.svg';
import dottedArrowShort from 'images/dotted-arrow-short.svg'
import dottedArrowLarge from 'images/dotted-arrow-large.svg'
import BambaIllustration from 'images/bamba-uncovered-illustration.svg'
import PopupImage from 'images/tutorial-completed-popup.svg'
import UpArrow from 'images/transcript-arrow.svg'
import individualIcon from 'images/actionresponse-individual.svg'
import orgIcon from 'images/actionresponse-org.svg'

class AppRouter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDataFetched: false,
            currentRoute: ''
        }
    }

    componentDidMount() {
        this.props.fetchLabelsData();
        this.props.fetchStorylineData();
        window.onresize = () => {
            this.props.updateUISize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.uiState.isDataFetched && this.props.uiState.isDataFetched) {
            // If data is fetched,set flag
            this.setState({ isDataFetched: this.props.uiState.isDataFetched });
        }
    }

    render() {
        let { isDataFetched } = this.state
        return (
            <Fragment>
                {
                    isDataFetched ?
                        <Router>
                            <Switch>
                                <Route path='/intro' component={IntroContainer} />
                                <Route path='/dashboard' component={DesktopDashboardContainer} />
                                <Route path='/end-report' component={EndReportContainer} />
                                <Route path='/' component={IntroContainer} />
                            </Switch>
                        </Router>
                        :
                        <LoadingPage />
                }
                <div style={{ display: 'none' }}>
                    {/* Preloading images */}
                    <img alt='DelayedImpact' src={DelayedImpact} />
                    <img alt='IncremenentIcon' src={IncremenentIcon} />
                    <img alt='DecrementIcon' src={DecrementIcon} />
                    <img alt='NoChangeIcon' src={NoChangeIcon} />
                    <img alt='budgetIcon' src={budgetIcon} />
                    <img alt='daysIcon' src={daysIcon} />
                    <img alt='daysLeft' src={daysLeft} />
                    <img alt='rightArrow' src={rightArrow} />
                    <img alt='IncremenentIconSvg' src={IncremenentIconSvg} />
                    <img alt='DecrementIconSvg' src={DecrementIconSvg} />
                    <img alt='IMG_ACTION_RESPONSE_CLOSE_ICON' src={IMG_ACTION_RESPONSE_CLOSE_ICON} />
                    <img alt='footer_image' src={footer_image} />
                    <img alt='objective_achieved' src={objective_achieved} />
                    <img alt='timer_image' src={timer_image} />
                    <img alt='simLogo' src={simLogo} />
                    <img alt='history_arrow' src={history_arrow} />
                    <img alt='historyImage' src={historyImage} />
                    <img alt='Close' src={Close} />
                    <img alt='reportImage' src={reportImage} />
                    <img alt='individualActionImage' src={individualActionImage} />
                    <img alt='IntroFooterBg' src={IntroFooterBg} />
                    <img alt='ObjectivesIcon' src={ObjectivesIcon} />
                    <img alt='PlayingConditionsIcon' src={PlayingConditionsIcon} />
                    <img alt='leaderboardImage' src={leaderboardImage} />
                    <img alt='LeaderboardIcon' src={LeaderboardIcon} />
                    <img alt='objectivesImage' src={objectivesImage} />
                    <img alt='Leaderboard' src={Leaderboard} />
                    <img alt='Objectives' src={Objectives} />
                    <img alt='History' src={History} />
                    <img alt='Report' src={Report} />
                    <img alt='SidebarArrow' src={SidebarArrow} />
                    <img alt='WelcomeBgIllustration' src={WelcomeBgIllustration} />
                    <img alt='WelcomeIcon' src={WelcomeIcon} />
                    <img alt='BulletIcon' src={BulletIcon} />
                    <img alt='DashboardBg' src={DashboardBg} />
                    <img alt='DayIcon' src={DayIcon} />
                    <img alt='BudgetIcon' src={BudgetIcon} />
                    <img alt='InfoIcon' src={InfoIcon} />
                    <img alt='HappyProgressBarIcon' src={HappyProgressBarIcon} />
                    <img alt='SadProgressBarIcon' src={SadProgressBarIcon} />
                    <img alt='NeutralProgressBarIcon' src={NeutralProgressBarIcon} />
                    <img alt='positiveIcon' src={positiveIcon} />
                    <img alt='neutralIcon' src={neutralIcon} />
                    <img alt='negativeIcon' src={negativeIcon} />
                    <img alt='lockedIcon' src={lockedIcon} />
                    <img alt='actor_pic' src={actor_pic} />
                    <img alt='actionArrow' src={actionArrow} />
                    <img alt='IndividualIcon' src={IndividualIcon} />
                    <img alt='SelectedIndividualIcon' src={SelectedIndividualIcon} />
                    <img alt='OrgIcon' src={OrgIcon} />
                    <img alt='SelectedOrgIcon' src={SelectedOrgIcon} />
                    <img alt='CalendarIcon' src={CalendarIcon} />
                    <img alt='SelectedCalendarIcon' src={SelectedCalendarIcon} />
                    <img alt='BudgetIconSvg' src={BudgetIconSvg} />
                    <img alt='SelectedBudgetIcon' src={SelectedBudgetIcon} />
                    <img alt='ActionsBgImage' src={ActionsBgImage} />
                    <img alt='logoutIcon' src={logoutIcon} />
                    <img alt='dottedArrowShort' src={dottedArrowShort} />
                    <img alt='dottedArrowLarge' src={dottedArrowLarge} />
                    <img alt='BambaIllustration' src={BambaIllustration} />
                    <img alt='PopupImage' src={PopupImage} />
                    <img alt='UpArrow' src={UpArrow} />
                    <img alt='individualIcon' src={individualIcon} />
                    <img alt='orgIcon' src={orgIcon} />
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => ({
    fetchStorylineData: () => {
        dispatch(fetchStorylineData());
    },
    fetchLabelsData: () => {
        dispatch(fetchLabelsData());
    },
    updateUISize: payload => {
        dispatch(updateUISize(payload));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);