import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container:{
            background: skin.white,
            borderRadius: '19px',
            border: `1px solid ${skin.primaryColor}`
        },
        headerContainer: {
            background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`
        },
        title: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            color: skin.white,
            opacity: 0.8
        },
        info:{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '24px',
            color: '#031A20'
        },
        buttonText: {
            textTransform: 'uppercase',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '12px',
            border: `1.5px solid ${skin.buttonColor1}`,
            //background: `linear-gradient(102.64deg, ${skin.buttonColor1} 4.65%, ${skin.buttonColor2} 97.99%)`
        },
        btnText:{
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '19px',
            textTransform: 'uppercase',
            color: skin.white,
            background: 'linear-gradient(132.72deg, #00D7FF 4.65%, #7DEEB9 97.99%)',
            borderRadius: '25px'
        }
    });
}

export default getSkin;
