import React from 'react';
import styles from './pieChart.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { ResponsivePie } from '@nivo/pie'

const PieChart = (props) => {
  const { pieData , colors } = props;
	const myStyles = getSkin(props.skinGuide);

    return (
        <div styleName="container">
          <ResponsivePie
              data={pieData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              fit={true}
              colors={colors}
              borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
              enableRadialLabels={false}
              radialLabelsSkipAngle={0}
              radialLabelsTextXOffset={6}
              radialLabelsTextColor="#333333"
              radialLabelsLinkOffset={0}
              radialLabelsLinkDiagonalLength={16}
              radialLabelsLinkHorizontalLength={24}
              radialLabelsLinkStrokeWidth={1}
              radialLabelsLinkColor={{ from: 'color' }}
              enableSlicesLabels={false}
              slicesLabelsSkipAngle={0}
              slicesLabelsTextColor="#333333"
              animate={true}
              motionStiffness={90}
              motionDamping={15}
          />
    </div>
	);
}

export default applyWrappers(PieChart, styles);