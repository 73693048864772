import actionConsts from 'constants/actions';

const initialState = {
    eventActionId: -1,
    daysRemaining: -1,
    budgetRemaining: -1,
    dayTo: -1,
    dayFrom: -1,
    eventActionInfo: {},
    categoryId: -1,
    subDomainId: -1
}

const userEventResponse = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.EVENT_RESPONSE:
            return action.payload;
        case actionConsts.RESET_EVENT_RESPONSE:
            return initialState
        default:
            return state;
    }
};

export default userEventResponse;
