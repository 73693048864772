import React from 'react';
import { connect } from 'react-redux';
import EventComponent from 'components/event/eventPopup';
import { setUiState } from 'actions/uiState/actionCreators';
import { infoEventCompleted } from 'actions/apiAction/eventApis'

class EventContainer extends React.Component {

    render() {
        return (
            <EventComponent {...this.props} />
        );
    }
}

EventContainer.propTypes = {};

EventContainer.defaultProps = {};

const mapStateToProps = state => {
    return {
        uiState: state.uiState,
        userEvent: state.userEvent
    };
};

const mapDispatchToProps = dispatch => ({
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    infoEventCompleted: (payload) => {
        dispatch(infoEventCompleted(payload))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventContainer);
