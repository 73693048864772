import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        headerContainer: {
            color: skin.white,
            backgroundImage: `linear-gradient(171.97deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`
        },
        vrLine: {
            backgroundColor: skin.white
        }
    });
}

export default getSkin;
