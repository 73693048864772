import React from 'react';
import styles from './actorHistory.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { ResponsiveLine } from '@nivo/line';
import IMG_INCREMENT_ARROW from 'images/increment-arrow.svg';
import IMG_DECREMENT_ARROW from 'images/decrement-arrow.svg';
const ActorHistory = (props) => {
    const myStyles = getSkin(props.skinGuide);
    const { happiness, history, totalDays } = props;
    const days = totalDays ? totalDays : 100;
    let metricsData = [];
    happiness.forEach(ele => {
        if (ele.days != null) {
            let obj = {};
            obj.x = ele.days;
            obj.y = ele.happiness;
            metricsData.push(obj);
        }
    });
    const data = [
        {
            "id": "Happiness",
            "color": "hsl(178, 70%, 50%)",
            "data": metricsData
        }
    ];
    const getLineGraph = (data, totalDays) => {
        return (
            <ResponsiveLine
                data={data}
                height={150}
                margin={{ top: 10, right: 20, bottom: 45, left: 50 }}
                xScale={{ type: 'linear', min: 0, max: totalDays }}
                yScale={{ type: 'linear', min: 0, max: 100, stacked: false }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: props.getLabel('label_metric_duration'),
                    legendOffset: 30,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: props.getLabel('label_tooltip_happiness_title'),
                    legendOffset: -40,
                    legendPosition: 'middle'
                }}
                colors="#F79F58"
                pointSize={0}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh={true}
                isInteractive={true}
            />
        )
    };
    let strings = [];
    const impacts = [
        -5, 9, 12, -4, 0
    ];
    const getImpactCircles = (impacts) => {
        let returnObj = [];
        let count = 0;
        returnObj = impacts.map((singleImpact, key) => {
            count++;
            let style = {};
            if (singleImpact > 0) {
                style["backgroundColor"] = "#7BEAD1";
            } else if (singleImpact < 0) {
                style["backgroundColor"] = "#FF9D9D";
            } else {
                style["backgroundColor"] = "#D3D3D3";
            }
            if (count == 5) {
                return (
                    <div styleName="big-impact-circle" style={style} key={key}> </div>
                );
            }
            return (
                <div styleName="impact-circle" style={style} key={key}> </div>
            );
        });
        return returnObj;
    };
    const getActionImpactComponent = () => {
        let actions = [];
        history.forEach((ele) => {
            let obj = {};
            obj.actionName = props.getLabel(ele.action.name);
            obj.completedDay = ele.completedDay;
            if (ele.actorActions && ele.actorActions[0]) {
                obj.happinessValue = ele.actorActions[0].happiness;
                obj.happinessImpact = ele.actorActions[0].impact;
            }
            actions.push(obj);
        });
        let returnObj = actions.map((singleAction, key) => {
            let imageName = null;
            if (singleAction.happinessImpact > 0) {
                imageName = IMG_INCREMENT_ARROW;
            } else {
                imageName = IMG_DECREMENT_ARROW;
            }
            return (
                <div styleName="single-action-impact-container" key={key}>
                    <div styleName="single-action-container"> {singleAction.actionName} </div>
                    <div styleName="single-impact-container">
                        <div styleName="happiness-value"> {singleAction.happinessValue} %</div>
                        <img src={imageName} styleName="change-image" />
                        <div styleName="happiness-impact"> by  {singleAction.happinessImpact} %</div>
                        <div styleName="day"> {props.getLabel('label_metric_duration')}  {singleAction.completedDay}</div>
                    </div>
                </div>
            );
        });
        return returnObj;
    };
    const getActionHeaderComponent = () => {
        let returnObj = [];
        return (
            <div styleName="header-container">
                <div styleName="action-header"> Actions </div>
                <div styleName="impact-header"> {props.getLabel('label_tooltip_happiness_title')} </div>
                <div styleName="day-header">  {props.getLabel('label_metric_duration')} </div>
            </div>
        );
        return returnObj;
    }
    strings["actor_history_header"] = "What are we looking at?";
    strings["actor_history_graph_desc"] = "Description of the graph above : John has been with HealWear Technologies for 4 years. Ever since he joined, John has a been a part of the DextroSense software team.";
    return (
        <div styleName="containerhead" className={css(myStyles.styleHeader)}>
            <div styleName="container">
                <div styleName="actorhistoryheader"> {strings["actor_history_header"]} </div>
                <div styleName="actorHistoryGraphContainer">
                    <div styleName="actorHistoryGraph">
                        {getLineGraph(data, days)}
                    </div>
                    <div styleName="actorHistoryGraphDesc" className={css(myStyles.styleGraphDesc)}>
                        {strings['actor_history_graph_desc']}
                    </div>
                </div>
                <div styleName="endLine"> </div>
                <div styleName="actorHistoryContainer">
                    <div> {props.getLabel('label_history_title')} </div>
                    <div styleName="impact-visualization">
                        <span styleName="impacts-desc" className={css(myStyles.styleGraphDesc)}> Last 5 impacts </span>
                        <div styleName="impact-circle-container"> {getImpactCircles(impacts)} </div>
                    </div>
                    <div styleName="history-component">
                        {getActionHeaderComponent()}
                        <div styleName="action-impacts-container">
                            {getActionImpactComponent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default applyWrappers(ActorHistory, styles);