import React from 'react';
import styles from './loadingPage.module.scss';
import LoaderGif from 'images/loading_big.gif'
import applyWrappers from 'wrappers/ComponentWrapper';

const LoadingPage = () => {
    return (
        <div styleName='container'>
            <div styleName="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default applyWrappers(LoadingPage, styles);
