import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        header: {
            ...myTypography.body2,
            background: `linear-gradient(170.61deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`,
            color: skin.white,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            borderRadius: '4px'
        },
        domain: {
            ...myTypography.body2,
            fontSize: '11px',
            backgroundColor: skin.pinkTableBg,
            color: skin.black,
            opacity: 0.7,
            textTransform: 'uppercase'
        },
        subdomain: {
            ...myTypography.body2,
            fontSize: '10px',
            backgroundColor: skin.pinkTableBg,
            color: skin.black,
            opacity: 0.7
        },
        greenTag: {
            borderRadius: '8px',
            background: `#7CEBD1`,
            fontSize: '9px',
        },
        redTag: {
            borderRadius: '8px',
            background: `#FF9292`,
            fontSize: '9px',
        },
        subdomainValue:{
            fontSize: '12px'
        }
    });
}

export default getSkin;
