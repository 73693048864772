import actionConsts from 'constants/actions';

const initialState = {
    isDataFetched: false,
    width: window.innerWidth,
    height: window.innerHeight,
    showOverlay: false,
    overlayComponentType: 'CENTER', //takes 'MIDDLE' or 'BOTTOM' or 'CENTER
    highlightDesktopHeader: false,
    highlightMetricsHeader: false,
    overlayComponent: '',
    delayedActionShowIndex: -1,
    highlightedSidebarComponent: -1,
    isInitialBambaScaleOn: false,
    openReportIframe: false,
    isReportDownloading: false,

    //email
    isMailBeingSent: false,
    showMailStatusMessage: false,
    mailSentSuccessfully: false,

    isTargetAchieved: false,
    gameOver: {},
    actionResponseLoader: false,

    showMonthlyReportFlag: false,
    isBudgetFinished : false,
    currentReportMonth: ''
};


const uiState = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_GAME_DATA:
            console.log(action)
            return {
                ...state,
                isDataFetched: true,
                showMonthlyReportFlag: action.payload.userInfo.monthlyReport ? action.payload.userInfo.monthlyReport.showMonthlyReport : false,
                currentReportMonth: action.payload.userInfo.monthlyReport ? action.payload.userInfo.monthlyReport.showMonth : false,
                gameOver: action.payload.userInfo.gameOver,
                isTargetAchieved: action.payload.userInfo.isTargetAchieved,
                isBudgetFinished: action.payload.userInfo.isBudgetFinished
            }
        case actionConsts.SET_UI_STATE:
            return {
                ...state,
                ...action.payload
            };
        case actionConsts.CHANGE_WIDTH_AND_HEIGHT:
            return {
                ...state,
                width: window.innerWidth,
                height: window.innerHeight
            };
        case actionConsts.ACTION_RESPONSE:
            return {
                ...state,
                delayedActionShowIndex: -1
            }
        case actionConsts.SET_HIGHLIGHTED_SIDEBAR_COMPONENT:
            return {
                ...state,
                highlightedSidebarComponent: action.payload
            }
        case actionConsts.TOGGLE_INITIAL_BAMBA_SCALE:
            return {
                ...state,
                isInitialBambaScaleOn: !state.isInitialBambaScaleOn
            }
        case actionConsts.START_ACTION_LOADER:
            return {
                ...state,
                actionResponseLoader: true
            }
        case actionConsts.STOP_ACTION_LOADER:
            return {
                ...state,
                actionResponseLoader: false
            }
        case actionConsts.SET_MONTHLY_REPORT_FLAG:
            return {
                ...state,
                showMonthlyReportFlag: true,
                currentReportMonth: action.payload.monthlyReport.showMonth
            }
        case actionConsts.RESET_MONTHLY_REPORT_FLAG:
            return {
                ...state,
                showMonthlyReportFlag: false,
                currentReportMonth: ''
            }
        case actionConsts.SET_USER_BUDGET_FINISHED:
            return {
                ...state,
                isBudgetFinished: true 
            }    
        default:
            return state;
    }
};

export default uiState;