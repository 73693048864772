import React from 'react';
import styles from './header.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Header = (props) => {
    const myStyles = getSkin(props.skinGuide);

    return (
        <div className={css(myStyles.title)}>
             {props.title}
        </div>
    )
}

export default applyWrappers(Header, styles);
