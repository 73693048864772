import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        actorDesc: {
            ...myTypography.body1,
            fontSize: '12px',
            textTransform: 'none',
            color: skin.black,
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '21px'
        },
        actorGraphDesc: {
            ...myTypography.body1,
            fontSize: '12px',
            textTransform: 'none',
            color: skin.black,
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '21px'
        },
        aboutActor: {
            ...myTypography.body1,
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '19px',
            color: skin.black
        },
        hrLine: {
            backgroundColor: skin.secondaryColor,
            opacity: 0.4
        },
        tableHeader: {
            ...myTypography.body1,
            fontWeight: 'bold',
            backgroundColor: skin.progressBarBackground,
            borderBottomLeftRadius: '25px',
            borderBottomRightRadius: '25px',
        },
        bambaValues: {
            ...myTypography.body1,
        },
        infoText: {
            ...myTypography.body1,
            // textTransform: 'uppercase',
            color: skin.black,
            opacity: 0.5
        },
        readMoreText: {
            ...myTypography.subtitle,
            fontWeight: '600',
            fontSize: '12px',
            textDecorationLine: 'underline',
            textTransform: 'capitalize',
            color: 'turquoise',
            marginTop: '4px',
            cursor: 'pointer'
        },
        tableDesc: {
            fontSize: '12px',
            lineHeight: '173.4%',
            opacity: '0.8',
        }
    });
}

export default getSkin;
