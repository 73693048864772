import React from 'react';
import styles from './eventPopup.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Header from 'components/commonComponents/popup/header';
import Description from 'components/commonComponents/popup/description';
import ActionOrEventInfo from 'components/commonComponents/popup/actionOrEventInfo';
import ResponseMetrics from 'components/action/actionResponseMetrics';
import LoadingPage from 'components/commonComponents/loadingPage'
import FlatButton from 'components/commonComponents/flatButton'
import analyticsUtil from 'utils/segmentUtil';

const EventPopup = (props) => {
    let { userEvent } = props
    let { isEventLoading } = userEvent

    const eventData = {
        title: props.getLabel(userEvent.name),
        desc: props.getLabel(userEvent.desc),
    }

    analyticsUtil.track("INFO_EVENT", {
        ...userEvent,
        metrics: eventData.metrics
    });


    const closePopup = () => {
        analyticsUtil.track("INFO_EVENT_CLOSE", {
            ...eventData
        });

        props.infoEventCompleted({
            "eventId": props.userEvent.id
        })
    }

    const myStyles = getSkin(props.skinGuide);

    return (
        <div className={css(myStyles.container)} styleName="container">
            <div className={css(myStyles.seperator)} styleName="headseperator"></div>
            {
                isEventLoading ?
                    <LoadingPage />
                    :
                    <div className={css(myStyles.responseContainer)} styleName="response-container">
                        <span>
                            <div styleName="title">
                                <Header title={eventData.title} />
                            </div>
                            <div styleName="eventInfo">
                                <ActionOrEventInfo isActionIndividual={eventData.isActionIndividual}
                                    days={userEvent.duration} budget={eventData.budget} />
                            </div>
                            <div styleName="response-message">
                                <Description desc={eventData.desc} fontSizeSmaller={true} />
                            </div>
                            <div styleName="okButton" onClick={closePopup}>
                                <FlatButton textLabel={props.getLabel("label_ok")} />
                            </div>
                        </span>
                    </div>
            }
        </div>
    )
}

export default applyWrappers(EventPopup, styles);
