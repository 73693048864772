/**
 * @author: suyog.patil@knolskape.com
 * @since: Wed Jul 05 2017 08:32:24 GMT+0530 (IST)
 * @file: environment.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Exports styling related constants
 *
 **/

const actions = {
    //uiState
    SET_UI_STATE: 'SET_UI_STATE',
    ERROR: "ERROR",
    EMPTY_ERROR: "EMPTY_ERROR",
    CHANGE_WIDTH_AND_HEIGHT: "CHANGE_WIDTH_AND_HEIGHT",
    SET_HIGHLIGHTED_SIDEBAR_COMPONENT: "SET_HIGHLIGHTED_SIDEBAR_COMPONENT",
    TOGGLE_INITIAL_BAMBA_SCALE: "TOGGLE_INITIAL_BAMBA_SCALE",
    SET_USER_BUDGET_FINISHED: 'SET_USER_BUDGET_FINISHED',


    //Initialize data
    INIT_GAME_DATA: "INIT_GAME_DATA",
    INIT_LABELS_DATA: 'INIT_LABELS_DATA',

    //Tutorial(walkthrough) actions
    START_TUTORIAL: "START_TUTORIAL",
    ADD_TUTORIAL_STEPS: "ADD_TUTORIAL_STEPS",
    UPDATE_TUTORIAL_STEP: "UPDATE_TUTORIAL_STEP",
    END_TUTORIAL: "END_TUTORIAL",
    PAUSE_TUTORIAL: "PAUSE_TUTORIAL",
    SET_END_TUTORIAL_POPUP_FLAG: "SET_END_TUTORIAL_POPUP_FLAG",
    // Actor Tutorial
    START_ACTORS_TUTORIAL: "START_ACTORS_TUTORIAL",
    STOP_ACTORS_TUTORIAL: "STOP_ACTORS_TUTORIAL",
    // Actions Tutorial
    SHOW_ACTIONS_UNCOVERED_PAGE: "SHOW_ACTIONS_UNCOVERED_PAGE",
    HIDE_ACTIONS_UNCOVERED_PAGE: "HIDE_ACTIONS_UNCOVERED_PAGE",
    START_ACTIONS_TUTORIAL: "START_ACTIONS_TUTORIAL",
    STOP_ACTIONS_TUTORIAL: "STOP_ACTIONS_TUTORIAL",
    SET_ACTOR_VISITED_FLAG: "SET_ACTOR_VISITED_FLAG",

    // Action sequence functions
    SAVE_SELECTED_CATEGORY_ID: "SAVE_SELECTED_CATEGORY_ID",
    SAVE_SELECTED_SUBDOMAIN_ID: "SAVE_SELECTED_SUBDOMAIN_ID",
    SAVE_SELECTED_ACTION_ID: "SAVE_SELECTED_ACTION_ID",
    SAVE_SELECTED_ACTOR_ID: "SAVE_SELECTED_ACTOR_ID",
    SET_ACTION_STEP_NUMBER: "SET_ACTION_STEP_NUMBER",
    RESET_ACTION_SEQUENCE: "RESET_ACTION_SEQUENCE",

    //save actor id to show actor
    SAVE_SHOW_ACTOR_ID: 'SAVE_SHOW_ACTOR_ID',

    // Save actor graph details 
    SET_ACTOR_GRAPH_DETAILS: 'SET_ACTOR_GRAPH_DETAILS',

    ACTION_RESPONSE: 'ACTION_RESPONSE',
    UPDATE_HEADER_METRICS: 'UPDATE_HEADER_METRICS',
    START_ACTION_LOADER: 'START_ACTION_LOADER',
    STOP_ACTION_LOADER: 'STOP_ACTION_LOADER',

    // Event actions
    EVENT_RESPONSE: 'EVENT_RESPONSE',
    UPDATE_EVENT_METRICS: 'UPDATE_EVENT_METRICS',
    START_EVENT_LOADER: 'START_EVENT_LOADER',
    STOP_EVENT_LOADER: 'STOP_EVENT_LOADER',
    RESET_EVENT_RESPONSE: 'RESET_EVENT_RESPONSE',
    SET_NEXT_EVENT: 'SET_NEXT_EVENT',

    //Leaderboard data
    LEADERBOARD_DATA: 'LEADERBOARD_DATA',
    UPDATE_LEADERBOARD_STATUS: 'UPDATE_LEADERBOARD_STATUS',

    //Intermediate report data
    INTERMEDIATE_REPORT_DATA: 'INTERMEDIATE_REPORT_DATA',
    SET_MONTHLY_REPORT_FLAG: 'SET_MONTHLY_REPORT_FLAG',
    RESET_MONTHLY_REPORT_FLAG: 'RESET_MONTHLY_REPORT_FLAG',

    //History data
    HISTORY_DATA: 'HISTORY_DATA',

    // User-related actions
    SET_USER_GAME_STARTED: 'SET_USER_GAME_STARTED',
    SET_USER_ACTOR_TUTORIAL_COMPLETED: 'SET_USER_ACTOR_TUTORIAL_COMPLETED',
    SET_USER_ACTION_TUTORIAL_COMPLETED: 'SET_USER_ACTION_TUTORIAL_COMPLETED',
    RESET_USER_ACTOR_TUTORIAL_COMPLETED: 'RESET_USER_ACTOR_TUTORIAL_COMPLETED',
    SET_USER_GAME_COMPLETED: 'SET_USER_GAME_COMPLETED',
    SET_START_TIMER_FLAG: 'SET_START_TIMER_FLAG',

    // Timer actions
    UPDATE_TIME_LEFT: 'UPDATE_TIME_LEFT'
};

export default actions;
