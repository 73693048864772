import React from 'react';
import styles from './costMetricsComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ToolTipComponent from 'components/commonComponents/toolTipComponent'
import metricsDecreaseIcon from 'images/metrics_decrease.svg'
import metricsIncreaseIcon from 'images/metrics_increase.svg'
import NoChangeIcon from 'images/metrics_no_change.svg'
import dayMovement from 'images/day-movement.svg'

const CostMetricsComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);

    let isScoreChanged = ['ACTION_REPONSE_POPUP', 'EVENT_POPUP', 'ACTION_EVENT_POPUP'].includes(props.uiState.overlayComponent)
    let metricsIcon = '', textColor;
    if (props.offset < 0) {
        metricsIcon = metricsDecreaseIcon
        textColor = '#FF4B4B'
    } else if (props.offset > 0) {
        metricsIcon = metricsIncreaseIcon
        textColor = '#0DD8A8'
    } else {
        metricsIcon = NoChangeIcon
    }

    return (
        <div styleName='container'>
            <img styleName="image-size" src={props.icon} />
            <div styleName='text'>
                <div styleName='header' className={css(myStyles.header)}>
                    <div styleName='header-space'>
                        {props.header}
                    </div>
                    <ToolTipComponent
                        tooltipText={props.toolTip}
                        alignTooltipKey="RIGHT"
                        tooltipStyles={{
                            maxWidth: '220px'
                        }}
                    />
                </div>
                <div styleName='value-width' className={css(myStyles.value)}>{props.value}</div>
            </div>
            {
                isScoreChanged && props.header === 'Days' &&
                <div styleName='value-changes'>
                    <div className={css(myStyles.initialDay)}>{props.currentDay + props.offset}</div>
                    <img styleName='day-icon' src={dayMovement} />
                    <div className={css(myStyles.finalDay)}>{props.currentDay}</div>
                </div>
            }
            {
                isScoreChanged && props.header === 'Budget' &&
                <div styleName='value-changes'>
                    <img src={metricsIcon} />
                    <div className={css(myStyles.budgetChange)} style={{ color: textColor, marginLeft: '3px' }}>${Math.abs(props.offset)}</div>
                </div >
            }
        </div>
    )
}

export default applyWrappers(CostMetricsComponent, styles);
