import React from 'react';
import styles from './overlay.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ActionResponseContainer from 'containers/ActionResponseContainer';
import ActorDetailContainer from 'containers/ActorDetailContainer';
import HistoryListContainer from 'containers/HistoryListContainer';
import LeaderboardContainer from 'containers/LeaderboardContainer';
import EventContainer from 'containers/EventContainer';
import ActionEventContainer from 'containers/ActionEventContainer';
import IntermediateReportContainer from 'containers/IntermediateReportContainer';
import VideoAndObjectivesContainer from 'containers/VideoAndObjectivesContainer';
import EndScreenPopupContainer from 'containers/EndScreenPopupContainer';
import TutorialCompletedPopupContainer from 'containers/TutorialCompletedPopupContainer';
import LogoutConfirmationContainer from 'containers/LogoutConfirmationContainer';
import analyticsUtil from 'utils/segmentUtil';

const Overlay = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const closePopup = (e) => {
        e.stopPropagation();
    }

    const renderOverlayComponent = () => {
        if(props.uiState.gameOver["simEnd"] && 
               ['ACTION_REPONSE_POPUP','ACTION_EVENT_POPUP','ACTION_EVENT_POPUP'].includes(props.uiState.overlayComponent)){
                   
            return props.uiState.gameOver["simEndCause"] == 'timeover' ? 
                     <EndScreenPopupContainer type='timer' /> : 
                     <EndScreenPopupContainer type='normal' />
        }
        switch (props.uiState.overlayComponent) {
            case 'ACTOR_DETAIL_COMPONENT':
                return <ActorDetailContainer />
            case 'ACTION_REPONSE_POPUP':
                return <ActionResponseContainer />
            case 'HISTORY_POPUP':
                analyticsUtil.track("SIDEBAR_POPUP_OPEN", { popup: "HISTORY_POPUP" });
                return <HistoryListContainer />
            case 'LEADERBOARD_POPUP':
                analyticsUtil.track("SIDEBAR_POPUP_OPEN", { popup: "LEADERBOARD_POPUP" });
                return <LeaderboardContainer />
            case 'EVENT_POPUP':
                return <EventContainer />
            case 'ACTION_EVENT_POPUP':
                return <ActionEventContainer />
            case 'INTERMEDIATE_REPORT_POPUP':
                analyticsUtil.track("SIDEBAR_POPUP_OPEN", { popup: "MONTHLY_REPORT_POPUP" });
                return <IntermediateReportContainer />
            case 'VIDEO_AND_OBJECTIVES_POPUP':
                analyticsUtil.track("SIDEBAR_POPUP_OPEN", { popup: "OBJECTIVES_POPUP" });
                return <VideoAndObjectivesContainer />
            case 'ENDSCREEN_POPUP':
                return <EndScreenPopupContainer type='normal' />
            case 'TIMEOVER_POPUP':
                return <EndScreenPopupContainer type='timer' />
            case 'TUTORIAL_COMPLETED_POPUP':
                return <TutorialCompletedPopupContainer />
            case 'LOGOUT_CONFIRMATION_POPUP':
                return <LogoutConfirmationContainer />
            default:
                return (
                    <div>Render your component here</div>
                );
        }
    }

    const renderComponent = () => {
        if(props.uiState.gameOver["simEnd"]){
            props.uiState.overlayComponentType = 'CENTER' 
        }
        switch (props.uiState.overlayComponentType) {
            case 'BOTTOM':
                return (
                    <div styleName="complete-container bottom-container">
                        <div styleName="bottom-container-component" className={css(myStyles.bottomContainerComponent)}>
                            {renderOverlayComponent()}
                        </div>
                        <div styleName='background-container' className={props.uiState.overlayComponent != "TUTORIAL_COMPLETED_POPUP" && css(myStyles.backgroundContainer)}></div>
                    </div>
                );
            case 'RIGHT':
                return (
                    <div styleName="complete-container right-container">
                        <div styleName="right-container-component" className={css(myStyles.rightContainerComponent)}>
                            {renderOverlayComponent()}
                        </div>
                        <div styleName='background-container' className={props.uiState.overlayComponent != "TUTORIAL_COMPLETED_POPUP" && css(myStyles.backgroundContainer)}></div>
                    </div>
                );
            case 'CENTER':
                return (
                    <div
                        styleName="complete-container center-container"
                        className={props.uiState.overlayComponent != "TUTORIAL_COMPLETED_POPUP" && css(myStyles.completeContainer)}
                        onClick={(e) => { closePopup(e); }}
                    >
                        {renderOverlayComponent()}
                        <div styleName='background-container' className={css(myStyles.backgroundContainer)}></div>
                    </div>
                );
            default:
                return null;
        }
    }

    return renderComponent();
}

export default applyWrappers(Overlay, styles);
