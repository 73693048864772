import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        path: {
            fontFamily: "Nunito",
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '14px',
            color: skin.black,
            opacity: 0.5,
            marginBottom: '15px'
        },
        actioninfo: {
            marginTop: '6px',
            marginBottom: '16px'

        },
        delayedImpact: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '16px',
            color: skin.white,
        },
    });
}

export default getSkin;
