import React from 'react';
import styles from './actionOrEventInfo.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

import budgetIcon from 'images/actionresponse-budget.svg'
import daysIcon from 'images/actionresponse-days.svg'
import individualIcon from 'images/actionresponse-individual.svg'
import orgIcon from 'images/actionresponse-org.svg'

const ActionOrEventInfo = (props) => {
    let isActionIndividual = props.isActionIndividual;

    const renderImage = (changeType) => {
        switch (changeType) {
            case 'org':
                return (
                    <div styleName="action-info-icon">
                        <img src={orgIcon} />
                    </div>
                );
            case 'individual':
                return (
                    <div styleName="action-info-icon">
                        <img src={individualIcon} />
                    </div>
                );
            case 'budget':
                return (
                    <div styleName="action-info-icon">
                        <img src={budgetIcon} />
                    </div>
                );
            case 'days':
                return (
                    <div styleName="action-info-icon">
                        <img src={daysIcon} />
                    </div>
                );
            default:
                return null;
        }
    }

    const renderActionInformation = (title, type) => {
        return (
            <div styleName="game-metrics-container" key={title}>
                {renderImage(type)}
                <div className={css(myStyles.actionInformation)} styleName="action-info">{title ? title : 0}</div>
            </div>
        );
    }

    const renderActionInfo = () => {
        let icon = isActionIndividual ? 'individual' : 'org';
        let orgOrIndividualLabel = isActionIndividual ? props.getLabel("label_action_type_individual") : props.getLabel("label_action_type_orgwide");
        let daysValue = props.days <= 1 ? props.days + " " + props.getLabel("label_history_day") : props.days + " " + props.getLabel("label_tooltip_days_title")
        return (
            <div styleName="game-metrics-container" key={isActionIndividual}>
                {renderActionInformation(
                    orgOrIndividualLabel,
                    icon
                )}
                {renderActionInformation(
                    daysValue,
                    "days"
                )}
                {renderActionInformation(
                    props.budget,
                    "budget"
                )}
            </div>
        );
    }

    const myStyles = getSkin(props.skinGuide);

    return (
        <div>
            {renderActionInfo()}
        </div>
    )
}

export default applyWrappers(ActionOrEventInfo, styles);
