import React from 'react';
import styles from './actionEventResponse.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ActionFeedback from 'components/commonComponents/popup/actionFeedback';
import FlatButton from 'components/commonComponents/flatButton';
import analyticsUtil from 'utils/segmentUtil';

const ActionEventResponse = (props) => {
    let actionResponseObj = {};

    actionResponseObj = props.userEventResponse;
    //create here the action data
    let actionData = {
        title: actionResponseObj.eventActionInfo.name,
        actionMessage: actionResponseObj.eventActionInfo.actionCompletedMessage,
        category: actionResponseObj.categoryName,
        domain: actionResponseObj.subdomainName,
        isActionIndividual: false,
        days: actionResponseObj.eventActionInfo.duration,
        budget: actionResponseObj.eventActionInfo.cost,
        metrics: actionResponseObj.metrics
    };

    analyticsUtil.track("ACTION_RESPONSE_EVENT", {
        ...actionData,
        title: props.getLabel(actionData.title),
        actionMessage: props.getLabel(actionData.actionMessage)
    });

    actionData = {
        ...actionData,
        ...actionResponseObj
    };
    let actorInfo = {};
    if (actionResponseObj['actorId']) {
        actorInfo = {
            name: props.actors.actorList[actionResponseObj['actorId']]['name'],
            happiness: actionResponseObj['individualHappiness'],
            isIncrease: true,
            value: actionResponseObj['happinessDiff'] + "%",
            imagePath: props.actors.actorList[actionResponseObj['actorId']]['imagePath']
        };
    }
    const closePopup = () => {
        analyticsUtil.track("ACTION_EVENT_CLOSE");

        let updatedUiState = {
            showOverlay: false,
            overlayComponentType: '',
            overlayComponent: ''
        };
        props.setUiState(updatedUiState);
        //Reset event response data
        props.resetEventResponse()
        if (actionResponseObj['gameOver'].simEnd || 
            (actionResponseObj['gameOver'].simEndCause == "budget" && !actionResponseObj['isBudgetFinished'])) {
           
             let showGameOverPopup = {
                showOverlay: true,
                overlayComponentType: "CENTER",
                overlayComponent: "ENDSCREEN_POPUP",
                isTargetAchieved: actionResponseObj['isTargetAchieved'],
                gameOver: actionResponseObj['gameOver']
            };
            props.setUiState(showGameOverPopup);
        } else {
            // Set next event data
            props.setNextEvent(props.userEventResponse)
        }
    }

    const myStyles = getSkin(props.skinGuide);

    return (
        <div styleName="container">
            <div className={css(myStyles.responseContainer)} styleName="response-container">
                <ActionFeedback {...actionData} actorInfo={actorInfo} closePopup={closePopup} />
                {/* <div styleName='button-container'>
                    <FlatButton
                        textLabel='ok'
                        onClickFunction={() => closePopup()}
                    />
                </div> */}
            </div>
        </div>
    )
}

export default applyWrappers(ActionEventResponse, styles);
