import React, { Fragment } from 'react';
import styles from './historyMetrics.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import IncremenentIcon from 'images/submetrics-increment-arrow.png';
import DecrementIcon from 'images/submetrics-decrement-arrow.png';
import NoChangeIcon from 'images/submetrics_no_change.png';
import budgetIcon from 'images/actionresponse-budget.svg'
import daysIcon from 'images/actionresponse-days.svg'
import IndividualMetricsChange from 'components/action/individualMetricsChange'

const HistoryMetrics = (props) => {
    const delayedResponse = props.delayedResponse;
    const { user_metrics } = props;
    const myStyles = getSkin(props.skinGuide);
    const getTagBGClass = (changeType) => {
        switch (changeType) {
            case 'positive':
                return 'greenTag';

            case 'negative':
                return 'redTag';
            case 'neutral':
                return 'grayTag';

            default:
                return 'greenTag';
        }
    }

    const renderArrow = (changeType) => {
        switch (changeType) {
            case 'positive':
                return (
                    <div>
                        <img src={IncremenentIcon} />
                    </div>
                );

            case 'negative':
                return (
                    <div>
                        <img src={DecrementIcon} />
                    </div>
                );
            case 'neutral':
                return (
                    <div styleName="noChange">
                        <img src={NoChangeIcon} />
                    </div>
                );
            default:
                return null;
        }
    }


    const renderTag = (changeValue, changeType) => {

        const tagBGClass = getTagBGClass(changeType);
        return (
            <div
                className={css(myStyles.metricChange, myStyles[tagBGClass])}
                styleName={"metrics-change"}
            >
                {renderArrow(changeType)}
                {
                    changeType != "neutral" &&
                    <span styleName="space">{Math.abs(changeValue)}</span>
                }
            </div>
        );
    }



    const renderGameMetrics = (title, value, changeValue, border = false) => {
        let changeType = changeValue == 0 ? "neutral" : changeValue > 0 ? 'positive' : 'negative';
        return (
            <div className={border ? css(myStyles["gameMetricsContainer"]) : css(myStyles["gameMetricContainer"])} styleName={"game-metric-container"}>
                <div className={css(myStyles["metricTitle"])} styleName="metric-title">
                    {props.getLabel(title)}
                </div>
                <div styleName={"left-metrics-value-change-container"}>
                    <div className={css(myStyles["metricValue"])}>{value}</div>
                    {renderTag(changeValue, changeType)}
                </div>
            </div>
        );
    }

    const renderBudgetOrDays = (title, value, changeValue, icon) => {
        let val;
        if (icon == budgetIcon) {
            val = "$" + value;
        } else {
            val = value > 0 ? value + " " + props.getLabel('label_tooltip_days_title') : "0 " + props.getLabel("label_history_day");

        }
        let changeType = changeValue == 0 ? "neutral" : changeValue > 0 ? 'positive' : 'negative';
        return (
            <div className={css(myStyles["gameMetricContainer"])} styleName={"game-metric-container", "containerSpace"}>
                <div className={css(myStyles["daysBudgetTitle"])} styleName="days-title">
                    <img styleName="iconSpace" src={icon} />
                    {props.getLabel(title)}
                </div>
                <div styleName={"left-metrics-value-change-container"}>
                    <div className={css(myStyles["metricValue"])}>{val}</div>
                    {renderTag(changeValue, changeType)}
                </div>
            </div>
        );
    }

    const renderDelayedAction = () => {
        let responseMsgObj = { delay: props.delayDay };
        return (
            <div className={css(myStyles["delayedAction"])} styleName="delayed-action">
                <div className={css(myStyles["delayedActionTitle"])}
                    styleName="delayed-action-title">
                    {props.getLabel("label_action_delayed_text", "", responseMsgObj)}
                </div>
            </div>
        );
    }

    const renderTopMetrics = () => {
        return ([
            <div key={Math.random()} styleName={"game-metrics-container"}>
                {!delayedResponse ? <div styleName="happiness">
                    {renderGameMetrics(
                        'label_metric_happiness',
                        (+user_metrics['happiness']['metricValue']).toFixed(1) + "%",
                        (+user_metrics['happiness']['offset']).toFixed(2),
                        true
                    )}</div> : renderDelayedAction()}
                {/* {!delayedResponse ? renderGameMetrics(
					'label_metric_satisfaction',
					(+user_metrics['satisfaction']['metricValue']).toFixed(1) + "%",
					(+user_metrics['satisfaction']['offset']).toFixed(2) ,
					false
				): ""}
				{!delayedResponse ? renderGameMetrics(
					'label_metric_engagement',
					(+user_metrics['engagement']['metricValue']).toFixed(1) + "%",
					(+user_metrics['engagement']['offset']).toFixed(2) ,
					false
				): ""}
				{!delayedResponse ? renderGameMetrics(
					'label_metric_motivation',
					(+user_metrics['motivation']['metricValue']).toFixed(1) + "%",
					(+user_metrics['motivation']['offset']).toFixed(2) ,
					false
				): ""} */}
                {!delayedResponse ? renderGameMetrics(
                    'label_metric_revenue',
                    ("$" + Math.round(user_metrics['revenue']['metricValue']).toLocaleString('en-US')),
                    +(user_metrics['revenue']['offset']).toFixed(2),
                    true
                ) : ""}
                <div styleName="seperator"></div>
                {renderBudgetOrDays(
                    'label_history_budget_remaining',
                    (user_metrics['budget']['metricValue'].toLocaleString('en-US')),
                    (user_metrics['budget']['offset']),
                    budgetIcon
                )}
                {renderBudgetOrDays(
                    'label_history_days_remaining',
                    (user_metrics['duration']['metricValue']),
                    (user_metrics['duration']['offset']),
                    daysIcon
                )}
            </div>
        ]);
    }

    const showTeamMetricChanges = (startPoint, endPoint) => {
        let divArray = [], actorMetrics = {}, actorDetails
        for (let i = startPoint; i <= endPoint; i++) {
            actorMetrics = {
                actorId: props.actorMetrics[i].actorId,
                happiness: {
                    value: props.actorMetrics[i].happiness,
                    offset: props.actorMetrics[i].impact
                },
                category: {
                    value: props.actorMetrics[i].categoryValue,
                    offset: props.actorMetrics[i].categoryOffset
                },
                subdomain: {
                    value: props.actorMetrics[i].subdomainValue,
                    offset: props.actorMetrics[i].subdomainOffset
                }
            }
            actorDetails = props.actors.actorList[actorMetrics.actorId]
            divArray.push(< IndividualMetricsChange
                key={i}
                actorMetrics={actorMetrics}
                {...props.actors.actorList[i]}
                happiness={props.actorMetrics[i].happiness}
                category={props.category.name}
                domain={props.domain}
                name={actorDetails.name}
                imagePath={actorDetails.imagePath}
            />)

        }
        return divArray
    }

    return (
        <Fragment>
            {
                props.actorMetrics &&
                <Fragment>
                    {
                        // First 3 actions,if they exist
                        props.actorMetrics.length >= 4 ?
                            <div styleName='actor-changes-list'>
                                {showTeamMetricChanges(0, 3)}
                            </div>
                            :
                            <div styleName='actor-changes-list'>
                                {showTeamMetricChanges(0, props.actorMetrics.length - 1)}
                            </div>
                    }
                    {
                        // Next actions,if they exist
                        props.actorMetrics.length >= 5 &&
                        <div styleName='actor-changes-list'>
                            {showTeamMetricChanges(4, props.actorMetrics.length - 1)}
                        </div>
                    }
                </Fragment>
            }
            <div styleName={"metrics-container"}>
                <div className={css(myStyles.leftMetricsContainer)} styleName="top-metrics-container">
                    {renderTopMetrics()}
                </div>
            </div>
        </Fragment>
    );
}

export default applyWrappers(HistoryMetrics, styles);