import React from 'react';
import styles from './actionsTaken.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import individualActionImage from 'images/individual-action.png';
import ToolTipComponent from 'components/commonComponents/toolTipComponent'
import IndividualAction from '../individualAction'
import PieChart from '../pieChart'

const ActionsTaken = (props) => {
    const actions = props.actions;
    let individualActionCompData = [];
    let actorIdActionCountHash = {};
    let categoryIdActionCountHash = {};
    let actionsLength = 0;
    let bambaGraphData = [];
    if (actions) {
        actionsLength = actions.length;
    }
    let orgActions = 0, indActions = 0; //in percent
    let orgActionsCount = 0;
    if (actions && actions.length > 0) {
        let length = actions.length;
        actions.forEach(ele => {
            let categoryId = ele.categoryId;
            if (!categoryIdActionCountHash[categoryId]) {
                categoryIdActionCountHash[categoryId] = 0;
            }
            categoryIdActionCountHash[categoryId]++;
            if (ele.isOrgAction) {
                orgActionsCount++;
            }

            else {
                let actorId = ele.actorId;
                let categoryId = ele.categoryId;
                if (!actorIdActionCountHash[actorId]) {
                    actorIdActionCountHash[actorId] = 0;
                }
                actorIdActionCountHash[actorId]++;
            }
        });
        orgActions = ((orgActionsCount * 100) / length).toFixed(0);
        indActions = (100 - orgActions).toFixed(0);
    }
    props.actors.actorsOrder.map((actorId) => {
        let actorActionCount = actorIdActionCountHash[actorId] ? actorIdActionCountHash[actorId] : 0;
        let actorActionScore = Math.floor(actorActionCount * 100 / (actionsLength - orgActionsCount));
        if (!actorActionScore)
            actorActionScore = 0;
        let actorObj = {
            'image': props.actors.actorList[actorId]['imagePath'],
            'name': props.actors.actorList[actorId]['name'],
            'score': actorActionScore + "%"
        };
        individualActionCompData.push(actorObj);
    });
    const colorsBAMBA = ['#B4F7D8', '#65E7AA', '#20DA83', '#0FBE6D', '#079C57'];
    let index = -1;
    props.categories.categoriesOrder.map((categoryId) => {
        index = index + 1;
        let categoryScore = categoryIdActionCountHash[categoryId] ? categoryIdActionCountHash[categoryId] : 0;
        categoryScore = Math.floor(categoryScore * 100 / actionsLength);
        if (!categoryScore)
            categoryScore = 0;
        let categoryName = props.getLabel(props.categories.categoryList[categoryId]['name']);
        let bambaGraphObj = {
            "id": `${categoryName}`,
            "label": `${categoryName}`,
            "value": categoryScore,
            "color": colorsBAMBA[index]
        };

        bambaGraphData.push(bambaGraphObj);
    });

    // data for first pie
    const pieDataAction = [
        {
            "id": "Individual",
            "label": props.getLabel("label_pie_chart_individual"),
            "value": Number(indActions),

        },
        {
            "id": "Org-Wide",
            "label": props.getLabel("label_pie_chart_org"),
            "value": Number(orgActions),
            "color": "#C6FBE2"
        }
    ];
    const colorsAction = ['#32BD9B', '#C6FBE2'];

    const myStyles = getSkin(props.skinGuide);
    const renderPieDesc = () => {
        let pieFooter = [];
        index = -1;
        props.categories.categoriesOrder.map((categoryId, index) => {
            //index = index + 1;
            let categoryScore = categoryIdActionCountHash[categoryId] ? categoryIdActionCountHash[categoryId] : 0;
            categoryScore = Math.floor(categoryScore * 100 / actionsLength);
            if (!categoryScore)
                categoryScore = 0;
            let categoryName = props.getLabel(props.categories.categoryList[categoryId]['name']);
            let stylePie = {
                backgroundColor: colorsBAMBA[index],
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                marginRight: '4px',
                marginTop: '2px'
            };
            pieFooter.push(renderEachTag(categoryName, categoryScore, stylePie, index));
        });
        return pieFooter;
    };

    const renderEachTag = (categoryName, categoryScore, stylePie, index) => {
        return (
            <div key={index} styleName="pie-footer">
                <div style={stylePie}></div>
                <div>
                    <div className={css(myStyles.pieTitleBAMBA)} styleName="pie-title1">
                        {categoryName}
                    </div>
                    <div className={css(myStyles.pieScoreBAMBA)}>
                        {categoryScore}%
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div styleName="heading" className={css(myStyles.title)}>
                {props.getLabel("label_intermediate_report_action_taken_title")}
            </div>
            <div styleName="desc" className={css(myStyles.description)}>
                {props.getLabel("label_intermediate_report_action_taken_desc")}
            </div>
            <div styleName="graph">
                <div styleName="first-graph">
                    <div styleName="OrgVsIndTitle" className={css(myStyles.title)}>
                        {props.getLabel("label_intermediate_report_chart1_title")}
                        <div styleName="tooltip">
                            <ToolTipComponent
                                tooltipText={props.getLabel("label_intermediate_report_chart1_desc")}
                                alignTooltipKey="BOTTOM"
                                tooltipStyles={{
                                    maxWidth: '220px'
                                }}
                                dark={true}
                            />
                        </div>
                    </div>
                    <div styleName="pie-height">
                        <div styleName="pie-center">
                            <PieChart pieData={pieDataAction} colors={colorsAction} />
                        </div>
                        <div styleName="pie-desc">
                            <div styleName="pie-org-color"></div>
                            <div>
                                <div className={css(myStyles.pieTitle)} styleName="pie-title">
                                    {props.getLabel("label_org_wide_actions")}
                                </div>
                                <div className={css(myStyles.pieScore)}>
                                    {orgActions}%
                                </div>
                            </div>

                            <div styleName="pie-ind-color"></div>
                            <div>
                                <div className={css(myStyles.pieTitle)} styleName="pie-title">
                                    {props.getLabel("label_intermediate_report_chart1_legend2")}
                                </div>
                                <div className={css(myStyles.pieScore)}>
                                    {indActions}%
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div styleName="second-graph">
                    <div styleName="IndividualActionTitle" className={css(myStyles.title)}>
                        {props.getLabel("label_intermediate_report_chart2_title")}
                        <div styleName="tooltip">
                            <ToolTipComponent
                                tooltipText={props.getLabel("label_intermediate_report_chart2_desc")}
                                alignTooltipKey="BOTTOM"
                                tooltipStyles={{
                                    maxWidth: '220px'
                                }}
                                dark={true}
                            />
                        </div>
                    </div>
                    <div styleName="individual-breakup">
                        <div styleName="indivisualActionImage">
                            <img src={individualActionImage} />
                        </div>
                        <div styleName="individualActionBreakup">
                            <IndividualAction listOfActor={individualActionCompData} />
                        </div>
                    </div>
                </div>
                <div styleName="line" />
                <div styleName="third-graph">
                    <div styleName="BAMBA-title" className={css(myStyles.title)}>
                        {props.getLabel('label_intermediate_report_chart3_title')}
                        <div styleName="tooltip">
                            <ToolTipComponent
                                tooltipText={props.getLabel("label_intermediate_report_chart3_desc")}
                                alignTooltipKey="BOTTOM"
                                tooltipStyles={{
                                    maxWidth: '220px'
                                }}
                                dark={true}
                            />
                        </div>
                    </div>
                    <div styleName="pie-height">
                        <div styleName="pie-center">
                            <PieChart pieData={bambaGraphData} colors={colorsBAMBA} />
                        </div>
                        <div styleName="pie-desc1">
                            {renderPieDesc()}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default applyWrappers(ActionsTaken, styles);
