import React from 'react';
import styles from './actionCardComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import IndividualIcon from 'images/individual-icon.svg'
import OrgIcon from 'images/org-icon.svg'
import CalendarIcon from 'images/calendar-icon.svg'
import SelectedCalendarIcon from 'images/calendar-icon-selected.svg'
import BudgetIcon from 'images/budget-icon.svg'
import SelectedBudgetIcon from 'images/budget-icon-selected.svg'
import selectedCardIcon from 'images/card-selected.svg'


const ActionCardComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);
    const skin = props.skinGuide.globalProfiles.palette

    let selectedCardStyle = {
        border: 'double 4px transparent',
        backgroundImage: `linear-gradient(white, white), linear-gradient(144.43deg, ${skin.buttonColor1} 4.65%, ${skin.buttonColor2} 97.99%)`
    }, selectedIconStyle = {
        opacity: 'unset',
        backgroundImage: `linear-gradient(144.43deg, ${skin.buttonColor1} 4.65%, ${skin.buttonColor2} 97.99%)`
    }, selectedTextStyle = {
        fontWeight: 600,
        color: skin.buttonColor1,
        opacity: 'unset'
    }

    let normalCardStyle = {
        border: 'double 4px transparent',
        backgroundImage: `linear-gradient(white, white), linear-gradient(to left, white, white 75%)`
    }

    let icons = props.isCurrentActionSelected ?
        {
            indIcon: IndividualIcon,
            orgIcon: OrgIcon,
            calendarIcon: SelectedCalendarIcon,
            budgetIcon: SelectedBudgetIcon
        } : {
            indIcon: IndividualIcon,
            orgIcon: OrgIcon,
            calendarIcon: CalendarIcon,
            budgetIcon: BudgetIcon
        }

    const onActionCardClick = () => {
        if (props.isEventAction) {
            if (props.userEventResponse.eventActionId !== -1) {
                // this is to prevent clicking on card after event response has come
                return
            } else {
                //If event action, just execute the saveSelectedActionId function
                props.saveSelectedActionId(props.id)
            }
        } else if (props.isClickDisabled) {
            return
        } else {
            // If normal action, take all the necessary steps required
            if (props.actionSequence.selectedActionId != props.id) {
                props.saveSelectedActorId(-1);
            }
            if (!props.isCurrentActionSelected && !props.isClickDisabled)
                props.saveSelectedActionId(props.id)

            if (props.typeId == 1)
                props.onIndividualActionClick();
            else
                props.onOrgActionClick()
        }
    }

    let { currentAttempt, maxAttempts, isEventAction, lastTimeTaken, minTimeForAttempt, duration, cost } = props
    let isCardDisabled = false
    let disabledCardText = ''
    const currentDay = props.introData["initialDuration"] - props.overallMetrics["duration"]["metricValue"];

    if (!isEventAction && duration > props.overallMetrics["duration"]["metricValue"]) {
        isCardDisabled = true;
        disabledCardText = props.getLabel('label_action_duration_exhaust')
    } else if (!isEventAction && cost > props.overallMetrics["budget"]["metricValue"]) {
        isCardDisabled = true;
        disabledCardText = props.getLabel('label_action_budget_exhaust')
    }
    if (!isCardDisabled && !Array.isArray(currentAttempt)) {
        // If card is org-wide and exceeds max maxAttempts, disabled card
        isCardDisabled = !isEventAction && currentAttempt >= maxAttempts
        disabledCardText = props.getLabel('label_action_max_attempts_reached')

        if (!isCardDisabled && lastTimeTaken !== null && !Array.isArray(lastTimeTaken)) {
            // If max attempts not reached, card is org-wide but action cooldown time present disable the card
            isCardDisabled = !isEventAction && ((lastTimeTaken + minTimeForAttempt) > currentDay)
            disabledCardText = `${props.getLabel("label_actor_max_days_reached")} ${lastTimeTaken + minTimeForAttempt - currentDay} ${props.getLabel("label_days")}`
        }
    }

    return (
        !isCardDisabled ?
            <div
                styleName='container'
                className={css(myStyles.container)}
                style={props.isCurrentActionSelected ? selectedCardStyle : normalCardStyle}
                onClick={() => onActionCardClick()}
            >
                {/* Different styles based on whether the action is selected */}
                {
                    !props.isCurrentActionSelected ?
                        <div
                            styleName='select-icon'
                            className={css(myStyles.selectIcon)}
                        />
                        : <img styleName='select-icon' src={selectedCardIcon} />
                }

                <div styleName='action-name' className={css(myStyles.actionName)} style={props.isCurrentActionSelected ? selectedTextStyle : {}}>
                    {props.getLabel(props.name)}
                </div>
                <div styleName='hr-line' className={css(myStyles.hrLine)} style={props.isCurrentActionSelected ? selectedIconStyle : {}} />
                <div styleName='details' className={css(myStyles.actionDetails)} style={props.isCurrentActionSelected ? selectedTextStyle : {}}>
                    <div styleName='action-icon'>
                        {
                            props.typeId === 1 ?
                                <img src={icons.indIcon} /> :
                                <img src={icons.orgIcon} />
                        }
                    </div>
                    <div styleName='vr-line' className={css(myStyles.vrLine)} />
                    <div styleName='day-info'>
                        <img src={icons.calendarIcon} />
                        <div styleName='details-text' className={css(myStyles.text)}>{props.duration} {props.getLabel("label_metric_duration")}</div>
                    </div>
                    <div styleName='budget-info'>
                        <img src={icons.budgetIcon} />
                        <div styleName='details-text' className={css(myStyles.text)}>${props.cost}</div>
                    </div>
                </div>
            </div>
            :
            <div
                styleName='container'
                className={css(myStyles.containerDisabled)}
            >
                <div styleName='action-name' className={css(myStyles.actionNameDisabled)} style={props.isCurrentActionSelected ? selectedTextStyle : {}}>
                    {props.getLabel(props.name)}
                </div>
                <div styleName='details-disabled' className={css(myStyles.actionDetails)} style={props.isCurrentActionSelected ? selectedTextStyle : {}}>
                    <div styleName='action-icon-disabled'>
                        {
                            props.typeId === 1 ?
                                <img src={icons.indIcon} /> :
                                <img src={icons.orgIcon} />
                        }
                    </div>
                    <div styleName='text-action-disabled' className={css(myStyles.textActionDisabled)}>
                        {disabledCardText}
                    </div>
                </div>
            </div>
    )
}

export default applyWrappers(ActionCardComponent, styles);
