import React, { Fragment } from 'react';
import styles from './subDomainsListComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import actionArrow from 'images/action-arrow.svg'
import { hexToRgbA } from 'utils/styleUtil'

const SubDomainsListComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);
    const skin = props.skinGuide.globalProfiles.palette

    let { isReactJoyrideRunning, isActionsTutorialRunning, type, stepIndex } = props.tutorial
    let { actionStepNumber, selectedCategoryId, selectedSubDomainId } = props.actionSequence

    const onSubDomainClick = (id) => {
        // Save selected category and go to next step
        props.saveSelectedSubDomainId(id)
        props.saveSelectedActionId(-1)
        props.setActionStepNumber(2)
    }

    let selectedSubDomainTextStyle = (id) => {
        if (id === selectedSubDomainId) {
            return {
                color: skin.primaryColor,
                fontWeight: 800
            }
        } else {
            return {}
        }
    }

    let selectedCategory = props.categories.categoryList && props.categories.categoryList[selectedCategoryId]
    let i = 0

    const getSubDomains = () => {
        let subDomainDivArray = []
        let subDomains = selectedCategory ? selectedCategory.subDomains : []
        if (selectedCategory) {
            let subDomainsOrder = selectedCategory.subDomainsOrder
            subDomainsOrder.map(item => {
                let borderStyle = {
                    width: '2px',
                    minHeight: '25px', //25px
                    height: '100%',
                    backgroundColor: `${hexToRgbA(skin.primaryColor, 0.5)}`
                }
                ++i //This is to get last element of array 

                //Use 0 padding for longer named subdomain
                let isZeroPaddingNeeded = subDomains[item].name === 'label_ac_subdomain_intrinsic_vs_extrinsic_rewards'

                subDomainDivArray.push(
                    <Fragment
                        key={subDomains[item].id}>
                        <div
                            styleName='domain-container'
                            className={css(myStyles.domianName)}
                            onClick={() => onSubDomainClick(subDomains[item].id)}
                            style={isZeroPaddingNeeded ? { padding: 'unset' } : {}}
                        >
                            <div
                                styleName='domain-name'
                                className={css(myStyles.name)}
                                style={selectedSubDomainTextStyle(subDomains[item].id)}
                            >
                                {props.getLabel(subDomains[item].name)}
                            </div>
                        </div>
                        <div
                            style={i !== Object.keys(subDomains).length ? borderStyle : {}}
                        />
                    </Fragment>
                )
            })
        }
        return subDomainDivArray
    }

    // If the action sequence number is less than 2 and tutorial is not ongoing, hide the component.
    let isVisible = (!isReactJoyrideRunning && !isActionsTutorialRunning && actionStepNumber < 1) ?
        { visibility: 'hidden' } : {}

    //Variable to store whether the component is highlighted, action sequence step 1
    let highlightedComponentStyle = (!isReactJoyrideRunning && !isActionsTutorialRunning && actionStepNumber === 1) ?
        { boxShadow: `0px 0px 24px ${hexToRgbA(skin.primaryColor, 0.85)}` } : {}

    let componentStyle = { ...isVisible, ...highlightedComponentStyle }

    return (
        <div
            styleName="container"
            className={css(myStyles.container)}
            style={componentStyle}
        >
            {getSubDomains()}
            {
                // If action sequence step 2 is ongoing, show helper text
                (!isReactJoyrideRunning && !isActionsTutorialRunning && actionStepNumber === 1) &&
                <div styleName='helper-textbox'>
                    <div styleName='arrow'>
                        <img src={actionArrow} />
                    </div>
                    <div styleName='text' className={css(myStyles.text)}>{props.getLabel('label_dashboard_select_subdomain_text')}</div>
                </div>
            }
        </div>
    )
}

export default applyWrappers(SubDomainsListComponent, styles);
