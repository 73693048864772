import React from 'react';
import { connect } from 'react-redux';
import IntermediateReportComponent from 'components/intermediateReport';
import { setUiState, setHighlightedSidebarComponent } from 'actions/uiState/actionCreators';
import { fetchIntermediateReportData } from "actions/apiAction/fetchIntermediateReportData";
import { setMonthlyReportSeenFlag } from 'actions/apiAction/userApis'

class IntermediateReportContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDataForMonth: "month1"
        };
    }

    componentDidMount() {
        this.props.fetchIntermediateReportData();
        let { currentReportMonth } = this.props.uiState
        if (currentReportMonth) {
            this.setShowData(currentReportMonth)
        }
    }

    setShowData = (type) => {
        this.setState({
            showDataForMonth: type
        })
    }

    render() {
        return (
            <IntermediateReportComponent
                {...this.props}
                onClickOfTab={this.setShowData}
                showDataForMonth={this.state.showDataForMonth}
                setMonthlyReportSeenFlag={setMonthlyReportSeenFlag}
            />
        );
    }
}

IntermediateReportContainer.propTypes = {};

IntermediateReportContainer.defaultProps = {};

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => ({
    fetchIntermediateReportData: () => {
        dispatch(fetchIntermediateReportData());
    },
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    setHighlightedSidebarComponent: (payload) => {
        dispatch(setHighlightedSidebarComponent(payload))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IntermediateReportContainer);
