import actionConsts from 'constants/actions';

const initialState = {};

const intermediateReport = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INTERMEDIATE_REPORT_DATA:
            return action.payload.report;
        default:
            return state;
    }
};

export default intermediateReport;