import React from 'react';
import { connect } from 'react-redux';
import LogoutConfirmationPopup from 'components/logoutConfirmationPopup';
import { setUiState } from 'actions/uiState/actionCreators';
import { setTimerStarted } from 'actions/apiAction/userApis'

class LogoutConfirmationContainer extends React.Component {

    render() {
        return (
            <LogoutConfirmationPopup {...this.props} />
        );
    }
}

LogoutConfirmationContainer.propTypes = {};

LogoutConfirmationContainer.defaultProps = {};

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => ({
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    setTimerStarted: () => {
        dispatch(setTimerStarted())
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogoutConfirmationContainer);
