import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
    const skin = globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        leftMetricsContainer: {
            // borderRight: `solid 1px ${hexToRgbA(skin.grayColor1, 0.1)}`
        },
        metricTitle: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '10px',
            lineHeight: '163.42%',
            color: '#031A20',
            opacity: '0.5',
            marginTop: '3px',
            marginBottom: '3px',
        },
        daysBudgetTitle: {
            //background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`,			fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '10px',
            lineHeight: '14px',
            textTransform: 'uppercase',
            marginTop: '3px',
            marginBottom: '3px',
        },
        metricValue: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '10px',
            lineHeight: '14px',
            display: 'flex',
            alignItems: 'center',
            color: skin.black,
            marginRight: '5px'
        },
        metricChange: {
            ...myTypography.body1,
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: 'normal',
            color: skin.white,
        },
        greenTag: {
            background: `#7CEBD1`
        },
        redTag: {
            background: `#FF9292`
        },
        grayTag: {
            background: `#C7C7C7`
        },
        gameMetricContainer: {
            marginRight: '12px',
            marginTop: '3px'
        },
        gameMetricContainerRightBorder: {
            borderRight: `solid 1px ${hexToRgbA(skin.grayColor1, 0.1)}`
        },

        gameMetricsContainer: {
            background: 'skin.white',
            borderRadius: '10px',
            border: '0.5px solid #FB7A7F',
            padding: '4px 14px 7px 14px'
        },

        rightMetricLeftBorder: {
            borderLeft: `1px solid ${skin.grayColor5}`
        },
        delayedAction: {
            background: skin.white
        },
        delayedActionTitle: {
            //background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`,
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px'
        },
    });
}

export default getSkin;