import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		name:{
			fontFamily: "Nunito",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "10px",
			lineHeight: "14px",
			color: "#000000"
		},
		score:{
			fontFamily: "Open Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "12px",
			lineHeight: "16px",
			color: "#000000"
		}
	});
}

export default getSkin;