import React from 'react';
import { connect } from 'react-redux';
import TutorialCompletedPopup from 'components/tutorialCompletedPopup';
import { setUiState } from 'actions/uiState/actionCreators';
import { setTimerStarted } from 'actions/apiAction/userApis'

class TutorialCompletedPopupContainer extends React.Component {

    render() {
        return (
            <TutorialCompletedPopup {...this.props} />
        );
    }
}

TutorialCompletedPopupContainer.propTypes = {};

TutorialCompletedPopupContainer.defaultProps = {};

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => ({
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    setTimerStarted: () => {
        dispatch(setTimerStarted())
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TutorialCompletedPopupContainer);
