import React, { useState } from 'react';
import styles from './logoutComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import logoutIcon from 'images/logout-icon.svg';
import { getUserName } from 'utils/utilFunctions';
import SidebarArrow from 'images/sidebar-arrow.svg'

const LogoutComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);
    const skin = props.skinGuide.globalProfiles.palette

    const onClickLogout = () => {
        props.setUiState({
            showOverlay: true,
            overlayComponentType: 'CENTER',
            overlayComponent: 'LOGOUT_CONFIRMATION_POPUP'
        })
    }

    const logoutOutButtonStyle = {
        backgroundColor: skin.secondaryColor
    }

    const [showTooltip, setShowTooltip] = useState(false);
    let tooltipContent = props.getLabel("label_logout")

    const { tutorial } = props
    const isTutorialButtonStyleNeeded = tutorial.isReactJoyrideRunning && !tutorial.isActionsTutorialRunning

    const userName = getUserName(props.userInfo)

    return (
        <div
            styleName='container'
            className={css(myStyles.container)}
            style={isTutorialButtonStyleNeeded ? logoutOutButtonStyle : {}}
            onMouseEnter={() => { setShowTooltip(true); }}
            onMouseLeave={() => { setShowTooltip(false); }}
        >
            <div styleName='username' data-tooltip={userName}>{userName}</div>
            <img src={logoutIcon} onClick={() => { onClickLogout(); }} />
            {
                showTooltip ?
                    <div styleName="tooltip-container-right">
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{ __html: props.getLabel(tooltipContent) }}
                        ></div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default applyWrappers(LogoutComponent, styles);
