import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
// import { hexToRgbA } from 'util/styleUtil';

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            fontFamily: 'Open Sans',
            // background: `linear-gradient(180deg, ${skin.white} 0%, #FBF1F4 0.01%, rgba(255, 255, 255, 0.94) 100%)`
        },
        header: {
            background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`
        },
        historyContent: {
            // backgroundColor: skin.primaryColor,
            backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.19), rgba(255, 255, 255, 0.14))`
            // boxShadow: `0 1px 1px 2px ${hexToRgbA(skin.white, 0.1)}`
        },
        historyName: {
            ...myTypography.body1,
            fontSize: '18px',
            fontWeight: 600,
            textTransform: 'uppercase',
            color: skin.secondaryColor
        },
        title: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '25px',
            lineHeight: '34px',
            color: skin.white
        },
        day: {
            color: skin.primaryColor,
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '16px',
        },
        dayNumber: {
            color: skin.primaryColor,
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '27px'
        },
        box: {
            backgroundImage: `linear-gradient(125.94deg, ${skin.primaryPopupBgColor} 4.65%, ${skin.secondaryPopupBgColor} 50.35%, ${skin.tertiaryPopupBgColor} 97.99%)`
        },
        borderbottom: {
            borderBottom: `1px solid ${skin.white}`
        },
        spacer: {
            borderLeft: `2px solid ${skin.primaryColor}`
        },
        name: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '19px',
            color: skin.grayColor1,
            opacity: '0.9'
        },
        msg: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '172%',
            opacity: '0.7'
        },
        aHash: {
            color: '#FFB341',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '163.42%'
        },
        eHash: {
            color: '#6741FF',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '163.42%'
        },
        filterBoxAll: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '10px',
            background: '#FFFFFF',
            color: '#41D2FF'
            // opacity: '0.2'
        },

        filterBoxAction: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '10px',
            background: '#FFFFFF',
            color: '#FFB341'
            // opacity: '0.2'
        },

        delayedImpact: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '8px',
            lineHeight: '11px',
            color: skin.white,
        },

        setActive: {
            textDecoration: 'underline'
        },

        filterBoxEvent: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '10px',
            background: '#FFFFFF',
            color: '#6741FF'
            // opacity: '0.2'
        }
    });
}

export default getSkin;