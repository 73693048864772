import consts from 'constants/url';
import netpack from 'utils/netpack';
import actionConsts from 'constants/actions';

export const fetchLeaderboardData = () => (dispatch) => {
    dispatch({
        type: actionConsts.UPDATE_LEADERBOARD_STATUS,
        payload: true
    });
    netpack(consts.LEADERBOARD_API)
        .get()
        .then((response) => {
            dispatch({
                type: actionConsts.LEADERBOARD_DATA,
                payload: response
            })
        });
    }
