import React, { Fragment } from "react";
import { connect } from "react-redux";
import DesktopHeader from "components/header/desktopHeader";
import DashboardComponent from "components/dashboardComponent";
import SideBarListComponent from "../components/sideBarListComponent";
import { setUiState, updateUISize, setHighlightedSidebarComponent } from "actions/uiState/actionCreators";
import Overlay from "../components/commonComponents/overlay";
import Tutorial from 'components/tutorial'
import {
    startReactJoyrideTutorial, pauseTutorial, addStepsToTutorial, endTutorial,
    updateTutorialStepNumber, showActionsUncoveredPage, startActorsTutorial,
    hideActionsUncoveredPage, startActionsTutorial, stopActionsTutorial,
    stopActorsTutorial
} from 'actions/tutorial'
import tutorialSequence from 'constants/tutorialSequence'
import {
    saveSelectedCategoryId,
    saveSelectedCategoryNameForTutorial,
    saveSelectedSubDomainId,
    saveSelectedSubDomainForTutorial,
    saveSelectedActionId,
    saveSelectedActorId,
    setActionStepNumber,
    resetActionSequence
} from 'actions/actionSequence'
import {
    saveShowActorId,
} from 'actions/actors'
import { actionTaken } from 'actions/apiAction/actionTaken';
import { setActionTutorialCompleted } from 'actions/apiAction/userApis'
import { actorVisitedApi, getActorDetails } from 'actions/apiAction/actorApis'
import { updateOverallMetricsForEvent } from 'actions/event'
import updateRoute from 'utils/webAppRoutes/updateRoute'
import { setActorTutorialCompleted, resetActorTutorialCompleted } from 'actions/apiAction/userApis'
import DashboardBg from 'images/dashboard-bg.svg'
import LoadingPage from 'components/commonComponents/loadingPage'
class DesktopDashboardContainer extends React.Component {

    componentDidMount() {
        const { userInfo, uiState } = this.props

        if (!userInfo.isSimStarted) {
            // If game is not started, redirect to intro route
            updateRoute({ route: '/intro' })
        } else if (userInfo.isGameCompleted) {
            updateRoute({ route: '/end-report' })
        } else if (uiState.showMonthlyReportFlag) {
            this.checkForMonthlyReport()
        } else if (userInfo.gameOver.simEnd || 
            (userInfo.gameOver.simEndCause == "budget" && !userInfo['isBudgetFinished'])) {
           
             let showGameOverPopup = {
                showOverlay: true,
                overlayComponentType: "CENTER",
                overlayComponent: "ENDSCREEN_POPUP",
                isTargetAchieved: userInfo.isTargetAchieved,
                gameOver: userInfo.gameOver
            };
            this.props.setUiState(showGameOverPopup);
        } else if (userInfo.userTimer === 0) {
            // If timer is 0 and end screen popup not shown
            let showGameOverPopup = {
                showOverlay: true,
                overlayComponentType: "CENTER",
                overlayComponent: "TIMEOVER_POPUP"
            };
            this.props.setUiState(showGameOverPopup);
        } else if (userInfo.isSimStarted) {
            // If game is started and actor tutorial is not completed, start actor tutorial
            if (!userInfo.isActorTutorialCompleted) {
                this.setActorTutorialSteps()
                this.props.updateTutorialStepNumber(0)
                this.props.startReactJoyrideTutorial()
            } else if (!userInfo.isActionTutorialCompleted) {
                // If game is started and action tutorial is not completed, start action tutorial
                this.props.showActionsUncoveredPage()
            } else if (!userInfo.isTimerStarted) {
                // If timer is not started, open popup
                this.props.setUiState({
                    showOverlay: true,
                    overlayComponentType: 'CENTER',
                    overlayComponent: 'TUTORIAL_COMPLETED_POPUP'
                });
            } else if (uiState.showMonthlyReportFlag) {
                this.checkForMonthlyReport()
            } else {
                this.checkUserEventProgress()
            }
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { userInfo, uiState } = this.props
        if (userInfo.isGameCompleted) {
            updateRoute({ route: '/end-report' })
        }

        // This is to show the intermediate report before end of game
        if (userInfo.gameOver.simEnd && !uiState.showMonthlyReportFlag && prevProps.uiState.showMonthlyReportFlag) {
            let showGameOverPopup = {
                showOverlay: true,
                overlayComponentType: "CENTER",
                overlayComponent: "ENDSCREEN_POPUP",
                isTargetAchieved: userInfo.isTargetAchieved,
                gameOver: userInfo.gameOver
            };
            this.props.setUiState(showGameOverPopup);
        }
        if (userInfo.userTimer !== 0) {
            if (!uiState.showOverlay) {
                if (uiState.showMonthlyReportFlag) {
                    // Check if monthly report flag is true
                    this.checkForMonthlyReport()
                }
                if (this.props.userEvent.isNewEventAvailable) {
                    // If isNewEventAvailable is true, check for new event
                    this.checkUserEventProgress()
                }
            }
        }

    }

    checkForMonthlyReport = () => {
        // If monthly report is to be shown, this function will set the flags
        const { uiState } = this.props
        if (uiState.showMonthlyReportFlag) {
            // show the report
            this.onClickOfIntermediateReport()
        }
    }

    checkUserEventProgress = () => {
        // This function checks if there are pending events to be shown to the user and display them
        let { userEvent } = this.props
        if (userEvent.id) {
            if (userEvent.isActionEvent) {
                this.props.setUiState({
                    showOverlay: true,
                    overlayComponentType: 'CENTER',
                    overlayComponent: 'ACTION_EVENT_POPUP'
                });
            } else {
                this.props.updateOverallMetricsForEvent(userEvent)
                this.props.setUiState({
                    showOverlay: true,
                    overlayComponentType: 'CENTER',
                    overlayComponent: 'EVENT_POPUP'
                });
            }
        }
    }

    onClickOfHistory = () => {
        this.props.setUiState({
            showOverlay: true,
            overlayComponentType: "CENTER",
            overlayComponent: "HISTORY_POPUP"
        });
    };

    onClickOfLeaderboard = () => {
        this.props.setUiState({
            showOverlay: true,
            overlayComponentType: "CENTER",
            overlayComponent: "LEADERBOARD_POPUP"
        });
    };

    onClickOfObjectives = () => {
        this.props.setUiState({
            showOverlay: true,
            overlayComponentType: "CENTER",
            overlayComponent: "VIDEO_AND_OBJECTIVES_POPUP"
        });
    };

    onClickOfIntermediateReport = () => {
        this.props.setUiState({
            showOverlay: true,
            overlayComponentType: "CENTER",
            overlayComponent: "INTERMEDIATE_REPORT_POPUP"
        });
    };

    goToActorTutorialCompletedScreen = () => {
        this.props.showActionsUncoveredPage()
        this.props.stopActionsTutorial()
        this.props.pauseTutorial()
    }

    onShowBambaButtonClick = () => {
        // Start action tutorial
        let labelValues = {
            "label_tutorial_next_button": this.getLabel('label_tutorial_next_button'),
            "label_tutorial_previous_button": this.getLabel('label_tutorial_previous_button'),
            "label_tutorial_got_it_button": this.getLabel('label_tutorial_got_it_button'),
            "label_tutorial_finish_button": this.getLabel('label_tutorial_finish_button'),
            "label_tutorial_select_category_title": this.getLabel('label_tutorial_select_category_title'),
            "label_tutorial_select_category_desc": this.getLabel('label_tutorial_select_category_desc'),
            "label_tutorial_subdomain_title": this.getLabel('label_tutorial_subdomain_title'),
            "label_tutorial_subdomain_desc": this.getLabel('label_tutorial_subdomain_desc'),
            "label_tutorial_action_card_title": this.getLabel('label_tutorial_action_card_title'),
            "label_tutorial_action_card_desc": this.getLabel('label_tutorial_action_card_desc'),
            "label_tutorial_days_per_action": this.getLabel('label_tutorial_days_per_action'),
            "label_tutorial_budget_per_action": this.getLabel('label_tutorial_budget_per_action'),
            "label_tutorial_individual_action": this.getLabel('label_tutorial_individual_action'),
            "label_tutorial_org_action": this.getLabel('label_tutorial_org_action'),
            "label_tutorial_select_action_continue": this.getLabel('label_tutorial_select_action_continue'),
            "label_tutorial_take_action": this.getLabel('label_tutorial_take_action'),
            "label_tutorial_action_outcome": this.getLabel('label_tutorial_action_outcome'),
            "label_tutorial_end": this.getLabel('label_tutorial_end')
        }
        this.props.hideActionsUncoveredPage()
        this.props.startActionsTutorial()
        this.props.addStepsToTutorial(
            tutorialSequence.actionSteps(this.goToActorTutorialCompletedScreen, this.props.updateTutorialStepNumber, this.props.saveSelectedCategoryNameForTutorial,
                this.props.saveSelectedSubDomainForTutorial, this.props.setActionTutorialCompleted, labelValues),
            'actions'
        )
        this.props.startReactJoyrideTutorial()
    }

    getLabel = (key, defaultValue = "", vars = {}) => {
        let { labels } = this.props
        if (defaultValue === "") {
            defaultValue = key;
        }
        let label = defaultValue;
        if (labels !== undefined && labels[key] !== undefined) {
            label = labels[key];
        }
        if (key === null || key === undefined) {
            label = defaultValue;
        }

        for (var varKey in vars) {
            if (vars.hasOwnProperty(varKey)) {
                label = label.replace(new RegExp(`%{${varKey}}`, 'g'), vars[varKey]);
            }
        }
        return label;
    }

    setActorTutorialSteps = () => {
        let labelValues = {
            "label_tutorial_next_button": this.getLabel('label_tutorial_next_button'),
            "label_tutorial_previous_button": this.getLabel('label_tutorial_previous_button'),
            "label_tutorial_got_it_button": this.getLabel('label_tutorial_got_it_button'),
            "label_tutorial_finish_button": this.getLabel('label_tutorial_finish_button'),
            "label_tutorial_timer_title": this.getLabel('label_tutorial_timer_title'),
            "label_tutorial_timer_desc": this.getLabel('label_tutorial_timer_desc'),
            "label_tutorial_day_budget_title": this.getLabel('label_tutorial_day_budget_title'),
            "label_tutorial_day_budget_desc": this.getLabel('label_tutorial_day_budget_desc'),
            "label_tutorial_metrics_title": this.getLabel('label_tutorial_metrics_title'),
            "label_tutorial_metrics_desc": this.getLabel('label_tutorial_metrics_desc'),
            "label_tutorial_revenue_title": this.getLabel('label_tutorial_revenue_title'),
            "label_tutorial_revenue_desc": this.getLabel('label_tutorial_revenue_desc'),
            "label_tutorial_sidebar_title": this.getLabel('label_tutorial_sidebar_title'),
            "label_tutorial_sidebar_desc": this.getLabel('label_tutorial_sidebar_desc'),
            "label_tutorial_sidebar_point_1": this.getLabel('label_tutorial_sidebar_point_1'),
            "label_tutorial_sidebar_point_2": this.getLabel('label_tutorial_sidebar_point_2'),
            "label_tutorial_sidebar_point_3": this.getLabel('label_tutorial_sidebar_point_3'),
            "label_tutorial_sidebar_point_4": this.getLabel('label_tutorial_sidebar_point_4'),
            "label_tutorial_sidebar_leaderboard": this.getLabel('label_tutorial_sidebar_leaderboard'),
            "label_tutorial_sidebar_objectives": this.getLabel('label_tutorial_sidebar_objectives'),
            "label_tutorial_sidebar_history": this.getLabel('label_tutorial_sidebar_history'),
            "label_tutorial_sidebar_statistics_1": this.getLabel('label_tutorial_sidebar_statistics_1'),
            "label_tutorial_sidebar_statistics_2": this.getLabel('label_tutorial_sidebar_statistics_2'),
        }
        this.props.addStepsToTutorial(
            tutorialSequence.headerSteps(this.props.updateTutorialStepNumber, this.props.pauseTutorial,
                this.props.startActorsTutorial, labelValues),
            'header'
        )
    }

    render() {
        let dashboardContainerStyle = {};
        if (this.props.uiState.showOverlay) {
            dashboardContainerStyle = {
                ...dashboardContainerStyle,
                opacity: "0.9"
            };
        }

        const skin = this.props.colorProfiles.globalProfiles.palette

        if (this.props.actionSequence.actionStepNumber === 3) {
            // This is to hide sidebar component
            dashboardContainerStyle = {
                ...dashboardContainerStyle,
                backgroundImage: `linear-gradient(129.82deg, #FFFBFB 39.8 %, ${skin.white} 97.99 %)`
            };
        }

        return (
            <Fragment>
                <div className="base-app-box" >
                    <DesktopHeader
                        isIntro={false}
                        {...this.props}
                    />
                    {
                        this.props.uiState.actionResponseLoader ?
                            <LoadingPage />
                            :
                            <div className='app-body' style={{ backgroundImage: `url(${DashboardBg})` }}>
                                <DashboardComponent
                                    tutorial={this.props.tutorial}
                                    onShowBambaButtonClick={this.onShowBambaButtonClick}
                                    stopActionsTutorial={this.props.stopActionsTutorial}
                                    startReactJoyrideTutorial={this.props.startReactJoyrideTutorial}
                                    updateTutorialStepNumber={this.props.updateTutorialStepNumber}
                                    categories={this.props.categories}
                                    actors={this.props.actors}
                                    actionList={this.props.actionList}
                                    actionSequence={this.props.actionSequence}
                                    saveSelectedCategoryId={this.props.saveSelectedCategoryId}
                                    saveSelectedSubDomainId={this.props.saveSelectedSubDomainId}
                                    saveSelectedActionId={this.props.saveSelectedActionId}
                                    saveSelectedActorId={this.props.saveSelectedActorId}
                                    saveShowActorId={this.props.saveShowActorId}
                                    setActionStepNumber={this.props.setActionStepNumber}
                                    resetActionSequence={this.props.resetActionSequence}
                                    actionTaken={this.props.actionTaken}
                                    userInfo={this.props.userInfo}
                                    // setActionTutorialCompleted={this.props.setActionTutorialCompleted}
                                    actorVisitedApi={this.props.actorVisitedApi}
                                    dashboardContainerStyle={dashboardContainerStyle}
                                    getActorDetails={this.props.getActorDetails}
                                    stopActorsTutorial={this.props.stopActorsTutorial}
                                    setActorTutorialCompleted={this.props.setActorTutorialCompleted}
                                    showActionsUncoveredPage={this.props.showActionsUncoveredPage}
                                    resetActorTutorialCompleted={this.props.resetActorTutorialCompleted}
                                    startActorsTutorial={this.props.startActorsTutorial}
                                    hideActionsUncoveredPage={this.props.hideActionsUncoveredPage}
                                    setActorTutorialSteps={this.setActorTutorialSteps}
                                    introData={this.props.introData}
                                    overallMetrics={this.props.overallMetrics}
                                />
                                <SideBarListComponent
                                    uiState={this.props.uiState}
                                    userInfo={this.props.userInfo}
                                    onClickOfHistory={this.onClickOfHistory}
                                    onClickOfObjectives={this.onClickOfObjectives}
                                    onClickOfLeaderboard={this.onClickOfLeaderboard}
                                    onClickOfIntermediateReport={this.onClickOfIntermediateReport}
                                    setHighlightedSidebarComponent={this.props.setHighlightedSidebarComponent}
                                />
                            </div>
                    }
                    <Tutorial
                        {...this.props}
                        tutorial={this.props.tutorial}
                        pauseTutorial={this.props.pauseTutorial}
                        updateTutorialStepNumber={this.props.updateTutorialStepNumber}
                        endTutorial={this.props.endTutorial}
                    />
                </div>
                {this.props.uiState.showOverlay ? <Overlay {...this.props} /> : null}
            </Fragment>
        );
    }
}

DesktopDashboardContainer.propTypes = {};

DesktopDashboardContainer.defaultProps = {};

const mapStateToProps = state => {
    return state
};

const mapDispatchToProps = dispatch => ({
    setUiState: payload => {
        dispatch(setUiState(payload));
    },
    updateUISize: payload => {
        dispatch(updateUISize(payload));
    },
    addStepsToTutorial: (steps, type) => {
        dispatch(addStepsToTutorial(steps, type));
    },
    startReactJoyrideTutorial: () => {
        dispatch(startReactJoyrideTutorial())
    },
    pauseTutorial: () => {
        dispatch(pauseTutorial())
    },
    updateTutorialStepNumber: (payload) => {
        dispatch(updateTutorialStepNumber(payload))
    },
    endTutorial: () => {
        dispatch(endTutorial())
    },
    showActionsUncoveredPage: () => {
        dispatch(showActionsUncoveredPage())
    },
    hideActionsUncoveredPage: () => {
        dispatch(hideActionsUncoveredPage())
    },
    startActionsTutorial: () => {
        dispatch(startActionsTutorial())
    },
    stopActionsTutorial: () => {
        dispatch(stopActionsTutorial())
    },
    saveSelectedSubDomainId: (payload) => {
        dispatch(saveSelectedSubDomainId(payload))
    },
    saveSelectedCategoryId: (payload) => {
        dispatch(saveSelectedCategoryId(payload))
    },
    saveSelectedActionId: (payload) => {
        dispatch(saveSelectedActionId(payload))
    },
    saveSelectedActorId: (payload) => {
        dispatch(saveSelectedActorId(payload))
    },
    saveShowActorId: (payload) => {
        dispatch(saveShowActorId(payload))
    },
    setActionStepNumber: (payload) => {
        dispatch(setActionStepNumber(payload))
    },
    resetActionSequence: () => {
        dispatch(resetActionSequence())
    },
    actionTaken: (payload) => {
        dispatch(actionTaken(payload))
    },
    setActionTutorialCompleted: () => {
        dispatch(setActionTutorialCompleted())
    },
    startActorsTutorial: () => {
        dispatch(startActorsTutorial())
    },
    updateOverallMetricsForEvent: (payload) => {
        dispatch(updateOverallMetricsForEvent(payload))
    },
    actorVisitedApi: (payload) => {
        dispatch(actorVisitedApi(payload))
    },
    getActorDetails: (payload) => {
        dispatch(getActorDetails(payload))
    },
    setHighlightedSidebarComponent: (payload) => {
        dispatch(setHighlightedSidebarComponent(payload))
    },
    stopActorsTutorial: () => {
        dispatch(stopActorsTutorial())
    },
    setActorTutorialCompleted: () => {
        dispatch(setActorTutorialCompleted())
    },
    resetActorTutorialCompleted: () => {
        dispatch(resetActorTutorialCompleted())
    },
    saveSelectedCategoryNameForTutorial: (payload) => {
        dispatch(saveSelectedCategoryNameForTutorial(payload))
    },
    saveSelectedSubDomainForTutorial: (payload) => {
        dispatch(saveSelectedSubDomainForTutorial(payload))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopDashboardContainer);
