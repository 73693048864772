import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

	return StyleSheet.create({
		emailInputContainer: {
			background: skin.white,
			border: `1px solid ${skin.buttonColor1}`,
			borderRadius: '25px'
		},
		emailHeading: {
			...myTypography.body2,
			color: skin.white
		},
		emailInputElement: {
			...myTypography.body1,
			color: skin.black,
			'::placeholder': {
				color: hexToRgbA(skin.black, 0.2),
				...myTypography.body1
			},
			'::-ms-input-placeholder': {
				color: hexToRgbA(skin.black, 0.2),
				...myTypography.body1
			},
			':-ms-input-placeholder': {
				color: hexToRgbA(skin.black, 0.8),
				...myTypography.body1
			}
		},
		emailSendEnable: {
			cursor: 'pointer',
			opacity: 1
		},
		emailSendDisable: {
			cursor: 'not-allowed',
			opacity: 0.5
		},
		emailStatusText: {
			...myTypography.body2,
			color: skin.white,
			fontStyle: 'italic'
		}
	});
}

export default getSkin;