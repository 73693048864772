import React from 'react';
import styles from './introComponent.module.scss';
import applyWrappers from 'wrappers/ComponentWrapper';
import { Route } from 'react-router-dom'
import IntroVideoPage from './introVideoPage'
import ObjectivesAndInstructionsPage from './objectivesAndInstructionsPage'
import WelcomePage from './welcomePage'

const IntroComponent = (props) => {
    return (
        <div styleName="intro-component" style={props.introContainerStyle}>
            <Route exact path={'/intro'}>
                <IntroVideoPage {...props} />
            </Route>
            <Route exact path={'/intro/objectives'}>
                <ObjectivesAndInstructionsPage {...props} />
            </Route>
            <Route exact path={'/intro/welcome'}>
                <WelcomePage {...props} />
            </Route>
        </div>
    )
}

export default applyWrappers(IntroComponent, styles);
