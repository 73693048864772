import actionConsts from 'constants/actions';

const actionResponseReceived = (payload) => {
    return {
        type: actionConsts.ACTION_RESPONSE,
        payload
    };
};

export {
    actionResponseReceived
};
