import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';
import footer_image from 'images/footer.png';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        title: {
            fontFamily: "Nunito",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "27px",
            color: "#000000",
            opacity: 0.8
        },
        desc: {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            color: skin.black,
        },
        backgroundImage: {
			backgroundImage: `url(${footer_image})`,
            backgroundSize: '100% 100%',
            background: skin.white
        },
        box:{
            background: 'linear-gradient(171.46deg, #FB7286 2.67%, #F79F58 100%)'
        },
        boxTitle:{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: '18px',
            color: skin.white
        },
        feedbackTitle:{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: '12px',
            color: skin.white
        },
        feedbackBtn:{
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '16px',
            color: skin.black,
            background: skin.white,
            textTransform: 'uppercase'
        },
        downloadBtn:{
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '19px',
            textTransform: 'uppercase',
            color: skin.white,
            background: 'linear-gradient(144.43deg, #00D7FF 4.65%, #7DEEB9 97.99%)'
        },
        emailBtn:{
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            textTransform: 'uppercase',
            color: skin.black,
            background: skin.white
        },
        emailStatusText: {
			...myTypography.body2,
			color: skin.white,
			fontStyle: 'italic'
        },
        downloadStatusText:{
            ...myTypography.body2,
			color: skin.white,
			fontStyle: 'italic'
        }

    });
}

export default getSkin;
