import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        actorName: {
            ...myTypography.body1,
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '19px'
        },
        actorDesignation: {
            ...myTypography.body1,
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            color: skin.black
        },
        actorDesc: {
            ...myTypography.body1,
            fontSize: '12px',
            textTransform: 'none',
            color: skin.black,
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '21px'
        },
        actorGraphDesc: {
            ...myTypography.body1,
            fontSize: '12px',
            textTransform: 'none',
            color: skin.black,
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '21px'
        },
        aboutActor: {
            ...myTypography.body1,
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '19px',
            textTransform: 'none'
        },
        styleGraphDesc: {
            fontSize: '11px',
            lineHeight: '150%',
            opacity: '0.8'
        }
    });
}

export default getSkin;
