import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
    const skin = globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        personName: {
            ...myTypography.body1,
            fontStyle: 'italic',
            color: skin.grayColor4
        },

        delayedImpact: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '14px',
            lineHeight: '19px',
            color: skin.white,
        },

        seperator: {
            background: `linear-gradient(173.88deg, ${skin.primaryColor} 2.55%, ${skin.secondaryColor} 110.15%)`
        }
    });
}

export default getSkin;