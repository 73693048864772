import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        styleHeader: {
            ...myTypography.title,
            fontSize: '16px',
            textTransform: 'none',
            color: skin.black,
            fontFamily: 'Nunito',
            fontWeight: 600,
            lineHeight: '22px'
        },
        styleGraphDesc: {
          ...myTypography.body,
          fontSize: '12px',
          textTransform: 'none',
          color: skin.black,
          fontFamily: 'Open Sans',
          fontWeight: 'normal',
          lineHeight: '21px'
        }
    });
}

export default getSkin;
