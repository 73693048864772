import consts from 'constants/url';
import netpack from 'utils/netpack';
import { initializeGameData } from '../init';
import analyticsUtil from 'utils/segmentUtil';

export const fetchStorylineData = () => (dispatch) => netpack(consts.GAME_API)
    .get()
    .then(response => {
        //Segment integartion
        if(response && response.userInfo){
            analyticsUtil.identify(response.userInfo.uliId,response.userInfo);
            analyticsUtil.group(response.userInfo.groupId,response.userInfo);
        } 
        dispatch(initializeGameData(response))
    });
