import React from 'react';
import styles from './intermediateReport.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'images/action-response-close.png';
import LineGraph from './lineGraph'
import ActionsTaken from './actionsTaken'
import reportImage from 'images/report_heading.png';
import analyticsUtil from 'utils/segmentUtil';

const IntermediateReport = (props) => {
    const { intermediateReport, introData } = props;
    let quarters = [];
    const totalDays = introData['initialDuration'];
    const days = totalDays ? totalDays : 100;
    const targetRevenue = introData['targetRevenue'] ? introData['targetRevenue'] : 100;
    const happinessGraph = {
        title: `${props.getLabel("label_intermediate_report_happiness_trend_title")}`,
        desc: `${props.getLabel("label_intermediate_report_happiness_trend_desc")}`,
        axisLeft: `${props.getLabel("label_tooltip_happiness_title")}`
    }

    const revenueGraph = {
        title: `${props.getLabel("label_intermediate_report_revenue_trend_title")}`,
        desc: `${props.getLabel("label_intermediate_report_revenue_trend_desc")}`,
        axisLeft: `${props.getLabel("label_metric_revenue")}`
    }

    const closePopup = () => {
        //Segment integartion
        analyticsUtil.track("SIDEBAR_POPUP_CLOSE", { popup: "MONTHLY_REPORT_POPUP" });
        if (props.uiState.showMonthlyReportFlag) {
            props.setMonthlyReportSeenFlag({
                "month": props.uiState.currentReportMonth
            }).then(() => {
                if (!props.userInfo.gameOver.simEnd) {
                    props.setUiState({
                        showOverlay: false,
                        overlayComponentType: '',
                        overlayComponent: ''
                    });
                }
                props.setHighlightedSidebarComponent(-1)
            })
        } else {
            props.setUiState({
                showOverlay: false,
                overlayComponentType: '',
                overlayComponent: ''
            });
            props.setHighlightedSidebarComponent(-1)
        }
    }

    const renderQuarters = () => {
        let data = [];
        if (Object.keys(intermediateReport).length > 0) {
            let len = Object.keys(intermediateReport).length;
            for (let i = 0, j; i < len; i++) {
                const { actions, name } = intermediateReport[i];
                let quarter = {};
                quarter.index = i;
                j = i + 1
                quarter.name = props.getLabel(name);
                quarter.enable = actions.length > 0 ? true : false;
                quarter.keyName = "month" + j;
                quarters.push(quarter);
            }
            let item = quarters.map(function (quarter, index) {
                const { actions } = intermediateReport[index];
                quarter.enable = actions.length > 0 ? true : false;
                return renderEachQuarter(quarter, index)
            });
            data.push(item);
        }
        return data;
    }

    const onClickQuarter = (index) => {
        let num = index + 1
        props.onClickOfTab("month" + num)
    }

    const renderEachQuarter = ({ name, enable, keyName }, index) => {
        return (
            <div key={index} styleName="quarter" className={css(myStyles.name)}
                className={enable ? css(myStyles["enable"]) : css(myStyles["disable"])}
                onClick={() => onClickQuarter(index)}>
                {name}
                {props.showDataForMonth == keyName ? <div className={css(myStyles.line)} styleName="line" /> : ""}
            </div>)
    }

    const renderData = (showDataForMonth = "month1") => {
        if (Object.keys(intermediateReport).length > 0) {
            let index = getIndexOfMonthSelected(showDataForMonth);
            if (index != null) {
                const { happiness, revenue, actions } = intermediateReport[index];
                return renderEachData(happiness, revenue, actions);
            }
        }
    }

    const getIndexOfMonthSelected = (name) => {
        let index = null;
        quarters.forEach((quarter) => {
            if (quarter.keyName === name) {
                index = quarter.index;
            }
        });
        return index;
    }

    const renderEachData = (happiness, revenue, actions) => {
        return (
            <div>
                <div className={css(myStyles.report)}>
                    <div styleName="lineGraph">
                        <LineGraph
                            isHappinessTrend={true}
                            {...happinessGraph}
                            graphData={happiness}
                            xaxis={days}
                            yaxis={100}
                            gridYValues={[0, 20, 40, 60, 80, 100]}
                            legendOffset={-40}
                            yFormat={function (value) {
                                return value.toFixed(1) + "%";
                            }}
                            hoverText={props.getLabel('label_tooltip_happiness_title')}
                        />
                        <LineGraph
                            isHappinessTrend={false}
                            {...revenueGraph}
                            graphData={revenue}
                            xaxis={days}
                            // yaxis={700000}
                            // gridYValues={[0, 100000, 200000, 300000, 400000, 500000, 600000, 700000]}
                            legendOffset={-50}
                            yFormat={function (value) {
                                return '$' + value.toLocaleString('en-US');
                            }}
                            hoverText={props.getLabel('label_metric_revenue')}
                        />
                    </div>
                </div>
                <div className={css(myStyles.action)} styleName="action">
                    <ActionsTaken {...props} actions={actions} />
                </div>
            </div>
        );
    }

    const myStyles = getSkin(props.skinGuide);

    return (
        <div className={css(myStyles.container)} styleName="container">
            <div className={css(myStyles.head)} styleName="head">
                <div className={css(myStyles.title)} styleName="title">
                    <img styleName="heading-icon" src={reportImage} />
                    {props.getLabel("label_intermediate_report_heading")}
                </div>
                <div styleName="close-icon" onClick={closePopup}>
                    <img src={Close} />
                </div>
            </div>
            <div className={css(myStyles.quarters)} styleName="quarters">
                {renderQuarters()}
            </div>
            <div styleName="scrollbar">
                {renderData(props.showDataForMonth)}
            </div>
        </div>
    );
}

export default applyWrappers(IntermediateReport, styles);
