import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            ...myTypography.title,
            fontSize: '13px',
            color: hexToRgbA(skin.black, 0.5),
        },
        dropDownMenu: {

        }
    });
}

export default getSkin;
