import React from 'react';
import { connect } from 'react-redux';
import ActorDetail from '../components/actorDetail';
import { setUiState, toggleInitialBambaScale } from 'actions/uiState/actionCreators';
import { stopActorsTutorial, showActionsUncoveredPage } from 'actions/tutorial';
import { setActorTutorialCompleted } from 'actions/apiAction/userApis'
import { saveSelectedActorId } from 'actions/actionSequence'
class ActorDetailContainer extends React.Component {
    render() {
        return (
            <ActorDetail
                {...this.props}
                stopActorsTutorial={this.props.stopActorsTutorial}
                setActorTutorialCompleted={this.props.setActorTutorialCompleted}
                saveSelectedActorId={this.props.saveSelectedActorId}
            />
        );
    }
}

ActorDetailContainer.propTypes = {};

ActorDetailContainer.defaultProps = {};

const mapStateToProps = state => {
    return {
        uiState: state.uiState,
        tutorial: state.tutorial,
        saveShowActorId: state.actionSequence.saveShowActorId,
        actors: state.actors,
        introData: state.introData,
        categories: state.categories,
        currentActorGraphDetails: state.currentActorGraphDetails,
        domains: state.domains,
        actionSequence: state.actionSequence,
    };
};

const mapDispatchToProps = dispatch => ({
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    stopActorsTutorial: () => {
        dispatch(stopActorsTutorial())
    },
    showActionsUncoveredPage: () => {
        dispatch(showActionsUncoveredPage())
    },
    setActorTutorialCompleted: () => {
        dispatch(setActorTutorialCompleted())
    },
    toggleInitialBambaScale: () => {
        dispatch(toggleInitialBambaScale())
    },
    saveSelectedActorId: (payload) => {
        dispatch(saveSelectedActorId(payload))
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActorDetailContainer);