import React from 'react'
import timerStyles from 'components/header/timerComponent/timerComponent.module.scss'
import desktopStyles from 'components/header/desktopHeader/desktopHeader.module.scss'
import categoryStyles from 'components/dashboardComponent/actionsComponent/categoriesListComponent/categoriesListComponent.module.scss'
import subdomainStyles from 'components/dashboardComponent/actionsComponent/subDomainsListComponent/subDomainsListComponent.module.scss'
import actionsListComponent from 'components/dashboardComponent/actionsComponent/actionsListComponent/actionsListComponent.module.scss'
import IndividualIcon from 'images/individual-icon.svg'
import OrgIcon from 'images/org-icon.svg'
import CalendarIcon from 'images/calendar-icon.svg'
import BudgetIcon from 'images/budget-icon.svg'
import BulletIcon from 'images/bullet-icon.svg'
import './tutorialSequence.scss'

const headerSteps = (updateTutorialStepNumber, pauseTutorial, startActorsTutorial, labelValues) => {
    return [
        {
            target: "." + timerStyles["outside-container"], //get first mail
            content: (
                <div className='step-1-container'>
                    <div className='step-1-container-text-box'>
                        <div
                            className='step-1-container-header'
                        >{labelValues['label_tutorial_timer_title']}</div>
                        <div className='step-1-container-text'>
                            {labelValues['label_tutorial_timer_desc']}
                        </div>
                        <div className='step-1-container-buttons'>
                            <div className='button next-button' onClick={() => updateTutorialStepNumber(1)}>
                                {labelValues['label_tutorial_next_button']}
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableAnimation: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightPadding: 5,
            placement: "bottom",
            styles: {
                options: {
                    zIndex: 100
                }
            }
        },
        {
            target: "." + desktopStyles["cost-metrics"], //get first mail
            content: (
                <div className='step-1-container second-header-step'>
                    <div className='step-1-container-text-box'>
                        <div
                            className='step-1-container-header'
                        >{labelValues['label_tutorial_day_budget_title']}</div>
                        <div className='step-1-container-text' dangerouslySetInnerHTML={{ __html: labelValues['label_tutorial_day_budget_desc'] }} />
                        <div className='step-1-container-buttons'>
                            <div className='button previous-button' onClick={() => updateTutorialStepNumber(0)}>
                                {labelValues['label_tutorial_previous_button']}
                            </div>
                            <div className='button next-button next-btn-margin' onClick={() => updateTutorialStepNumber(2)}>
                                {labelValues['label_tutorial_next_button']}
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableAnimation: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightPadding: 0,
            placement: "bottom",
            styles: {
                options: {
                    zIndex: 100
                }
            }
        },
        {
            target: "#tutorial-step-3", //get first mail
            content: (
                <div className='step-1-container third-header-step'>
                    <div className='step-1-container-text-box'>
                        <div
                            className='step-1-container-header'
                        >{labelValues['label_tutorial_metrics_title']}</div>
                        <div className='step-1-container-text'
                            dangerouslySetInnerHTML={{ __html: labelValues['label_tutorial_metrics_desc'] }}
                        />

                        <div className='step-1-container-buttons'>
                            <div className='button previous-button' onClick={() => updateTutorialStepNumber(1)}>
                                {labelValues['label_tutorial_previous_button']}
                            </div>
                            <div
                                className='button next-button next-btn-margin'
                                onClick={() => { updateTutorialStepNumber(3) }}>
                                {labelValues['label_tutorial_next_button']}
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableAnimation: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightPadding: 0,
            placement: "bottom",
            styles: {
                options: {
                    zIndex: 100
                }
            }
        },
        {
            target: "#tutorial-step-4", //get first mail
            content: (
                <div className='step-1-container fourth-header-step'>
                    <div className='step-1-container-text-box'>
                        <div className='step-1-container-header'
                        >{labelValues['label_tutorial_revenue_title']}</div>
                        <div className='step-1-container-text'
                            dangerouslySetInnerHTML={{ __html: labelValues['label_tutorial_revenue_desc'] }}
                        />
                        <div className='step-1-container-buttons'>
                            <div className='button previous-button' onClick={() => updateTutorialStepNumber(2)}>
                                {labelValues['label_tutorial_previous_button']}
                            </div>
                            <div
                                className='button next-button next-btn-margin'
                                onClick={() => { updateTutorialStepNumber(4) }}>
                                {labelValues['label_tutorial_next_button']}
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableAnimation: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightPadding: 0,
            placement: "bottom",
            styles: {
                options: {
                    zIndex: 100
                }
            }
        },
        {
            target: "#sidebarcomponent", //get first mail
            content: (
                <div className='step-1-container fifth-header-step'>
                    <div className='step-1-container-text-box fifth-header-box'>
                        <div
                            className='step-1-container-header'
                        >{labelValues['label_tutorial_sidebar_title']}</div>
                        <div className='step-1-container-text'

                        >
                        </div>
                        <div className='step-1-container-text'
                            dangerouslySetInnerHTML={{ __html: labelValues['label_tutorial_sidebar_desc'] }}></div>
                        <div className='step-1-container-text'><img src={BulletIcon} /> {labelValues['label_tutorial_sidebar_point_1']}</div>
                        <div className='step-1-container-text'><img src={BulletIcon} /> {labelValues['label_tutorial_sidebar_point_2']}</div>
                        <div className='step-1-container-text'><img src={BulletIcon} /> {labelValues['label_tutorial_sidebar_point_3']}</div>
                        <div className='step-1-container-text'><img src={BulletIcon} /> {labelValues['label_tutorial_sidebar_point_4']}</div>

                        <div className='step-1-container-buttons'>
                            <div className='button previous-button' onClick={() => updateTutorialStepNumber(3)}>
                                {labelValues['label_tutorial_previous_button']}
                            </div>
                            <div
                                className='button next-button next-btn-margin'
                                onClick={() => { pauseTutorial(); updateTutorialStepNumber(0); startActorsTutorial() }}>
                                {labelValues['label_tutorial_next_button']}
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableAnimation: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightPadding: 0,
            placement: "right",
            styles: {
                options: {
                    zIndex: 100
                }
            }
        }
    ]
}

const actionSteps = (goToActorTutorialCompletedScreen, updateTutorialStepNumber, saveSelectedCategoryNameForTutorial, saveSelectedSubDomainForTutorial, setActionTutorialCompleted, labelValues) => {
    return [
        {
            target: "." + categoryStyles["container"], //get first mail
            content: (
                <div className='step-1-container action-step-1'>
                    <div className='step-1-container-text-box'>
                        <div
                            className='step-1-container-header'
                        >{labelValues['label_tutorial_select_category_title']}</div>
                        <div className='step-1-container-text'>
                            {labelValues['label_tutorial_select_category_desc']}
                        </div>
                        <div className='step-1-container-buttons'>
                            <div className='button previous-button' onClick={() => { goToActorTutorialCompletedScreen() }}>
                                {labelValues['label_tutorial_previous_button']}
                            </div>
                            <div className='button next-button next-btn-margin' onClick={() => { updateTutorialStepNumber(1); saveSelectedCategoryNameForTutorial(52) }}>
                                {labelValues['label_tutorial_next_button']}
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableAnimation: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightPadding: 0,
            placement: "bottom",
            styles: {
                options: {
                    zIndex: 100
                }
            }
        },
        {
            target: "." + subdomainStyles["container"], //get first mail
            content: (
                <div className='step-1-container action-step-2'>
                    <div className='step-1-container-text-box'>
                        <div
                            className='step-1-container-header'
                        >{labelValues['label_tutorial_subdomain_title']}</div>
                        <div className='step-1-container-text'>
                            {labelValues['label_tutorial_subdomain_desc']}
                        </div>
                        <div className='step-1-container-buttons'>
                            <div className='button previous-button' onClick={() => { updateTutorialStepNumber(0); saveSelectedCategoryNameForTutorial(-1) }}>
                                {labelValues['label_tutorial_previous_button']}
                            </div>
                            <div
                                className='button next-button next-btn-margin'
                                onClick={() => { updateTutorialStepNumber(2); saveSelectedSubDomainForTutorial(1) }}
                            >
                                {labelValues['label_tutorial_next_button']}
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableAnimation: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightPadding: 0,
            placement: "bottom",
            styles: {
                options: {
                    zIndex: 100
                }
            }
        },
        {
            target: "." + actionsListComponent["actions-container"], //get first mail
            content: (
                <div className='step-1-container action-step-3'>
                    <div className='step-1-container-text-box'>
                        <div
                            className='step-1-container-header'
                        >{labelValues['label_tutorial_action_card_title']}</div>
                        <div
                            className='step-1-container-text'
                            dangerouslySetInnerHTML={{ __html: labelValues['label_tutorial_action_card_desc'] }}
                        />
                        <div className='step-1-container-img'><img src={CalendarIcon} />{labelValues['label_tutorial_days_per_action']}</div>
                        <div className='step-1-container-img'><img src={BudgetIcon} />{labelValues['label_tutorial_budget_per_action']}</div>
                        <div className='step-1-container-img'><img src={IndividualIcon} />{labelValues['label_tutorial_individual_action']}</div>
                        <div className='step-1-container-img'><img src={OrgIcon} />{labelValues['label_tutorial_org_action']}</div>

                        <div className='step-1-container-buttons'>
                            <div className='button previous-button' onClick={() => { updateTutorialStepNumber(1); saveSelectedSubDomainForTutorial(-1) }}>
                                {labelValues['label_tutorial_previous_button']}
                            </div>
                            <div
                                className='button next-button next-btn-margin'
                                onClick={() => { setActionTutorialCompleted() }}
                            >
                                {labelValues['label_tutorial_finish_button']}
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableAnimation: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightPadding: 0,
            placement: "left",
            styles: {
                options: {
                    zIndex: 100
                }
            }
        }
    ]
}

export default { headerSteps, actionSteps }