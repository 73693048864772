import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        title: {
            ...myTypography.subtitle,
            fontSize: '14px',
            textTransform: 'uppercase',
            color: skin.black,
            opacity: 0.8
        }
    });
}

export default getSkin;
