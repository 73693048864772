import React from 'react';
import styles from './progressBarComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import HappyProgressBarIcon from 'images/happy-progress-bar-icon.svg';
import SadProgressBarIcon from 'images/sad-progress-bar-icon.svg';
import NeutralProgressBarIcon from 'images/neutral-progress-bar-icon.svg';

const ProgressBarComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);
    const skin = props.skinGuide.globalProfiles.palette

    const breakPoint = props.value
    let ProgressBarIcon, backgroundImageGradient;
    if (props.metricsType == "Revenue") {
        ProgressBarIcon = breakPoint >= 750000 ? HappyProgressBarIcon : SadProgressBarIcon
        backgroundImageGradient = breakPoint >= 750000 ?
            `linear-gradient(90deg, ${skin.progressBarPrimaryColor} ${breakPoint - 8}%, ${skin.progressBarSecondaryColor} ${breakPoint}%, ${skin.progressBarBackground} ${breakPoint - -13}%)` :
            `linear-gradient(90deg, #EF5F5F 45.31%, #FFB6B6 52.6%, #F1F1F1 59.9%)`
    } else {
        ProgressBarIcon = breakPoint > 71 ? HappyProgressBarIcon : SadProgressBarIcon
        backgroundImageGradient = breakPoint >= 71 ?
            `linear-gradient(90deg, ${skin.progressBarPrimaryColor} ${breakPoint - 8}%, ${skin.progressBarSecondaryColor} ${breakPoint}%, ${skin.progressBarBackground} ${breakPoint - -13}%)` :
            `linear-gradient(90deg, #EF5F5F 45.31%, #FFB6B6 52.6%, ${skin.progressBarBackground} 59.9%)`
        if (props.happinessBar)
            backgroundImageGradient = `linear-gradient(90deg, ${skin.progressBarPrimaryColor} ${breakPoint - 8}%, ${skin.progressBarSecondaryColor} ${breakPoint}%, ${skin.progressBarBackground} ${breakPoint - -13}%)`
    }

    if (props.isEndScreen) {
        backgroundImageGradient = `linear-gradient(90deg, ${skin.progressBarPrimaryColor} ${breakPoint - 8}%, ${skin.progressBarSecondaryColor} ${breakPoint}%, ${skin.progressBarBackground} ${breakPoint - -13}%)`
    }

    const BarStyle = props.isBarRounded ? {
        borderRadius: '10px',
        backgroundImage: backgroundImageGradient
    } : {
            backgroundImage: backgroundImageGradient
        }

    return (
        <div styleName='container'>
            <div
                styleName='progress'
                style={BarStyle}
            />
            <div styleName='progress-bar-icon'>
                <img src={ProgressBarIcon} />
            </div>
            {
                !props.hideValue &&
                <div styleName='progress-bar-value' className={css(myStyles.progressValue)}>
                    {(+props.value).toFixed(1)}%
                </div>
            }
        </div>
    )
}

export default applyWrappers(ProgressBarComponent, styles);
