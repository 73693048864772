import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import WelcomeBgIllustration from 'images/welcome-rockstar-bg.svg'

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            backgroundImage: `url(${WelcomeBgIllustration})`,
            backgroundSize: 'cover'
        },
        title: {
            ...myTypography.subtitle,
            fontSize: '24px',
            lineHeight: '33px',
            fontWeight: '600',
            opacity: '0.8',
            color: skin.black
        },
        text: {
            ...myTypography.body1,
            color: skin.black
        },
        helperText: {
            ...myTypography.body2,
            textAlign: 'center',
            color: skin.black,
            opacity: 0.8
        }
    });
}

export default getSkin;
