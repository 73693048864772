import actionConsts from 'constants/actions';


const initialState = [];

// The ids will be -1 if the corresponding field is not selected

const actors = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_GAME_DATA:
            return action.payload.actors;

        case actionConsts.SET_ACTOR_VISITED_FLAG:
            let prevState = { ...state }
            prevState["actorList"][action.payload].isSeen = true
            return prevState

        case actionConsts.EVENT_RESPONSE:
        case actionConsts.UPDATE_EVENT_METRICS:
        case actionConsts.ACTION_RESPONSE:
            let newState = { ...state };
            for (let actorId in action.payload["metrics"]["actorsCurrentHappiness"]) {
                newState["actorList"][actorId]["happiness"] = action.payload["metrics"]["actorsCurrentHappiness"][actorId]["happiness"];
                // let metricValueObjForActor = {
                //     "days": action.payload["dayTo"],
                //     "happiness": action.payload["metrics"]["actorsCurrentHappiness"][actorId]["happiness"]
                // };
                // newState["actorList"][actorId]["metricsValues"].push(metricValueObjForActor);
            }
            return newState;

        default:
            return state;
    }
};

export default actors;
