import React from 'react';
import { connect } from 'react-redux';
import ActionEventPopup from 'components/event/actionEventPopup';
import { setUiState } from 'actions/uiState/actionCreators';
import { actionEventCompleted } from 'actions/apiAction/eventApis'
import { resetEventResponse, setNextEvent } from 'actions/event'

class ActionEventContainer extends React.Component {

    render() {
        return (
            <ActionEventPopup {...this.props} />
        );
    }
}

ActionEventContainer.propTypes = {};

ActionEventContainer.defaultProps = {};

const mapStateToProps = state => {
    return {
        uiState: state.uiState,
        userEvent: state.userEvent,
        userEventResponse: state.userEventResponse,
        introData: state.introData,
        overallMetrics: state.overallMetrics
    };
};

const mapDispatchToProps = dispatch => ({
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    actionEventCompleted: (payload) => {
        dispatch(actionEventCompleted(payload))
    },
    resetEventResponse: () => {
        dispatch(resetEventResponse())
    },
    setNextEvent: (payload) => {
        dispatch(setNextEvent(payload))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionEventContainer);
