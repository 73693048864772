import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
    const skin = globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        headerContainer: {
            background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`
        },
        leaderboardPopupContainer: {
            overflow: 'hidden',
            borderRadius: '8px'
        },
        title: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '25px',
            color: skin.white,
        },
        headerTitle: {
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontWeight: 600,
            fontStyle: 'normal',
            textTransform: 'uppercase',
            //background: `linear-gradient(171.46deg, ${skin.primaryColor} 2.67%, ${skin.secondaryColor} 100%)`
        },
        content: {
            background: `linear-gradient(116.58deg, ${skin.primaryPopupBgColor} 4.65%, ${skin.secondaryPopupBgColor} 50.35%, ${skin.tertiaryPopupBgColor} 97.99%)`
        },
        value: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            color: skin.black
        },
        currentUserValue: {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontSize: '16px',
            fontWeight: 600,
            color: skin.black
        },
        seperationLine: {
            background: hexToRgbA('#407EA6', 0.1)
        },
        currentUser: {
            background: `linear-gradient(102.4deg, rgba(251, 114, 134, 0.2) 9.01%, rgba(247, 159, 88, 0.04) 90.99%)`
        },

        buttonText: {
            textTransform: 'uppercase',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '12px',
            border: `1.5px solid ${skin.buttonColor1}`,
            //background: `linear-gradient(102.64deg, ${skin.buttonColor1} 4.65%, ${skin.buttonColor2} 97.99%)`
        }
    });
}

export default getSkin;