import React from 'react';
import styles from './categoriesListComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import categoryImage from 'images/happiness-icon-neutral.svg';
import actionArrow from 'images/action-arrow.svg'
import { hexToRgbA } from 'utils/styleUtil'

const CategoriesListComponent = (props) => {
    const myStyles = getSkin(props.skinGuide)
    const skin = props.skinGuide.globalProfiles.palette

    let { isReactJoyrideRunning, isActionsTutorialRunning, type, stepIndex } = props.tutorial
    let { actionStepNumber, selectedCategoryId } = props.actionSequence

    const onCategoryClick = (id) => {
        // Save selected category and go to next step
        props.resetActionSequence()
        props.saveSelectedCategoryId(id)
        props.setActionStepNumber(1)
    }

    let selectedCategoryTextStyle = (id) => {
        if (id === selectedCategoryId) {
            return {
                color: skin.primaryColor,
                fontWeight: 800
            }
        } else {
            return {}
        }
    }

    const getCategories = () => {
        let categoryDivArray = []
        let { categoriesOrder, categoryList } = props.categories

        categoriesOrder && categoriesOrder.map(item => {
            categoryDivArray.push(
                <div
                    key={categoryList[item].id}
                    styleName='category-container'
                    onClick={() => onCategoryClick(categoryList[item].id)}
                >
                    <div styleName='category-image'>
                        <img src={categoryList[item].imagePath} />
                    </div>
                    <div
                        styleName='category-name'
                        className={css(myStyles.name)}
                        style={selectedCategoryTextStyle(categoryList[item].id)}
                    >
                        {props.getLabel(categoryList[item].name)}
                    </div>
                </div>
            )
        })

        return categoryDivArray
    }

    //If action tutorial step 2 is ongoing, hide the component
    let tutorialStyle = (isReactJoyrideRunning && type === 'actions' && stepIndex === 1) ?
        { zIndex: 1 } :
        {}

    //Variable to store whether the component is highlighted, in tutorial step 1 or action sequence step 1
    let highlightedComponentStyle = (isReactJoyrideRunning && type === 'actions' && stepIndex === 0)
        || (!isReactJoyrideRunning && !isActionsTutorialRunning && actionStepNumber === 0) ?
        { boxShadow: `0px 0px 24px ${hexToRgbA(skin.primaryColor, 0.85)}` } :
        {}

    let componentStyle = { ...tutorialStyle, ...highlightedComponentStyle }

    return (
        <div
            styleName="container"
            className={css(myStyles.container)}
            style={componentStyle}
        >
            {getCategories()}
            {
                // If action sequence step 1 is ongoing, show helper text
                (!isReactJoyrideRunning && !isActionsTutorialRunning && actionStepNumber === 0) &&
                <div styleName='helper-textbox'>
                    <div styleName='arrow'>
                        <img src={actionArrow} />
                    </div>
                    <div styleName='text' className={css(myStyles.text)}>{props.getLabel('label_dashboard_select_category_text')}</div>
                </div>
            }
        </div>
    )
}

export default applyWrappers(CategoriesListComponent, styles);
