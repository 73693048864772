import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            border: `1px solid ${hexToRgbA(skin.primaryColor, 0.5)}`,
            background: skin.white
        },
        name: {
            ...myTypography.body1,
            fontSize: '11px',
            fontWeight: '300',
            textTransform: 'uppercase',
            color: skin.black
        },
        text: {
            ...myTypography.subTitle,
            fontSize: '14px',
            fontWeight: '600',
            textTransform: 'uppercase',
            color: skin.black
        }
    });
}

export default getSkin;
