import React, { Fragment } from 'react';
import styles from './leaderboardPopup.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'images/action-response-close.png';
import leaderboardImage from 'images/leaderboard_heading.png';
import LoadingPage from 'components/commonComponents/loadingPage';
import analyticsUtil from 'utils/segmentUtil';

const LeaderboardPopup = (props) => {
    const { leaderboard } = props;
    const closePopup = () => {
        //Segment integartion
        analyticsUtil.track("SIDEBAR_POPUP_CLOSE", { popup: "LEADERBOARD_POPUP" });
        props.setUiState({
            showOverlay: false,
            overlayComponentType: '',
            overlayComponent: ''
        });
        props.setHighlightedSidebarComponent(-1)
    }

    let refreshButtonText = 'refresh';
    let disableButton = false;

    if (props.leaderboard.isLeaderboardFetching) {
        refreshButtonText = 'refreshing';
        disableButton = true;
    }

    const myStyles = getSkin(props.skinGuide);

    const renderhistory = () => {
        let data = [];
        leaderboard.usersList.forEach((ele, i) => {
            data.push(renderTableBody(ele, i));
        })

        return data;
    }

    const renderTableBody = (record, index) => {
        const { firstName, lastName, emailId, isSelf, status } = record;
        const name = isSelf ? "You" : (firstName ?
            (lastName ? firstName + " " + lastName : firstName) : emailId);
        const daysRemaining = record.daysRemaining < 0 ? '0 ' + props.getLabel("label_history_day") : record.daysRemaining + " " + props.getLabel("label_tooltip_days_title");
        const happinessValue = record.happiness;
        const revenueValue = "$" + Math.round(record.revenue).toLocaleString('en-US');
        let rowClass = '';
        let cellClass = css(myStyles.value);
        if (isSelf) {
            rowClass = css(myStyles.currentUser);
            cellClass = css(myStyles.currentUserValue);
        }

        return (
            <div key={index}>
                <div className={rowClass} styleName="table-row">
                    <div className={cellClass} styleName="position position-body">{record.rank}</div>
                    <div className={cellClass} styleName="user">
                        {name}
                    </div>
                    <div className={cellClass} styleName="revenue">{`${revenueValue}`}</div>
                    <div className={cellClass} styleName="happiness">{`${happinessValue.toFixed(1)}%`}</div>
                    <div className={cellClass} styleName="days-remaining">{daysRemaining}</div>
                    <div className={cellClass} styleName="days-remaining">{props.getLabel("label_" + status.toLowerCase().replace(" ", "_"))}</div>
                </div>
                <div className={css(myStyles.seperationLine)} styleName="seperation-line"></div>
            </div>
        );
    };

    return (
        <div styleName="leaderboard-popup-container" className={css(myStyles.leaderboardPopupContainer)}>
            <div style={{ width: '100%', height: '486px', marginTop: '25px' }}>
                <div className={css(myStyles.headerContainer)} styleName="header-container">
                    <img styleName="heading-icon" src={leaderboardImage} />
                    <div className={css(myStyles.title)}>
                        {props.getLabel('label_leaderboard_title')}
                    </div>
                </div>
                <div className={css(myStyles.content)} styleName="content">
                    <div styleName="buttonContainer">
                        <div
                            styleName="button"
                            className={css(myStyles.buttonText)}
                            style={props.leaderboard.isLeaderboardFetching ? { pointerEvents: 'none', width: '120px' } : {}}
                            onClick={() => { !props.leaderboard.isLeaderboardFetching && props.fetchLeaderboardData() }}
                        >
                            {props.leaderboard.isLeaderboardFetching ? props.getLabel('label_leaderboard_refreshing') : props.getLabel('label_leaderboard_refresh')}
                        </div>
                    </div>
                    {
                        props.leaderboard.isLeaderboardFetching ?
                            <LoadingPage />
                            :
                            <div styleName="table-container">
                                <div styleName="table-header-container">
                                    <div className={css(myStyles.headerTitle)} styleName="headerColor position position-header">{props.getLabel('label_leaderboard_rank')}</div>
                                    <div className={css(myStyles.headerTitle)} styleName="headerColor username">{props.getLabel('label_leaderboard_username')}</div>
                                    <div className={css(myStyles.headerTitle)} styleName="headerColor revenue">{props.getLabel("label_metric_revenue")}</div>
                                    <div className={css(myStyles.headerTitle)} styleName="headerColor happiness">{props.getLabel("label_metric_happiness")}</div>
                                    <div className={css(myStyles.headerTitle)} styleName="headerColor days-remaining">{props.getLabel('label_history_days_remaining')}</div>
                                    <div className={css(myStyles.headerTitle)} styleName="headerColor days-remaining">{props.getLabel('label_status')}</div>
                                </div>
                                <div styleName="table-body-container">
                                    {renderhistory()}
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div styleName="close-icon" onClick={closePopup}>
                <img src={Close} />
            </div>
        </div>
    );
}

export default applyWrappers(LeaderboardPopup, styles);
