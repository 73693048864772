import React, { Fragment } from 'react';
import styles from './actorCardComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ProgressBar from 'components/commonComponents/progressBarComponent'
import positiveIcon from 'images/happiness-icon-positive.svg'
import neutralIcon from 'images/happiness-icon-neutral.svg'
import negativeIcon from 'images/happiness-icon-negative.svg'
import lockedIcon from 'images/actor-info-icon.svg'
import selectedCardIcon from 'images/card-selected.svg'

// import actor_pic from 'images/Actor_Pic.svg'

const ActorCardComponent = (props) => {
    const myStyles = getSkin(props.skinGuide)
    const skin = props.skinGuide.globalProfiles.palette

    let selectedCardStyle = {
        backgroundImage: `linear-gradient(144.43deg, ${skin.buttonColor1} 4.65%, ${skin.buttonColor2} 97.99%)`
    }

    let normalCardStyle = {
        background: 'transparent'
    }

    let emoticonUrl = ''
    const { id, happiness, isActorSelectionScreenOpen, isCurrentActorSelected } = props;
    const value = happiness.toFixed(1);
    const { name, role, isSeen } = props;
    if (value) {
        if (value < 33) emoticonUrl = negativeIcon
        else if (value < 66) emoticonUrl = neutralIcon
        else if (value <= 100) emoticonUrl = positiveIcon
    }

    let { actionSequence, actionList } = props
    let currentAction, isActorDisabled = false
    const currentDay = props.introData["initialDuration"] - props.overallMetrics["duration"]["metricValue"];
    let disabledCardText = ''

    if (actionSequence.actionStepNumber >= 2 && actionSequence.selectedActionId > -1) {
        currentAction = actionList[actionSequence.selectedActionId]

        if (typeof currentAction.currentAttempt === 'object' && currentAction.currentAttempt[id].attmptNo >= currentAction.maxAttempts) {
            // If actor card is isActorDisabled, add opacity to the elements of the card
            isActorDisabled = true
            disabledCardText = props.getLabel('label_actor_max_attempts_reached')
        }

        if (!isActorDisabled && typeof currentAction.lastTimeTaken === 'object' && currentAction.lastTimeTaken !== null) {
            if (currentAction.lastTimeTaken[id].lastTimeTaken !== null) {
                // If max attempts not reached, card is org-wide but action cooldown time present disable the card
                isActorDisabled = (currentAction.lastTimeTaken[id].lastTimeTaken + currentAction.minTimeForAttempt) > currentDay
                disabledCardText = `${props.getLabel("label_actor_max_days_reached")} ${currentAction.lastTimeTaken[id].lastTimeTaken + currentAction.minTimeForAttempt - currentDay} ${props.getLabel("label_days")}`
            }
        }
    }

    let isTutorialRunning;
    let { isActorsTutorialRunning, isActionsTutorialRunning, isActionsUncoveredPageShown } = props.tutorial
    isTutorialRunning = isActorsTutorialRunning || isActionsTutorialRunning || isActionsUncoveredPageShown

    const onViewProfileButtonClick = (e, id) => {
        e.stopPropagation()
        props.onActorCardClick(id)
    }

    return (
        <div
            styleName='container'
            style={actionSequence.actionStepNumber > 3 && isCurrentActorSelected ? selectedCardStyle : normalCardStyle}
        >
            <div
                styleName='card-container'
                className={css(myStyles.container)}
                style={actionSequence.actionStepNumber > 3 && !isActorDisabled ? { cursor: 'pointer' } : {}}
                onClick={(e) => !isActorDisabled && props.onActorSelect(e, id)}
            >
                {
                    isActorSelectionScreenOpen &&
                    <div styleName='radio-btn-selection-area' >
                        {
                            !isCurrentActorSelected ?
                                <div
                                    styleName='select-icon'
                                    className={css(myStyles.selectIcon)}
                                />
                                :
                                <img style={{ width: '25px', height: '25px' }} src={selectedCardIcon} />
                        }
                    </div>
                }
                <div styleName='image-container' style={isActorDisabled ? { opacity: '0.5' } : {}}>
                    <img styleName='actor-image' src={props.actor_image} />
                </div>
                <div styleName='name' className={css(myStyles.name)} style={isActorDisabled ? { opacity: '0.5' } : {}}>{props.getLabel(name)}</div>
                {
                    isActorDisabled ?
                        <div styleName='actor-disabled' className={css(myStyles.actorDisabled)}>
                            {disabledCardText}
                        </div>
                        :
                        <Fragment>
                            <div styleName='designation' className={css(myStyles.designation)}>{props.getLabel(role)}</div>
                            <div
                                className='button previous-button'
                                styleName='view-profile-button'
                                style={isTutorialRunning ? { cursor: 'not-allowed', pointerEvents: 'none' } : {}}
                                onClick={(e) => onViewProfileButtonClick(e, id)}
                            >
                                {props.getLabel("label_view_profile")}
                            </div>
                            {
                                isSeen ?
                                    null :
                                    < img styleName='locked-icon' src={lockedIcon} />
                            }
                        </Fragment>
                }
                <div styleName='progress-bar' style={isActorDisabled ? { opacity: '0.5' } : {}}>
                    <ProgressBar value={value} />
                </div>
            </div>
        </div>
    )
}

export default applyWrappers(ActorCardComponent, styles);
