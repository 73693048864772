import actionConsts from 'constants/actions';


const initialState = {};

// The ids will be -1 if the corresponding field is not selected

const currentActorGraphDetails = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.SET_ACTOR_GRAPH_DETAILS:
            return action.payload;

        default:
            return state;
    }
};

export default currentActorGraphDetails;
