import consts from 'constants/url';
import actionConsts from 'constants/actions';
import netpack from 'utils/netpack'

export const fetchLabelsData = () => (dispatch) => netpack(consts.LABELS_API)
.get()
    .then((response) => {
        dispatch({
            type: actionConsts.INIT_LABELS_DATA,
            payload: response
        })
    });
