import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        title: {
            fontFamily: 'Open Sans',
            fontWeight: 'bold',
            fontSize: '16px',
            textAlign: 'center',
            color: '#031A20',
            opacity: '0.8',
        },
        text: {
            fontSize: '12px',
            textAlign: 'center',
            color: '#031A20',
            opacity: '0.6',
        },
        hrline: {
            border: '1px solid #F8985F'
        },
        buttonText: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '14px',
            lineHeight: '19px',
            textTransform: 'uppercase',
            borderRadius: '18px',
            border: `1px solid ${skin.buttonColor1}`
        }
    });
}

export default getSkin;
