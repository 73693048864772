import React, { Fragment } from 'react';
import styles from './actionsListComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ActionCardComponent from './actionCardComponent';
import FlatButton from 'components/commonComponents/flatButton';
import IndividualIcon from 'images/individual-icon.svg';
import OrgIcon from 'images/org-icon.svg';
import analyticsUtil from 'utils/segmentUtil';

const ActionsListComponent = (props) => {
    const myStyles = getSkin(props.skinGuide)
    let { selectedActorId } = props.actionSequence

    let { isReactJoyrideRunning, isActionsTutorialRunning } = props.tutorial
    let { actionStepNumber, selectedCategoryId, selectedSubDomainId, selectedActionId } = props.actionSequence

    let { categoryList } = props.categories
    let currentCategory = categoryList && categoryList[selectedCategoryId]
    let currentSubDomain = currentCategory && currentCategory.subDomains[selectedSubDomainId]

    // let currentCategoryActionList = currentSubDomain && currentSubDomain.actions
    let { actionList } = props
    let actionsOrder = currentSubDomain && currentSubDomain.actionsOrder
    let currentActionList = []

    if (actionsOrder) {
        currentActionList = actionsOrder.map(item => actionList[item])
    }

    // If the action sequence number is less than 2 and tutorial is not ongoing, hide the component.
    let isVisible = (!isReactJoyrideRunning && !isActionsTutorialRunning && actionStepNumber < 2) ?
        { visibility: 'hidden' } : {}

    let componentStyle = { ...isVisible }

    const onOrgWideActionClick = () => {
        //Segment integartion
        analyticsUtil.track("ACTION_TAKEN",{
            actionType: "Org",
            actionName : props.getLabel(props.actionList[selectedActionId].name)
         });
        props.actionTaken({
            "actionId": selectedActionId
        })
        props.resetActionSequence()
    }

    const goToActorSelectionStep = () => {
        props.setActionStepNumber(4)
        props.saveSelectedActorId(-1)
    }

    const takeIndAction = () => {
        if (selectedActorId != -1) {
            props.actionTaken({
                "actionId": selectedActionId,
                "actorId": selectedActorId
            })
        }
        // props.resetActionSequence()
    }

    const onOrgActionClick = () => {
        props.setActionStepNumber(2)
    }

    const onIndividualActionClick = () => {
        props.setActionStepNumber(3)
    }

    const getActionCard = (startPoint, endPoint) => {
        let divArray = []
        for (let i = startPoint; i <= endPoint; i++) {
            divArray.push(
                <ActionCardComponent
                    key={i}
                    {...currentActionList[i]}
                    saveSelectedActionId={props.saveSelectedActionId}
                    isCurrentActionSelected={currentActionList[i].id === selectedActionId}
                    onIndividualActionClick={onIndividualActionClick}
                    onOrgActionClick={onOrgActionClick}
                    saveSelectedActorId={props.saveSelectedActorId}
                    isEventAction={false}
                    actionSequence={props.actionSequence}
                    introData={props.introData}
                    overallMetrics={props.overallMetrics}
                />
            )
        }
        return divArray
    }

    let isActionSelected = selectedActionId > -1,
        selectedActionData = currentActionList.find(item => item.id === selectedActionId)

    return (
        <div
            styleName='container'
            className={css(myStyles.container)}
            style={componentStyle}
        >
            <div styleName='title' className={css(myStyles.title)}>
                {currentSubDomain && props.getLabel(currentSubDomain.desc)}
            </div>
            <div styleName='actions-container'>
                {
                    // First 3 actions,if they exist
                    currentActionList.length >= 3 ?
                        <div styleName='action-list'>
                            {getActionCard(0, 2)}
                        </div>
                        :
                        <div styleName='action-list'>
                            {getActionCard(0, currentActionList.length - 1)}
                        </div>
                }
                {
                    // Next actions,if they exist
                    currentActionList.length >= 4 &&
                    <div styleName='action-list'>
                        {getActionCard(3, currentActionList.length - 1)}
                    </div>
                }
            </div>
            <div styleName='action-text' className={css(myStyles.actionTextBG)}>
                {
                    isActionSelected ?
                        <div styleName='action-desc' className={css(myStyles.actionDesc)}>
                            <span styleName='action-desc-header'> {props.getLabel(selectedActionData.name)}:</span>
                            {props.getLabel(selectedActionData.desc)}
                        </div>
                        :
                        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                            <div styleName='action-types' className={css(myStyles.actionTypes)}>
                                <div styleName='action-type-single'>
                                    <img src={IndividualIcon} />
                                    {props.getLabel("label_dashboard_individual_action_text")}
                                </div>
                                <div styleName='action-type-single'>
                                    <img src={OrgIcon} />
                                    {props.getLabel("label_dashboard_org_action_text")}
                                </div>
                            </div>
                        </div>
                }
            </div>
            <div styleName='button-container'>
                {
                    selectedActionData && selectedActionData.typeId === 1 ?
                        <FlatButton
                            textLabel={props.getLabel('label_dashboard_choose_member_button')}
                            isDisabled={actionStepNumber < 3 ? true : false}
                            onClickFunction={() => goToActorSelectionStep()}
                        />
                        :
                        <FlatButton
                            textLabel={props.getLabel("label_dashboard_take_action_button")}
                            isDisabled={!isActionSelected}
                            onClickFunction={() => onOrgWideActionClick()}
                        />
                }
            </div>
            {
                !selectedActionData &&
                <div styleName='action-hint' className={css(myStyles.actionHint)}>{props.getLabel('label_dashboard_choose_action_text')}</div>
            }
        </div>
    )
}

export default applyWrappers(ActionsListComponent, styles);
