import actionConsts from 'constants/actions';
import store from 'store/store'

const saveSelectedCategoryId = (id) => {
    return {
        type: actionConsts.SAVE_SELECTED_CATEGORY_ID,
        payload: id
    }
}

const saveSelectedCategoryNameForTutorial = () => {
    let categoryList = store.getState().categories.categoryList;
    let categoryId
    for (let key in categoryList) {
        if (categoryList[key].name === "label_category_basic_needs") {
            categoryId = categoryList[key].id
            return {
                type: actionConsts.SAVE_SELECTED_CATEGORY_ID,
                payload: categoryId
            }
        }
    }
}

const saveSelectedSubDomainId = (id) => {
    return {
        type: actionConsts.SAVE_SELECTED_SUBDOMAIN_ID,
        payload: id
    }
}

const saveSelectedSubDomainForTutorial = () => {
    let categoryList = store.getState().categories.categoryList;
    let subDomainId
    for (let key in categoryList) {
        if (categoryList[key].name === "label_category_basic_needs") {
            let subDomains = categoryList[key].subDomains;
            for (let k in subDomains) {
                if (subDomains[k].name === "label_bn_subdomain_safety") {
                    subDomainId = k
                    return {
                        type: actionConsts.SAVE_SELECTED_SUBDOMAIN_ID,
                        payload: subDomainId
                    }
                }
            }
        }
    }
}

const saveSelectedActionId = (id) => {
    return {
        type: actionConsts.SAVE_SELECTED_ACTION_ID,
        payload: id
    }
}

const saveSelectedActorId = (id) => {
    return {
        type: actionConsts.SAVE_SELECTED_ACTOR_ID,
        payload: id
    }
}

const setActionStepNumber = (id) => {
    return {
        type: actionConsts.SET_ACTION_STEP_NUMBER,
        stepId: id
    }
}

const resetActionSequence = () => {
    return {
        type: actionConsts.RESET_ACTION_SEQUENCE
    }
}

export {
    saveSelectedCategoryId,
    saveSelectedCategoryNameForTutorial,
    saveSelectedSubDomainId,
    saveSelectedSubDomainForTutorial,
    saveSelectedActionId,
    saveSelectedActorId,
    setActionStepNumber,
    resetActionSequence
};