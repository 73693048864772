import React, { Fragment } from 'react';
import styles from './actionsComponent.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import CategoriesListComponent from './categoriesListComponent'
import SubDomainsListComponent from './subDomainsListComponent'
import ActionsListComponent from './actionsListComponent'
import ActorSelectionScreen from './actorSelectionScreen';

const ActionsComponent = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const { isReactJoyrideRunning, isActorsTutorialRunning, isActionsUncoveredPageShown, isActionsTutorialRunning } = props.tutorial
    const isActorSelectionScreenOpen = props.actionSequence.actionStepNumber === 4
    const { isActorTutorialCompleted, isActionTutorialCompleted } = props.userInfo

    // This style is to hide the actions part
    let hiddenDivStyle = isActorsTutorialRunning || (!isActionsTutorialRunning && !isActionTutorialCompleted && !(isActorTutorialCompleted && isActionsUncoveredPageShown)) ?
        { visibility: 'hidden' }
        : {}

    // This style is to remove the actions part completely, and is used to display intermediate pae in tutorial
    hiddenDivStyle = isActorTutorialCompleted && isActionsUncoveredPageShown ? { display: 'none' } : hiddenDivStyle

    return (
        <div styleName="container" style={hiddenDivStyle}>
            {
                isActorSelectionScreenOpen
                    ?
                    <ActorSelectionScreen
                        actionSequence={props.actionSequence}
                        categories={props.categories}
                        actorList={props.actorList}
                        actionList={props.actionList}
                        setActionStepNumber={props.setActionStepNumber}
                        resetActionSequence={props.resetActionSequence}
                        saveSelectedActorId={props.saveSelectedActorId}
                        actionTaken={props.actionTaken}
                        introData={props.introData}
                        overallMetrics={props.overallMetrics}
                    />
                    :
                    <Fragment>
                        <div styleName='title' className={css(myStyles.title)}>{props.getLabel('label_dashboard_screen_text2')}</div>
                        <div styleName='actions-container' className={css(myStyles.actionsContainer)}>
                            <CategoriesListComponent
                                tutorial={props.tutorial}
                                categories={props.categories}
                                actionSequence={props.actionSequence}
                                saveSelectedCategoryId={props.saveSelectedCategoryId}
                                setActionStepNumber={props.setActionStepNumber}
                                resetActionSequence={props.resetActionSequence}
                            />
                            <SubDomainsListComponent
                                tutorial={props.tutorial}
                                categories={props.categories}
                                actionSequence={props.actionSequence}
                                saveSelectedSubDomainId={props.saveSelectedSubDomainId}
                                saveSelectedActionId={props.saveSelectedActionId}
                                setActionStepNumber={props.setActionStepNumber}
                            />
                            <ActionsListComponent
                                tutorial={props.tutorial}
                                categories={props.categories}
                                actionSequence={props.actionSequence}
                                saveSelectedActionId={props.saveSelectedActionId}
                                setActionStepNumber={props.setActionStepNumber}
                                resetActionSequence={props.resetActionSequence}
                                actionTaken={props.actionTaken}
                                saveSelectedActorId={props.saveSelectedActorId}
                                actionList={props.actionList}
                                introData={props.introData}
                                overallMetrics={props.overallMetrics}
                            />
                        </div>
                    </Fragment>
            }
        </div>
    )
}

export default applyWrappers(ActionsComponent, styles);
