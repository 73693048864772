import urlConsts from 'constants/url';
import netpack from 'utils/netpack';
import { setUiState } from '../uiState/actionCreators';
import { startEventLoader, stopEventLoader, eventResponseReceived, setNextEvent } from 'actions/event'

export const actionEventCompleted = (payload) => (dispatch) => {
    dispatch(startEventLoader())
    // Send event id and action id for current action event 
    netpack(urlConsts.ACTION_EVENT_COMPLETED_API).post({
        body: JSON.stringify(payload)
    }).then((response) => {
        if (response.success) {
            dispatch(stopEventLoader())
            // Dispatch an action to confirm event response has been received and update all the metrics
            dispatch(eventResponseReceived(response))
        }
    });
}

export const infoEventCompleted = (payload) => (dispatch) => {
    dispatch(startEventLoader())
    // Send event id for completed info event 
    netpack(urlConsts.INFO_EVENT_COMPLETED_API).post({
        body: JSON.stringify(payload)
    }).then((response) => {
        if (response.success) {
            dispatch(stopEventLoader())

            //Close event popup
            dispatch(setUiState({
                showOverlay: false,
                overlayComponentType: '',
                overlayComponent: ''
            }));

            if (response.gameOver.simEnd || 
                (response.gameOver.simEndCause == "budget" && !response['isBudgetFinished'])) {
               
                let showGameOverPopup = {
                    showOverlay: true,
                    overlayComponentType: "CENTER",
                    overlayComponent: "ENDSCREEN_POPUP",
                    isTargetAchieved: response.isTargetAchieved,
                    gameOver: response.gameOver
                };
                dispatch(setUiState(showGameOverPopup));
            } else if (response.events) {
                // If an event comes as the response of the current event, set details for that event
                dispatch(setNextEvent(response))
            }
        }
    });
}