import React, { Fragment } from 'react';
import styles from './actionFeedback.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Header from 'components/commonComponents/popup/header';
import Description from 'components/commonComponents/popup/description';
import ActionOrEventInfo from 'components/commonComponents/popup/actionOrEventInfo';
import ActionResponseMetrics from 'components/action/actionResponseMetrics';
import DelayedImpact from 'images/delayedimpact.svg';
import IndividualMetricsChange from 'components/action/individualMetricsChange'
import FlatButton from 'components/commonComponents/flatButton'

const ActionFeedback = (props) => {
    const myStyles = getSkin(props.skinGuide);

    let responseMsgObj = {};
    if (props.isActionIndividual && !props.isDelayedAction) {
        let actorName = props.getLabel(props.actorInfo.name);
        let gender = props.getLabel(props.actorInfo.gender);
        const isMale = gender == "M" || gender == "m" || gender == "male" || gender == "Male" ? true : false;
        const gender_1 = isMale ? props.getLabel("label_he" ) :props.getLabel("label_she");
        const gender_2 = isMale ? props.getLabel("label_his") :props.getLabel("label_her");
        const gender_3 = isMale ? props.getLabel("label_him") :props.getLabel("label_her");
        responseMsgObj = { actorName1: actorName.split(" ")[0], gender_1, gender_2, gender_3 };
    }

    let delayedMessageConditionCheck = props.isDelayedAction && !props.isDelayedImpact
    let delayedActionDescriptionText = delayedMessageConditionCheck ? 'label_action_delayed_text' : props.actionMessage

    const showTeamMetricChanges = () => {
        let returnObj = []
        for (let item in props.actorMetrics) {
            returnObj.push(< IndividualMetricsChange
                key={item}
                actorMetrics={props.actorMetrics[item]}
                {...props.actorInfo[item]}
                category={props.category}
                domain={props.domain}
            />)
        }
        return <div style={{ display: 'flex', width: '100%' }}>{returnObj}</div>
    }

    const showIndividualMetricChanges = () => {
        return <IndividualMetricsChange
            actorMetrics={props.actorMetrics[props.actorId]? props.actorMetrics[props.actorId]: props.actorMetrics}
            {...props.actorInfo}
            category={props.category}
            domain={props.domain}
        />
    }

    return (
        <Fragment>
            <div style={{ position: 'relative' }}>
                {
                    props.isDelayedImpact ?
                        <div styleName="image-container">
                            <div styleName="delayed-impact">
                                <img src={DelayedImpact} />
                                <div className={css(myStyles.delayedImpact)} styleName="centered">{props.getLabel("label_history_delayed_impact_tag")}</div>
                            </div>
                        </div>
                        :
                        null
                }
                <div styleName="title">
                    <Header title={props.getLabel(props.title)} />
                </div>
            </div>
            <div className={css(myStyles.actioninfo)}>
                <ActionOrEventInfo
                    isActionIndividual={props.isActionIndividual}
                    days={props.days}
                    budget={props.budget}
                />
            </div>
            {/* {
                (props.isActionIndividual && !props.isDelayedAction) ?
                    <div styleName="actorInfo">
                        <IndividualActionResponse actorInfo={props.actorInfo} />
                    </div>
                    : ''
            } */}
            {
                !props.isActionIndividual && !props.isDelayedAction && !props.delayedMessageConditionCheck &&
                <span>
                    {showTeamMetricChanges()}
                    <div styleName="response-message">
                        <Description desc={props.getLabel(props.actionMessage, "", responseMsgObj)} />
                    </div>
                </span>
            }
            {
                props.isActionIndividual && !props.isDelayedAction &&
                <span style={{ display: 'flex', marginBottom: '20px' }}>
                    {showIndividualMetricChanges()}
                    <div styleName="response-message-ind">
                        <Description desc={props.getLabel(props.actionMessage, "", responseMsgObj)} />
                    </div>
                </span>
            }
            {
                props.isDelayedAction &&
                <ActionResponseMetrics {...props} delayedActionDescriptionText={delayedActionDescriptionText} popup="actionresponse" />
            }
            <div styleName='button-container'>
                <FlatButton
                    textLabel={props.getLabel('label_action_close_button_text')}
                    onClickFunction={() => props.closePopup()}
                />
            </div>
        </Fragment>
    )
}

export default applyWrappers(ActionFeedback, styles);
