import React from 'react';
import styles from './introVideoPage.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import VideoPlayerComponent from 'components/commonComponents/videoPlayerComponent'
import TranscriptComponent from 'components/commonComponents/transcriptComponent'
import FlatButton from 'components/commonComponents/flatButton'
import IntroFooterBg from 'images/intro-footer-bg.png'

const IntroVideoPage = (props) => {
    const myStyles = getSkin(props.skinGuide);

    return (
        <div styleName="external-container" >
            <div
                styleName="container"
                className={css(myStyles.container)}
            >
                <div styleName="intro-body">
                    <VideoPlayerComponent
                        propRef="intro-video"
                        kalturaVideoId={props.getLabel("label_kaltura_video_id")}
                    />
                    <TranscriptComponent {...props} />
                </div>
            </div>
            <div styleName="intro-footer" >
                <FlatButton textLabel={props.getLabel('label_transcript_screen_button')} onClickFunction={() => props.onFooterButtonClick()} />
                <img src={IntroFooterBg} />
            </div>
        </div>
    )
}

export default applyWrappers(IntroVideoPage, styles);
