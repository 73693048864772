import React from 'react';
import styles from './endScreenPopup.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import objective_achieved from 'images/objective_achieved.png';
import timer_image from 'images/timer_image.svg';
import analyticsUtil from 'utils/segmentUtil';

const EndScreenPopup = (props) => {
    const myStyles = getSkin(props.skinGuide);
    let { uiState , type } = props;
    let timerTitle = props.getLabel('label_end_popup_timeout_title');
    let objective_achievedTitle = uiState.isTargetAchieved
        ? props.getLabel('label_end_popup_objective_achieved_title')
        : uiState.gameOver.simEndCause == "duration" ? props.getLabel('label_end_popup_days_ranout_title'): props.getLabel('label_end_popup_budget_ranout_title');
    
    let timerDesc = props.getLabel('label_end_popup_timeout_desc');
    let objective_achievedDesc = uiState.isTargetAchieved
        ? props.getLabel('label_end_popup_objective_achieve_desc')
        : uiState.gameOver.simEndCause == "duration" ? props.getLabel('label_end_popup_days_ranout_desc'): props.getLabel('label_end_popup_budget_ranout_desc');

    //Segment integartion
    analyticsUtil.track("ENDSCREEN_POPUP_OPEN",{ 
        isTargetAchieved: uiState.isTargetAchieved, type : props.type });
    return (
        <div styleName="container" className={css(myStyles.container)}>
            <div styleName="header-container" className={css(myStyles.headerContainer)}>
                <div styleName="info-icon">
                    {
                        type === 'timer' ? <img src={timer_image} />
                            : <img src={objective_achieved} />
                    }
                </div>
                <div styleName="title" className={css(myStyles.title)}>
                    {
                        type === 'timer' ? timerTitle : objective_achievedTitle
                    }
                </div>
            </div>
            <div styleName="content">
                <div styleName="desc" className={css(myStyles.info)}>
                    {
                        type === 'timer' ? timerDesc : objective_achievedDesc
                    }
                </div>
                <div styleName="got-it" className={css(myStyles.btnText)} onClick={props.clickGotIt}>
                    {props.getLabel('label_end_popup_button')}
                </div>
            </div>
        </div>
    )
}

export default applyWrappers(EndScreenPopup, styles);
