import React from 'react';
import styles from './logoutConfimationPopup.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import FlatButton from 'components/commonComponents/flatButton'
import timer_image from 'images/timer_image.svg';
import urlconsts from 'constants/url';
import analyticsUtil from 'utils/segmentUtil';

const LogoutConfimationPopup = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const onClickLogout = () => {
        //Segment integartion
        analyticsUtil.reset();
        analyticsUtil.track("LOGOUT",{ userInfo : props.userInfo });
        
        window.location.href = urlconsts.LOG_OUT;
    }

    const onClickCancel = () => {
        props.setUiState({
            showOverlay: false,
            overlayComponentType: '',
            overlayComponent: ''
        })
    }

    return (
        <div styleName="container">
            <div styleName='image'>
                <img src={timer_image} />
            </div>
    <div styleName='title' className={css(myStyles.title)}>{props.getLabel("label_logout_popup_title")}</div>
            <div styleName='hrline' className={css(myStyles.hrline)} />
            <div styleName='text' className={css(myStyles.text)}>
                {/* {props.getLabel("label_logout_popup_desc")} */}
            </div>
            <div styleName='button-container'>
                <div
                    styleName='cancel-button'
                    className={css(myStyles.buttonText)}
                    onClick={() => onClickCancel()}
                >{props.getLabel("label_logout_popup_cancel_btn")}</div>
                <FlatButton
                    textLabel={props.getLabel("label_logout_popup_logout_btn")}
                    onClickFunction={() => onClickLogout()}
                />
            </div>
        </div>
    )
}

export default applyWrappers(LogoutConfimationPopup, styles);
