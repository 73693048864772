import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'utils/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
    const skin = globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            backgroundImage: skin.white,
        },
        seperator: {
            background: `linear-gradient(173.88deg, ${skin.primaryColor} 2.55%, ${skin.secondaryColor} 110.15%)`
        },
        responseContainer: {
            //background: `#FBFBFB`
        },
        path: {
            fontFamily: "Nunito",
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '14px',
            color: skin.black,
            opacity: 0.5,
            marginBottom: '30px'
        },

        actioninfo: {
            marginTop: '15px',
            marginBottom: '12px'

        },
        actionInformation: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '16px',
            color: skin.black,
            opacity: 0.6
        },

        personName: {
            ...myTypography.body1,
            fontStyle: 'italic',
            color: skin.grayColor4
        }
    });
}

export default getSkin;