import React from "react";
import styles from "./sideBarListComponent.module.scss";
import getSkin from "./skin.js";
import { css } from "aphrodite/no-important";
import applyWrappers from "wrappers/ComponentWrapper";
import SideBarItemComponent from "./sideBarItemComponent";
import { hexToRgbA } from 'utils/styleUtil';

const SideBarListComponent = props => {
    const myStyles = getSkin(props.skinGuide);

    const skin = props.skinGuide.globalProfiles.palette
    let { uiState, userInfo } = props

    return (
        <div
            styleName="component"
            className={css(myStyles.list)}
            style={uiState.highlightedSidebarComponent !== -1 ? { borderColor: hexToRgbA(skin.secondaryColor, 0.1) } : {}}
            id='sidebarcomponent'
        >
            {!userInfo.isVACEnabled ? <SideBarItemComponent
                image="leaderboard"
                uiState={uiState}
                setHighlightedSidebarComponent={props.setHighlightedSidebarComponent}
                onClickCall={props.onClickOfLeaderboard}
                isHighlighted={uiState.highlightedSidebarComponent === -1 || uiState.highlightedSidebarComponent === 0}
            /> : null }
            <SideBarItemComponent
                image="objectives"
                uiState={uiState}
                setHighlightedSidebarComponent={props.setHighlightedSidebarComponent}
                onClickCall={props.onClickOfObjectives}
                isHighlighted={uiState.highlightedSidebarComponent === -1 || uiState.highlightedSidebarComponent === 1}
            />
            <SideBarItemComponent
                image="history"
                uiState={uiState}
                setHighlightedSidebarComponent={props.setHighlightedSidebarComponent}
                onClickCall={props.onClickOfHistory}
                isHighlighted={uiState.highlightedSidebarComponent === -1 || uiState.highlightedSidebarComponent === 2}
            />
            <SideBarItemComponent
                image="report"
                uiState={uiState}
                setHighlightedSidebarComponent={props.setHighlightedSidebarComponent}
                onClickCall={props.onClickOfIntermediateReport}
                isHighlighted={uiState.highlightedSidebarComponent === -1 || uiState.highlightedSidebarComponent === 3}
            />
        </div>
    );
};

export default applyWrappers(SideBarListComponent, styles);
