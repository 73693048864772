import React from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

class Tutorial extends React.Component {

    getLabel = (key) => {
        return this.props.labels.labelsList[key];
    }

    stopOngoingTutorial = () => {
        this.props.pauseTutorial();
    }

    getNextStepNumber = (data) => {
        if (data.action === ACTIONS.PREV) {
            return this.props.tutorial.stepIndex - 1
        } else if (data.action === ACTIONS.NEXT) {
            return this.props.tutorial.stepIndex + 1
        } else if (data.action === ACTIONS.CLOSE) {
            this.stopOngoingTutorial()
        }
    }

    callback = (data) => {
        // let nextStepId;
        // let { tutorial } = this.props
        // switch (data.type) {
        //     case EVENTS.STEP_AFTER:
        //         if ((tutorial.type == "sprintPlanningScreen" && tutorial.stepIndex === 0) || tutorial.type == "sprintExecutionScreen") {
        //             nextStepId = this.getNextStepNumber(data);
        //             this.props.updateTutorialStepNumber(nextStepId);
        //         }
        //         break;
        //     default:
        //         break;
        // }

        // if (tutorial.type == "sprintPlanningScreen" && tutorial.stepIndex === 2) {
        //     if (data.index === 2 && data.lifecycle === "complete") {
        //         this.props.endTutorial()
        //     }
        // }
    }

    render() {
        let nextBtnText = 'Next'
        let finishBtnText = 'Finish'

        let { type, stepIndex } = this.props.tutorial
        let spotLightBorderRadius = 0
        if (type === 'header') {
            spotLightBorderRadius = 10
        } else if (type === 'actions') {
            if (stepIndex !== 2) {
                spotLightBorderRadius = 55
            }
        }

        return (
            <ReactJoyride
                ref={(c) => (this.joyride = c)}
                continuous
                run={this.props.tutorial.isReactJoyrideRunning}
                steps={this.props.tutorial.steps}
                stepIndex={this.props.tutorial.stepIndex}
                callback={this.callback}
                styles={
                    {
                        options: {
                            arrowColor: 'black',
                            backgroundColor: 'white',
                            overlayColor: 'rgba(255, 255, 255, 0.95)',
                            primaryColor: 'black',
                            textColor: 'black',
                            arrowColor: 'white',
                        },
                        spotlight: {
                            borderRadius: spotLightBorderRadius
                        },
                        buttonClose: {
                            display: 'none',
                        }
                    }
                }
                // disableOverlay={false}
                locale={{ last: finishBtnText, next: nextBtnText }}
                disableBeacon
                disableCloseOnEsc
                disableOverlayClose
                scrollToFirstStep
                showBackButton
                disableOverlayClicks
                scrollToSteps={false}
            />
        );
    }
}

Tutorial.propTypes = {};
Tutorial.defaultProps = {};

export default Tutorial;