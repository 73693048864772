import React from 'react';
import styles from './tutorialCompletedPopup.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import PopupImage from 'images/tutorial-completed-popup.svg'
import FlatButton from 'components/commonComponents/flatButton'

const TutorialCompletedPopup = (props) => {
    let { firstName, emailId } = props.userInfo
    let playerName = firstName ? firstName : emailId
    const myStyles = getSkin(props.skinGuide);

    const onButtonClick = () => {
        // Close tutorial completed popup
        props.setUiState({
            showOverlay: false,
            overlayComponentType: '',
            overlayComponent: ''
        })
        props.setTimerStarted()
    }

    return (
        <div styleName='container'>
            <div styleName='img-container'>
                <img src={PopupImage} />
            </div>
            <div styleName='title' className={css(myStyles.title)}>
                {props.getLabel('label_start_simulation_title', "", { PLAYER_NAME: playerName })}
            </div>
            {/* <div className={css(myStyles.desc)}>
               {props.getLabel('label_start_simulation_desc')}
            </div> */}
            <div styleName='desc'>
                <FlatButton
                    textLabel={props.getLabel('label_tutorial_got_it_button')}
                    onClickFunction={() => onButtonClick()}
                />
            </div>
            <div
                className={css(myStyles.desc)}
                style={{ opacity: '0.8', fontStyle: 'italic' }}
            >
                {props.getLabel('label_tutorial_got_it_button_timer_start_text')}
            </div>
        </div>
    )
}

export default applyWrappers(TutorialCompletedPopup, styles);
