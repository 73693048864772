import React from 'react';
import styles from './IndividualMetricsChange.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import metricsDecreaseIcon from 'images/metrics_decrease.svg'
import metricsIncreaseIcon from 'images/metrics_increase.svg'
import NoChangeIcon from 'images/metrics_no_change.svg'
import ProgressBar from 'components/commonComponents/progressBarComponent'

const IndividualMetricsChange = (props) => {
    const myStyles = getSkin(props.skinGuide);

    let metricsIcon = '', textColor;
    if (props.actorMetrics.happiness.offset < 0) {
        metricsIcon = metricsDecreaseIcon
        textColor = '#FF4B4B'
    } else if (props.actorMetrics.happiness.offset > 0) {
        metricsIcon = metricsIncreaseIcon
        textColor = '#0DD8A8'
    } else {
        metricsIcon = NoChangeIcon
    }
    return (
        <div styleName="container">
            <div styleName='name-container' className={css(myStyles["individualInfo"])}>
                <div styleName="user">
                    <span>
                        <img styleName="image-size" src={props.imagePath}></img>
                        <div className={css(myStyles["actorName"])} styleName="name">{props.getLabel(props.name)}</div>
                    </span>
                    <div styleName='value-changes'>
                        <img src={metricsIcon} />
                        <div className={css(myStyles.budgetChange)} style={{ color: textColor, marginLeft: '3px' }}>{Math.abs(props.actorMetrics.happiness.offset).toFixed(1)}%</div>
                    </div >
                </div>
                <div styleName='ProgressBar'>
                    <ProgressBar value={props.actorMetrics.happiness.value && (Number(props.actorMetrics.happiness.value)).toFixed(1)} isBarRounded={true} />
                </div>
            </div>
            <div styleName='category-container'>
                <div className={css(myStyles.categoryName)}>{props.getLabel(props.category)}</div>
                <span>
                    <div className={css(myStyles.categoryValue)}>{props.actorMetrics.category.value.toFixed(0)}%</div>
                    <div styleName='value-changes'>
                        <img src={metricsIcon} />
                        <div className={css(myStyles.budgetChange)} style={{ color: textColor, marginLeft: '3px' }}>{Math.abs(props.actorMetrics.category.offset).toFixed(1)}%</div>
                    </div >
                </span>
            </div>
            <div styleName='category-container'>
                <div className={css(myStyles.categoryName)}>{props.getLabel(props.domain)}</div>
                <span>
                    <div className={css(myStyles.categoryValue)}>{props.actorMetrics.subdomain.value.toFixed(0)}</div>
                    <div styleName='value-changes'>
                        <img src={metricsIcon} />
                        <div className={css(myStyles.budgetChange)} style={{ color: textColor, marginLeft: '3px' }}>{Math.abs(props.actorMetrics.subdomain.offset)}</div>
                    </div >
                </span>
            </div>
        </div>
    );
}

export default applyWrappers(IndividualMetricsChange, styles);