import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        desc: {
			fontFamily: "Open Sans",
			fontSize: '16px',
			fontWeight: 'normal',
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: '180%',
			letterSpacing: 'normal',
			color: skin.black,
		},
		descSmaller: {
			fontFamily: "Open Sans",
			fontSize: '14px',
			fontWeight: 'normal',
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: '180%',
			letterSpacing: 'normal',
			color: skin.black,
		},
    });
}

export default getSkin;
