import actionConsts from 'constants/actions';

const initialState = {
    history : []
};

const history = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.HISTORY_DATA:
            return action.payload;
        default:
            return state;
    }
};

export default history;