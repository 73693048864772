import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        individualInfo: {
            ...myTypography.body1,
            background: '#FFFFFF',
        },
        actorName: {
            fontSize: '10px',
            lineHeight: '14px',
            color: skin.black,
            opacity: '0.8',
        },
        budgetChange: {
            ...myTypography.body1,
            fontWeight: '900',
            fontSize: '12px',
            color: '#B6B6B6'
        },
        categoryName: {
            ...myTypography.body1,
            fontSize: '10px',
            color: skin.black,
            opacity: '0.5'
        },
        categoryValue: {
            ...myTypography.body1,
            fontSize: '12px',
            color: skin.black
        }
    });
}

export default getSkin;
