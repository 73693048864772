import environment from 'utils/environment';

const host = environment.REACT_APP_BACKEND_URL;

const urls = {
    GAME_API: `${host}/game`,
    LABELS_API: `${host}/labels`,
    ACTION_API: `${host}/take-action`,
    LEADERBOARD_API: `${host}/leaderboard`,
    INTERMEDIATE_REPORT_API: `${host}/intermediate-report`,
    HISTORT_API: `${host}/history`,

    // Timer update api
    UPDATE_TIME_LEFT: `${host}/update-timer`,

    // User state apis
    GAME_STARTED_API: `${host}/game-started`,
    ACTOR_TUTORIAL_COMPLETED_API: `${host}/actor-tutorial-completed`,
    ACTION_TUTORIAL_COMPLETED_API: `${host}/action-tutorial-completed`,
    BUDGET_FINISHED_API: `${host}/budget-finished`,

    // Actor based apis
    SET_ACTOR_VISITED_FLAG_API: `${host}/actor-visited`,
    GET_ACTOR_DETAILS: `${host}/actor?actorId=`,
    START_TIMER: `${host}/timer-started`,
    SET_MONTHLY_REPORT_SEEN_FLAG: `${host}/month-seen`,

    //logout
    LOG_OUT: `${host}/logout`,

    // Event apis
    ACTION_EVENT_COMPLETED_API: `${host}/take-event-action`,
    INFO_EVENT_COMPLETED_API: `${host}/event-seen`,

    //Game Completed
    GAME_COMPLETED: `${host}/game-completed`,
    USER_REPORT_WEB_URL: `${host}/user-report`,
    USER_REPORT_PDF_URL: `${host}/report/pdf`,
    MAIL_REPORT: `${host}/mail-report-pdf`,
};

export default urls;
