import React from 'react';
import styles from './actionResponseMetrics.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import IncremenentIcon from 'images/submetrics-increment-arrow.png';
import DecrementIcon from 'images/submetrics-decrement-arrow.png';
import NoChangeIcon from 'images/submetrics_no_change.png';


const ActionResponseMetrics = (props) => {
    const myStyles = getSkin(props.skinGuide);
    let isDelayedAction = props.isDelayedAction;
    let showBuggetAndDayRemaining = true;
    if (props.popup && props.popup == "action-event") {
        showBuggetAndDayRemaining = false;
    }
    const getTagBGClass = (changeType) => {
        switch (changeType) {
            case 'positive':
                return 'greenTag';

            case 'negative':
                return 'redTag';

            case 'neutral':
                return 'grayTag';

            default:
                return 'greenTag';
        }
    }

    const renderArrow = (changeType) => {
        switch (changeType) {
            case 'positive':
                return (
                    <div>
                        <img src={IncremenentIcon} />
                    </div>
                );

            case 'negative':
                return (
                    <div>
                        <img src={DecrementIcon} />
                    </div>
                );
            case 'neutral':
                return (
                    <div styleName="noChange">
                        <img src={NoChangeIcon} />
                    </div>
                );
            default:
                return null;
        }
    }

    const renderTag = (changeValue, changeType) => {
        const tagBGClass = getTagBGClass(changeType);
        return (
            <div
                className={css(myStyles.metricChange, myStyles[tagBGClass])}
                styleName={"metrics-change"}
            >
                {renderArrow(changeType)}
                { changeType != "neutral" &&
                    <span styleName="space">{Math.abs(changeValue)}</span>
                }
            </div>
        );
    }

    const renderGameMetrics = (title, value, changeValue, border = false) => {
        let changeType = changeValue == 0 ? "neutral" : changeValue > 0 ? 'positive' : 'negative';
        return (
            <div
                className={border ? css(myStyles["gameMetricsContainer"]) : css(myStyles["gameMetricContainer"])}
                styleName={"game-metric-container"}
                key={Math.random()}
            >
                <div className={border ? css(myStyles["metricTitle"]) : css(myStyles["metricTitleSmall"
                ])} styleName="metric-title">
                    {title}
                </div>
                <div styleName={"left-metrics-value-change-container"}>
                    <div className={border ? css(myStyles["metricValue"]) : css(myStyles["metricValueSmall"
                    ])}>{value}</div>
                    {renderTag(changeValue, changeType)}
                </div>
            </div >
        );
    }

    const renderDelayedAction = () => {
        let responseMsgObj = { delay: props.delayDay };
        return (
            <div className={css(myStyles["delayedAction"])} styleName="delayed-action">
                <div className={css(myStyles["delayedActionTitle"])}
                    styleName="delayed-action-title">
                    {props.getLabel(props.delayedActionDescriptionText, "", responseMsgObj)}
                </div>
            </div>
        );
    }

    const renderTopMetrics = () => {
        return (
            <div styleName={"game-metrics-container"} key={props.id}>
                {!isDelayedAction ? renderGameMetrics(
                    props.getLabel("label_metric_revenue"),
                    ("$" + Math.round(props.metrics['overallMetrics']['revenue']['metricValue']).toLocaleString('en-US')),
                    (props.metrics['overallMetrics']['revenue']['offset']).toFixed(2),
                    true
                ) : renderDelayedAction()}
            </div>
        );
    }

    return (
        <div styleName={"metrics-container"}>
            {renderTopMetrics()}
        </div>
    );
}

export default applyWrappers(ActionResponseMetrics, styles);
