import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        title: {
			fontFamily: "Nunito",
			fontSize: '18px',
			fontWeight: 600,
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: '25px',
			letterSpacing: 'normal',
			color: skin.black
		},
    });
}

export default getSkin;
