import React from 'react';
import styles from './actorSelectionScreen.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ActionCardComponent from '../actionsListComponent/actionCardComponent'
import FlatButton from 'components/commonComponents/flatButton'
import analyticsUtil from 'utils/segmentUtil';

const ActorSelectionScreen = (props) => {
    const myStyles = getSkin(props.skinGuide);

    let { selectedCategoryId, selectedSubDomainId, selectedActionId, selectedActorId } = props.actionSequence
    let isTakeActionButtonLocked = selectedActorId === -1

    const onCancelButtonClick = () => {
        // Reset selected actor and go back to action selection screen
        props.saveSelectedActorId(-1)
        props.setActionStepNumber(3)
    }

    const onTakeActionButtonClick = () => {
        //Segment integartion
        analyticsUtil.track("ACTION_TAKEN",{
            actionType: "Individual",
            actionName : props.getLabel(props.actionList[selectedActionId].name),
            actorName: props.getLabel(props.actorList[selectedActorId].name),
        });
        props.actionTaken({
            "actionId": selectedActionId,
            "actorId": selectedActorId
        })
        // props.resetActionSequence()
    }

    let { categoryList } = props.categories
    let currentCategory = categoryList[selectedCategoryId]
    let currentSubDomain = currentCategory.subDomains[selectedSubDomainId]
    let currentAction = props.actionList[selectedActionId]

    return (
        <div styleName='ext-container'>
            <div styleName='container'>
                <div styleName='choose-text' className={css(myStyles.chooseText)}>
                    {props.getLabel('label_action_selected_text1')}
                </div>
                <div styleName='action-card'>
                    <ActionCardComponent
                        {...currentAction}
                        isClickDisabled={true}
                        isCurrentActionSelected={false}
                        introData={props.introData}
                        overallMetrics={props.overallMetrics}
                    />
                </div>
                <div styleName='action-desc' className={css(myStyles.actionDesc)}>
                    <span styleName='action-desc-header'> {props.getLabel(currentAction.name)}:</span>
                    {props.getLabel(currentAction.desc)}
                </div>
                <div styleName='buttons'>
                    <div
                        styleName='cancel-button'
                        className={css(myStyles.cancelButton)}
                        onClick={() => onCancelButtonClick()}
                    >
                        {props.getLabel('label_action_selected_cancel_button')}
                    </div>
                    <FlatButton
                        textLabel={props.getLabel('label_action_selected_take_action_button')}
                        isDisabled={isTakeActionButtonLocked}
                        onClickFunction={() => onTakeActionButtonClick()}
                    />
                </div>
            </div>
        </div>
    )
}

export default applyWrappers(ActorSelectionScreen, styles);
