import actionConsts from 'constants/actions';

const initialState = [];

const userDelayedActions = (state = initialState, action = {}) => {
    
    switch (action.type) {
        case actionConsts.ACTION_RESPONSE:
            return action.payload.delayedActions;
        default:
            return state;
    }
};

export default userDelayedActions;
