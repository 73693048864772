import consts from 'constants/url';
import netpack from 'utils/netpack';
import store from 'store/store'
import {
    setUserGameStartedFlag,
    setActorTutorialCompletedFlag,
    resetActorTutorialCompletedFlag,
    setActionTutorialCompletedFlag,
    setUserGameCompletedFlag,
    setUserBudgetFinishedFlag,
    setStartTimerFlag
} from '../user';
import { setUiState, resetDisplayMonthlyReportFlag } from '../uiState/actionCreators';
import { resetActionSequence } from '../actionSequence';
import { pauseTutorial, stopActionsTutorial } from 'actions/tutorial'
import { startActionLoader, stopActionLoader } from '../user';

export const setUserGameStarted = () => (dispatch) =>
    //To stop mail read request in tutorial mode
    netpack(consts.GAME_STARTED_API)
        .post()
        .then(response => {
            if (response.success) {
                dispatch(setUserGameStartedFlag());
            }
        })
        .catch(frontendError => {
            console.log(frontendError);
        });

export const setUserGameCompleted = () => (dispatch) => {
    dispatch(startActionLoader())
    //To stop mail read request in tutorial mode
    netpack(consts.GAME_COMPLETED)
        .post()
        .then(response => {
            if (response.success) {
                dispatch(setUserGameCompletedFlag());
                dispatch(stopActionLoader())
            }
        })
        .catch(frontendError => {
            console.log(frontendError);
        });
}

export const setUserBudgetFinished = () => (dispatch) =>{
    dispatch(startActionLoader())

    netpack(consts.BUDGET_FINISHED_API)
        .post({
            body: JSON.stringify({ isBudgetFinished: true })
        })
        .then(response => {
            if (response.success) {
                dispatch(setUserBudgetFinishedFlag());
                dispatch(stopActionLoader())
            }
        })
        .catch(frontendError => {
            console.log(frontendError);
        });
}

export const setActorTutorialCompleted = () => (dispatch) => {
    dispatch(startActionLoader())
    //To stop mail read request in tutorial mode
    netpack(consts.ACTOR_TUTORIAL_COMPLETED_API)
        .post({
            body: JSON.stringify({ isTutorialCompleted: true })
        })
        .then(response => {
            if (response.success) {
                dispatch(setActorTutorialCompletedFlag());
                // Close react joyride tutorial for header
                dispatch(pauseTutorial())
                //Close actor popup
                dispatch(setUiState({
                    showOverlay: false,
                    overlayComponentType: '',
                    overlayComponent: ''
                }))
                dispatch(stopActionLoader())
            }
        })
        .catch(frontendError => {
            console.log(frontendError);
        });
}

export const resetActorTutorialCompleted = () => (dispatch) => {
    dispatch(startActionLoader())
    netpack(consts.ACTOR_TUTORIAL_COMPLETED_API)
        .post({
            body: JSON.stringify({ isTutorialCompleted: false })
        })
        .then(response => {
            if (response.success) {
                dispatch(resetActorTutorialCompletedFlag())
                dispatch(stopActionLoader())
            }
        })
        .catch(frontendError => {
            console.log(frontendError);
        });
}

export const setActionTutorialCompleted = () => (dispatch) =>
    //To stop mail read request in tutorial mode
    netpack(consts.ACTION_TUTORIAL_COMPLETED_API)
        .post({
            body: JSON.stringify({ isTutorialCompleted: true })
        })
        .then(() => {
            dispatch(pauseTutorial())
            dispatch(setActionTutorialCompletedFlag());
            dispatch(stopActionsTutorial());
            dispatch(resetActionSequence());
            //Open tutorial complete popup
            dispatch(setUiState({
                showOverlay: true,
                overlayComponentType: 'CENTER',
                overlayComponent: 'TUTORIAL_COMPLETED_POPUP'
            }));
        })
        .catch(frontendError => {
            console.log(frontendError);
        });

export const setTimerStarted = () => (dispatch) =>
    //Set timer started flag
    netpack(consts.START_TIMER)
        .post()
        .then(response => {
            if (response.success) {
                dispatch(setStartTimerFlag())
            }
        })
        .catch(frontendError => {
            console.log(frontendError);
        });

export const setMonthlyReportSeenFlag = (payload) => {
    //Set timer started flag
    return netpack(consts.SET_MONTHLY_REPORT_SEEN_FLAG)
        .post({
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (response.success) {
                store.dispatch(resetDisplayMonthlyReportFlag())
            }
            return response
        })
        .catch(frontendError => {
            console.log(frontendError);
        });
}
