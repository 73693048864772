import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        buttonText: {
            ...myTypography.button,
            textTransform: 'uppercase',
            color: skin.white,
            backgroundImage: `linear-gradient(144.43deg, ${skin.buttonColor1} 4.65%, ${skin.buttonColor2} 97.99%)`
        }
    });
}

export default getSkin;
