import React from 'react';
import { connect } from 'react-redux';
import ActionResponse from '../components/action/actionResponse';
import { setUiState, updateHeaderMetrics } from 'actions/uiState/actionCreators';

class ActionResponseContainer extends React.Component {

    render() {
        return (
            <ActionResponse {...this.props} />
        );
    }
}

ActionResponseContainer.propTypes = {};

ActionResponseContainer.defaultProps = {};

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => ({
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    updateHeaderMetrics: (payload) => {
        dispatch(updateHeaderMetrics(payload))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionResponseContainer);
