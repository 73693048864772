import actionConsts from 'constants/actions';

const initialState = {
    uliId: -1,
    firstName: "",
    lastName: "",
    emailId: "",
    isSimStarted: false,
    isGameCompleted: false,
    isActorTutorialCompleted: false,
    isActionTutorialCompleted: false,
    isTimerStarted: false
};

const uiState = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_GAME_DATA:
            return action.payload.userInfo
        case actionConsts.SET_USER_GAME_STARTED:
            return {
                ...state,
                isSimStarted: true
            }
        case actionConsts.SET_USER_GAME_COMPLETED:
            return {
                ...state,
                isGameCompleted: true
            }
        case actionConsts.SET_USER_ACTOR_TUTORIAL_COMPLETED:
            return {
                ...state,
                isActorTutorialCompleted: true
            }
        case actionConsts.RESET_USER_ACTOR_TUTORIAL_COMPLETED:
            return {
                ...state,
                isActorTutorialCompleted: false
            }
        case actionConsts.SET_USER_ACTION_TUTORIAL_COMPLETED:
            return {
                ...state,
                isActionTutorialCompleted: true
            }
        case actionConsts.SET_START_TIMER_FLAG:
            return {
                ...state,
                isTimerStarted: true
            }
        default:
            return state;
    }
};

export default uiState;