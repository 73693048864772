import actionConsts from 'constants/actions';

const initialState = [];

const userActions = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.ACTION_RESPONSE:
            return [
                ...state,
                action.payload
            ];
        default:
            return state;
    }
};

export default userActions;
