import actionConsts from 'constants/actions';

const initialState = {
    isEventLoading: false,
    isNewEventAvailable: false  // This flag is used to indicate a new event is available.
}

const userEvent = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.INIT_GAME_DATA:
            let { events } = action.payload
            if (Object.keys(events).length > 0) {
                return {
                    ...state,
                    isNewEventAvailable: true,
                    ...events
                };
            }
            return state
        case actionConsts.SET_NEXT_EVENT:
            // If new event is available, set isNewEventAvailable flag to true
            let userEvent = action.payload.events
            if (Object.keys(userEvent).length > 0) {
                return {
                    isEventLoading: false,
                    isNewEventAvailable: true,
                    ...userEvent
                }
            }
            return initialState
        case actionConsts.START_EVENT_LOADER:
            /* Set isNewEventAvailable to false, so that we do not check for new events
             unnecessarily. It is set to false here as all events have a loader */
            return {
                ...state,
                isNewEventAvailable: false,
                isEventLoading: true
            }
        case actionConsts.STOP_EVENT_LOADER:
            return {
                ...state,
                isEventLoading: false
            }
        default:
            return state;
    }
};

export default userEvent;
