import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntroComponent from 'components/introComponent'
import DesktopHeader from 'components/header/desktopHeader'
import updateRoute from 'utils/webAppRoutes/updateRoute'
import { setUserGameStarted } from 'actions/apiAction/userApis'
import { setUiState } from "actions/uiState/actionCreators";
import Overlay from "../components/commonComponents/overlay";

class IntroContainer extends Component {
    componentDidMount() {
        let { location, userInfo } = this.props
        if (userInfo.isGameCompleted) {
            updateRoute({ route: '/end-report' })
        } else if (userInfo.isSimStarted) {
            updateRoute({ route: '/dashboard' })
        } else if (location.pathname === '/') {
            updateRoute({ route: '/intro' })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.userInfo.isSimStarted && this.props.userInfo.isSimStarted) {
            // If user isSimStarted flag is updated, go to dashboard route
            updateRoute({ route: '/dashboard' })
        }
    }

    onFooterButtonClick = () => {
        let { location, userInfo } = this.props
        if (location.pathname.includes('welcome')) {
            if (!userInfo.isSimStarted) {
                this.props.setUserGameStarted()
            }
        } else if (location.pathname.includes('objectives')) {
            updateRoute({ route: '/intro/welcome' })
        } else {
            updateRoute({ route: '/intro/objectives' })
        }
    }

    render() {
        let { introData, userInfo } = this.props
        let introContainerStyle = {};
        if (this.props.uiState.showOverlay) {
            introContainerStyle = {
                ...introContainerStyle,
                opacity: "0.1"
            };
        }

        return (
            <div className="base-app-box">
                <DesktopHeader isIntro={true} {...this.props} />
                <IntroComponent
                    introData={introData}
                    userInfo={userInfo}
                    onFooterButtonClick={this.onFooterButtonClick}
                    introContainerStyle={introContainerStyle} />
                {this.props.uiState.showOverlay ? <Overlay {...this.props} /> : null}
            </div>
        );
    }
}

IntroContainer.propTypes = {};

IntroContainer.defaultProps = {};

const mapStateToProps = state => {
    return state
};

const mapDispatchToProps = dispatch => ({
    setUiState: payload => {
        dispatch(setUiState(payload));
    },
    setUserGameStarted: () => {
        dispatch(setUserGameStarted())
    }
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IntroContainer);
