import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

	return StyleSheet.create({
		downloadStatusText: {
			...myTypography.body2,
			color: skin.white,
			fontStyle: 'italic'
		},
		downloadBtn:{
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '19px',
            textTransform: 'uppercase',
            color: skin.white,
            background: 'linear-gradient(144.43deg, #00D7FF 4.65%, #7DEEB9 97.99%)'
        },
	});
}

export default getSkin;