import React from 'react';
import styles from './actorTutorialCompletedPage.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import FlatButton from 'components/commonComponents/flatButton'
import BambaIllustration from 'images/bamba-uncovered-illustration.svg'

const ActorTutorialCompletedPage = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const onPreviousButtonClick = () => {
        props.resetActorTutorialCompleted()
        props.hideActionsUncoveredPage()
        props.startActorsTutorial()
    }

    return (
        <div styleName="ext-container">
            <div styleName="container" >
                <div styleName="welcome-icon">
                    <img src={BambaIllustration} />
                </div>
                <div styleName="title" className={css(myStyles.title)}
                    dangerouslySetInnerHTML={{ __html: props.getLabel('label_tutorial_bamba_title') }}
                >
                </div>
                <div
                    styleName="title"
                    className={css(myStyles.text)}
                    dangerouslySetInnerHTML={{ __html: props.getLabel('label_tutorial_bamba_desc') }}
                />
                <div styleName="proceed-button">

                    <div
                        className='button previous-button'
                        onClick={() => onPreviousButtonClick()}
                    >
                        {props.getLabel('label_previous')}
                    </div>
                    <div className='button next-button next-btn-margin' onClick={() => props.onShowBambaButtonClick()}>
                        {props.getLabel('label_tutorial_show_bamba_button')}
                    </div>

                    {/* <FlatButton textLabel={props.getLabel('label_tutorial_show_bamba_button')} onClickFunction={() => props.onShowBambaButtonClick()} /> */}
                </div>

            </div>
        </div>
    )
}

export default applyWrappers(ActorTutorialCompletedPage, styles);
