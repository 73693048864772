import actionConsts from 'constants/actions';

const setUiState = (payload) => {
    return {
        type: actionConsts.SET_UI_STATE,
        payload
    };
};

const updateUISize = payload => ({
    type: actionConsts.CHANGE_WIDTH_AND_HEIGHT,
    payload
});

const setHighlightedSidebarComponent = payload => {
    return {
        type: actionConsts.SET_HIGHLIGHTED_SIDEBAR_COMPONENT,
        payload
    }
}

const toggleInitialBambaScale = payload => {
    return {
        type: actionConsts.TOGGLE_INITIAL_BAMBA_SCALE,
        payload
    }
}

const updateHeaderMetrics = (payload) => {
    return {
        type: actionConsts.UPDATE_HEADER_METRICS,
        payload
    }
}

const setDisplayMonthlyReportFlag = (payload) => {
    return {
        type: actionConsts.SET_MONTHLY_REPORT_FLAG,
        payload
    }
}

const resetDisplayMonthlyReportFlag = () => {
    return {
        type: actionConsts.RESET_MONTHLY_REPORT_FLAG
    }
}

export {
    setUiState,
    updateUISize,
    setHighlightedSidebarComponent,
    toggleInitialBambaScale,
    updateHeaderMetrics,
    setDisplayMonthlyReportFlag,
    resetDisplayMonthlyReportFlag
};
