import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'utils/styleUtil';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        transcriptContainer: {
            boxShadow: `0px 4px 10px ${hexToRgbA(skin.black, 0.25)}`
        },
        transcriptTitle: {
            ...myTypography.subtitle,
            fontSize: '18px',
            lineHeight: '25px',
            textTransform: 'capitalize',
            color: skin.grayColor1
        },
        transcriptText: {
            ...myTypography.body1,
            fontSize: '16px',
            lineHeight: '163.42%',
            color: skin.grayColor1,
            opacity: '0.8'
        }
    });
}

export default getSkin;
