import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
		actionInformation: {
			fontFamily: 'Nunito',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '12px',
			lineHeight: '16px',
			color: skin.black,
			opacity: 0.6
		},
    });
}

export default getSkin;
