import React, { useEffect, useRef } from 'react';
import styles from './objectivesPopup.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import VideoPlayerComponent from 'components/commonComponents/videoPlayerComponent'
import TranscriptComponent from 'components/commonComponents/transcriptComponent'
import IntroListComponent from 'components/commonComponents/introListComponent'
import ObjectivesIcon from 'images/objectives-icon.svg'
import LeaderboardIcon from 'images/action-response.svg';
import Close from 'images/action-response-close.png';
import applyWrappers from 'wrappers/ComponentWrapper';
import objectivesImage from 'images/objectives_heading.png';
import analyticsUtil from 'utils/segmentUtil';

const ObjectivesPopup = (props) => {
    const myStyles = getSkin(props.skinGuide);

    const closePopup = () => {
        //Segment integartion
        analyticsUtil.track("SIDEBAR_POPUP_CLOSE", { popup: "OBJECTIVES_POPUP" });
        props.setUiState({
            showOverlay: false,
            overlayComponentType: '',
            overlayComponent: ''
        });
        props.setHighlightedSidebarComponent(-1)
    }

    let { targetRevenueObjective, objective } = props.introData;
    let objectiveCondition = { CURRENCY_VALUE: "$", TARGETREV_VALUE: targetRevenueObjective.toLocaleString('en-US') };

    const objectivesProps = {
        optionList: objective,
        condition: objectiveCondition,
        title: 'label_storyline_objectives',
        listIcon: ObjectivesIcon,
        type: 'objectives'
    }

    const objectivePopupEndRef = useRef(null)

    const scrollToBottom = () => {
        // Wont work well in ie 11
        objectivePopupEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [props]);

    return (
        <div styleName="container" className={css(myStyles.leaderboardPopupContainer)}>
            <div style={{ width: '100%', height: '486px', marginTop: '25px' }}>
                <div className={css(myStyles.headerContainer)} styleName="header-container">
                    <img styleName="heading-icon" src={objectivesImage} />
                    <div className={css(myStyles.title)}>
                        {props.getLabel('label_storyline_objectives')}
                    </div>
                </div>
                <div className={css(myStyles.content)} styleName="content">
                    <div styleName="intro-body">
                        <VideoPlayerComponent
                            propRef="intro-video"
                            kalturaVideoId={props.getLabel("label_kaltura_video_id")}
                        />
                        <TranscriptComponent {...props} />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <IntroListComponent {...objectivesProps} />
                        </div>
                    </div>
                    {/* <div styleName="image-container">
                    <img src={LeaderboardIcon} />
                </div> */}
                    <div ref={objectivePopupEndRef} />
                </div>
            </div>
            <div styleName="close-icon" onClick={closePopup}>
                <img src={Close} />
            </div>
        </div>
    )
}

export default applyWrappers(ObjectivesPopup, styles);
