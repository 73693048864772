import { StyleSheet } from 'aphrodite';
import IntroBgIllustration from 'images/intro-bg-illustration.svg'

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    return StyleSheet.create({
        container: {
            backgroundImage: `url(${IntroBgIllustration})`,
            backgroundSize: 'cover'
        }
    });
}

export default getSkin;
