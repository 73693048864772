import React from 'react';
import styles from './actionResponse.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ActionFeedback from 'components/commonComponents/popup/actionFeedback';
import ActionResponseCloseIcon from 'images/action-response-close.png';
import analyticsUtil from 'utils/segmentUtil';

const ActionResponse = (props) => {
    let actionResponseObj = {};
    if (props.userActions.length > 0 && props.uiState.delayedActionShowIndex < 0) {
        actionResponseObj = props.userActions[props.userActions.length - 1];
    } else if (props.userDelayedActions.length > 0 && props.uiState.delayedActionShowIndex >= 0) {
        actionResponseObj = props.userDelayedActions[props.uiState.delayedActionShowIndex];
    }
    //create here the action data
    let actionData = {
        title: props.actionList[actionResponseObj['actionId']]['name'],
        actionMessage: actionResponseObj['responseMgs'],
        category: props.categories.categoryList[actionResponseObj['categoryId']]['name'],
        domain: props.categories.categoryList[actionResponseObj['categoryId']]['subDomains'][actionResponseObj['subDomainId']]['name'],
        isActionIndividual: actionResponseObj['isIndividual'],
        days: props.actionList[actionResponseObj['actionId']]['duration'],
        budget: props.actionList[actionResponseObj['actionId']]['cost'],
        isDelayedImpact: props.uiState.delayedActionShowIndex >= 0 ? true : false,
        metrics: actionResponseObj['metrics']['overallMetrics'],
        isDelayedAction: actionResponseObj['isDelayedAction'],
        delayDay: actionResponseObj['impactDay'] - actionResponseObj['dayTo']
    };

    //Segment integartion
    analyticsUtil.track("ACTION_TAKEN_RESPONSE", {
        ...actionData,
        actionMessage: props.getLabel(actionData.actionMessage),
        title: props.getLabel(actionData.title),
        metrics: "not sent"
    });

    actionData = {
        ...actionData,
        ...actionResponseObj
    };
    let actorInfo = props.actors.actorList;
    if (actionResponseObj['actorId']) {
        actorInfo = {
            name: props.actors.actorList[actionResponseObj['actorId']]['name'],
            happiness: actionResponseObj['individualHappiness'],
            isIncrease: true,
            value: actionResponseObj['happinessDiff'],
            imagePath: props.actors.actorList[actionResponseObj['actorId']]['imagePath']
        };
    }
    const isActionIndividual = actionResponseObj['isIndividual'];
    let index = 2;
    if (!isActionIndividual) {
        index = 1;
    }
    const closePopup = () => {
        let updatedUiState = {
            showOverlay: false,
            overlayComponentType: '',
            overlayComponent: ''
        };
        if (props.uiState.delayedActionShowIndex == props.userDelayedActions.length - 1) {
            updatedUiState.delayedActionShowIndex = -1;
        }
        props.setUiState(updatedUiState);
        if (props.userDelayedActions.length > 0 && props.uiState.delayedActionShowIndex < props.userDelayedActions.length - 1) {
            //dispatch the updated ui state
            props.updateHeaderMetrics(props.userDelayedActions)
            let updatedUiState = {
                showOverlay: true,
                overlayComponentType: "BOTTOM",
                overlayComponent: "ACTION_REPONSE_POPUP",
                isTargetAchieved: props.userDelayedActions[props.uiState.delayedActionShowIndex + 1]['isTargetAchieved'],
                gameOver: props.userDelayedActions[props.uiState.delayedActionShowIndex + 1]['gameOver'],
                delayedActionShowIndex: props.uiState.delayedActionShowIndex + 1
            };
            props.setUiState(updatedUiState);
        } else {
            if (actionResponseObj['gameOver'].simEnd || (actionResponseObj.gameOver.simEndCause == "budget" && !actionResponseObj['isBudgetFinished'])) {
                let showGameOverPopup = {
                    showOverlay: true,
                    overlayComponentType: "CENTER",
                    overlayComponent: "ENDSCREEN_POPUP",
                    isTargetAchieved: actionResponseObj['isTargetAchieved'],
                    gameOver: actionResponseObj['gameOver']
                };
                props.setUiState(showGameOverPopup);
            }
        }
    }

    const myStyles = getSkin(props.skinGuide);

    return (
        <div className={css(myStyles.container)} styleName={"container container" + index}>
            <div className={css(myStyles.seperator)} styleName="headseperator"></div>
            <div
                className={css(myStyles.responseContainer)}
                styleName="response-container"
                style={actionData.isDelayedImpact ? { padding: '75px 40px 30px 110px' } : {}}
            >
                <ActionFeedback
                    {...actionData}
                    actorInfo={actorInfo}
                    closePopup={closePopup}
                />
            </div>
            <div styleName='response-text' className={css(myStyles.responseText)}>{props.getLabel("label_response")}</div>
        </div>
    )
}

export default applyWrappers(ActionResponse, styles);
