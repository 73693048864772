import { StyleSheet } from 'aphrodite';

//All color and font related changes here. The layout will be in .scss file

const getSkin = (theme) => {
    const skin = theme.globalProfiles.palette;

    return StyleSheet.create({
        video: {
            backgroundColor: skin.black
        }
    });
}

export default getSkin;
